import React, { useEffect, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import GetAppIcon from '@material-ui/icons/GetApp';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ShipmentDetails from './ShipmentDetails'
import { CircularProgress } from '@material-ui/core';
import { HOST_URL } from '../../config'
import axios from 'axios'
import { getUserData } from '../../utility/utils'
import { TurnedIn } from '@material-ui/icons';
import { StoreContext } from '../../store/StoreProvider'
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import $ from 'jquery'
import ExportData from './ExportData'
// import { StoreContext } from '../../store/StoreProvider'


export default function AccountStatement(props) {
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(60);
    const [sortCriteria, setSortCriteria] = useState("duedate");
    const [sortOrder, setSortOrder] = useState('Asc');
    const [hasLoadMore, setHasLoadMore] = useState(false)
    const [docDownloading, setDocDownloading] = useState([])
    const [search, setSearch] = useState("")
    const [statementData, setStatementData] = useState({
        statement: [],
        loading: false,
        error: false
    });
    const [data, setData] = useState([])
    const context = useContext(StoreContext)


    useEffect(() => {

        // branch
        $('.updownarrow1').on('click', () => {
            $('.uparrow1,.updownarrow2,.updownarrow3').removeClass('hide');
            $('.updownarrow1,.downarrow2,.uparrow2,.downarrow3,.uparrow3').addClass('hide');
        })
        $('.uparrow1').on('click', () => {
            $('.downarrow1,.updownarrow2,.updownarrow3').removeClass('hide');
            $('.uparrow1,.downarrow2,.uparrow2,.downarrow3,.uparrow3').addClass('hide');
        })
        $('.downarrow1').on('click', () => {
            $('.uparrow1,.updownarrow2,.updownarrow3').removeClass('hide');
            $('.downarrow1,.downarrow2,.uparrow2,.downarrow3,.uparrow3').addClass('hide');
        })

        // type
        $('.updownarrow2').on('click', () => {
            $('.uparrow2,.updownarrow1,.updownarrow3').removeClass('hide');
            $('.updownarrow2,.downarrow1,.uparrow1,.downarrow3,.uparrow3').addClass('hide');
        })
        $('.uparrow2').on('click', () => {
            $('.downarrow2,.updownarrow1,.updownarrow3').removeClass('hide');
            $('.uparrow2,.downarrow1,.uparrow1,.downarrow3,.uparrow3').addClass('hide');
        })
        $('.downarrow2').on('click', () => {
            $('.uparrow2,.updownarrow1,.updownarrow3').removeClass('hide');
            $('.downarrow2,.downarrow1,.uparrow1,.downarrow3,.uparrow3').addClass('hide');
        })

        // currency
        $('.updownarrow3').on('click', () => {
            $('.uparrow3,.updownarrow1,.updownarrow2').removeClass('hide');
            $('.updownarrow3,.downarrow1,.uparrow1,.downarrow2,.uparrow2').addClass('hide');
        })
        $('.uparrow3').on('click', () => {
            $('.downarrow3,.updownarrow1,.updownarrow2').removeClass('hide');
            $('.uparrow3,.downarrow1,.uparrow1,.downarrow2,.uparrow2').addClass('hide');
        })
        $('.downarrow3').on('click', () => {
            $('.uparrow3,.updownarrow1,.updownarrow2').removeClass('hide');
            $('.downarrow3,.downarrow1,.uparrow1,.downarrow2,.uparrow2').addClass('hide');
        })
    }, [])


    useEffect(() => {
        getStatementList(1, 20, sortCriteria, sortOrder)
        getStatementList(1, 0, sortCriteria, sortOrder)
    }, [])


    useEffect(() => {

        setSearch((preState) => {
            if (preState === context.searchValue) {
                return preState
            }
            OnSearchStatement()
            return context.searchValue
        })


    }, [context.searchValue])


    useEffect(() => {
        // if(context.orgaizatioCode !== ""){
        getStatementList(1, 20, sortCriteria, sortOrder)

        // }
    }, [context.orgaizatioCode])


    const onDocDownload = async (index) => {
        const download = docDownloading
        download[index] = true
        setDocDownloading(download)
        context.setLoading(true);
        let userData = {}
        const userSecret = JSON.parse(sessionStorage.getItem('user_secret'))
        await getUserData("user_data").then((res) => {
            userData = res
        }).catch(() => {
            return;
        })

        const header = {
            "Authorization": `Bearer ${userData.access_token}`,
            'UserName': userData.user_name,
            "UserSecret": userSecret.password,
            'DeviceType': "W",
            'MacAddress': '',
            'IPAddress': ''
        }


        const END_POINT = `${HOST_URL}api/v1/DocumentData/${userData.user_company_code}}/InvoiceDocument`

        const searchParams = new URLSearchParams()
        searchParams.append("ShipmentId", `${statementData.statement[index].InvoiceNo}`)
        searchParams.append("TypeCode", "AR")
        searchParams.append("TypeName", statementData.statement[index].Type)

        axios.post(END_POINT, searchParams, {
            headers: header
        }).then((res) => {
            download[index] = false
            setDocDownloading(download)
            context.setLoading(false)

            const pdf = res.data[0].ImageData
            const linkSource = `data:application/pdf;base64,${pdf}`;
            const downloadLink = document.createElement("a");
            const fileName = res.data[0].FullName;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();

        }).catch((err) => {
            download[index] = false
            context.setLoading(false);
            console.log(err)
        })
    }


    const OnSearchStatement = async () => {
        setStatementData({ ...statementData, statement: [], loading: true, error: false })

        let data = {}
        const userSecret = await JSON.parse(sessionStorage.getItem('user_secret'))
        await getUserData('user_data').then((res) => {
            data = res;
        })
        const header = {
            "Authorization": `Bearer ${data.access_token}`,
            "UserName": data.user_name,
            "UserSecret": userSecret.password,
            "DeviceType": 'W',
            "MacAddress": '',
            "IPAddress": ''
        }

        const END_POINT = `${HOST_URL}api/v1/AccountingData/${data.user_country_code}/Search/${data.user_company_code}?pageNumber=1&pageSize=20&sortCriteria=${sortCriteria}&sortOrder=${sortOrder}&SearchText=${context.searchValue}`

        axios.get(END_POINT, { headers: header }).then((res) => {
            context.setInvoiceAmmount({ ...context.invoiceAmmount, total: res.data.TotalOutstanding, overdue: res.data.TotalInvoiceAmount, totalInUSD: res.data.TotalOutstandingUSD, overdueInUSD: res.data.TotalInvoiceAmountUSD })
            setHasLoadMore(false)
            setStatementData({ ...statementData, statement: res.data.Data, loading: false, error: false })

        }).catch(() => {
            setStatementData({ ...statementData, statement: [], loading: false, error: true })

        })

    }


    const getStatementList = async (pageNumber, pageSize, criteria, order) => {
        let data = {}
        if (pageNumber == 1) {
            setStatementData({ statement: [], loading: true, error: false })
            setDocDownloading([])
            setHasLoadMore(false)
            setPageNumber(1)
        }
        const userSecret = await JSON.parse(sessionStorage.getItem('user_secret'))
        await getUserData('user_data').then((res) => {
            data = res;
        })
        const header = {
            "Authorization": `Bearer ${data.access_token}`,
            "UserName": data.user_name,
            "UserSecret": userSecret.password,
            "DeviceType": 'W',
            "MacAddress": '',
            "IPAddress": ''
        }
        let URL = ""
        if (context.type === "normal") {
            URL = `${HOST_URL}api/v1/AccountingData/${data.user_country_code}/GET/${data.user_company_code}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortCriteria=${criteria}&sortOrder=${order}`;

        } else {
            URL = `${HOST_URL}api/v1/AccountingData/${data.user_country_code}/GET/${context.orgaizatioCode}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortCriteria=${criteria}&sortOrder=${order}`;

        }

        axios.get(URL, { headers: header }).then((res) => {
            if (pageSize === 0) {
                setData(res.data?.Data ?? [])
            } else {
                context.setInvoiceAmmount({ ...context.invoiceAmmount, total: res.data.TotalInvoiceAmount, overdue: res.data.TotalOutstanding, totalInUSD: res.data.TotalInvoiceAmountUSD, overdueInUSD: res.data.TotalOutstandingUSD })

                if (pageNumber == 1) {
                    // setStatementData
                    setStatementData({ statement: res.data.Data, loading: false, error: false })

                }

                else if (res.data.Data !== []) {

                    const download = [...docDownloading]
                    res.data.Data.forEach(element => {
                        download.push(false)
                    })

                    setDocDownloading(download)

                    setStatementData({ ...statementData, statement: [...statementData.statement, ...res.data.Data], loading: false, error: false })
                    setPageNumber(pageNumber)
                    context.setStmData("statement data", res.data.Data);
                }
                if (res.data.Data.length === 20) {
                    setHasLoadMore(true)
                }
                else {
                    setHasLoadMore(false)
                }
            }
        }).catch(() => {
            setStatementData({ ...statementData, statement: [], loading: false, error: true })
            setHasLoadMore(false)
        })
    }

    const onScrollLoadMore = () => {
        setHasLoadMore(false)
        if (context.searchValue === "") {
            let count = pageNumber + 1

            getStatementList(count, 20, sortCriteria, sortOrder)

        }
    }

    const onSortFilter = (type, order) => {

        setHasLoadMore(false)
        setSortOrder(order)
        setSortCriteria(type)
        setPageNumber(1)
        getStatementList(1, 20, type, order)

    }



    return (
        <TableContainer component={Paper} className="tableCont">
            <ExportData data={data} />
            <InfiniteScroll
                pageStart={0}
                loadMore={() => onScrollLoadMore()}
                hasMore={hasLoadMore}
                loader={<div className="loader" key={0}>Loading ...</div>}
                useWindow={false}
            >
                <Table aria-label="collapsible table" id="stmOfAcc">
                    <TableHead>
                        <TableRow>
                            <TableCell onClick={() => {
                            }}>
                                <div className='sorth'>
                                    Posting Branch
                                    <span title="Sort Descending">
                                        <UnfoldMoreIcon className="updownarrow1 pointer"
                                            onClick={() => { onSortFilter('branch', "Desc") }}
                                        />
                                    </span>
                                    <span title="Ascending">
                                        <ExpandMoreIcon className="downarrow1 hide pointer" onClick={() => { onSortFilter('branch', "Desc") }} />
                                    </span>
                                    <span title="Descending">
                                        <ExpandLessIcon className="uparrow1 hide pointer" onClick={() => { onSortFilter('branch', "Asc") }} />
                                    </span>
                                </div>
                            </TableCell>

                            <TableCell align="center" >Type
                                <span title="Sort Descending">
                                    <UnfoldMoreIcon className="updownarrow2 pointer"
                                        onClick={() => { onSortFilter('type', "Desc") }}
                                    />
                                </span>
                                <span title="Ascending">
                                    <ExpandMoreIcon className="downarrow2 hide pointer" onClick={() => { onSortFilter('type', "Desc") }} />
                                </span>
                                <span title="Descending">
                                    <ExpandLessIcon className="uparrow2 hide pointer" onClick={() => { onSortFilter('type', "Asc") }} />
                                </span>
                            </TableCell>
                            <TableCell align="right">Credit Days</TableCell>
                            <TableCell align="left">Job Invoice #</TableCell>
                            <TableCell align="right">Job Invoice Date</TableCell>
                            <TableCell align="center">
                                <div className='sorth'>
                                    Job Invoice Currency
                                    <span title="Sort Descending">
                                        <UnfoldMoreIcon className="updownarrow3 pointer" onClick={() => { onSortFilter('currency', "Desc") }}
                                        />
                                    </span>
                                    <span title="Ascending">
                                        <ExpandMoreIcon className="downarrow3 hide pointer" onClick={() => { onSortFilter('currency', "Desc") }} />
                                    </span>
                                    <span title="Descending">
                                        <ExpandLessIcon className="uparrow3 hide pointer" onClick={() => { onSortFilter('currency', "Asc") }} />
                                    </span>
                                </div>
                            </TableCell>
                            <TableCell align="right">Job Invoice Amount</TableCell>
                            <TableCell align="right">Received Amount</TableCell>
                            <TableCell align="right">Balance Total</TableCell>
                            <TableCell align="right">Due Date</TableCell>
                            <TableCell align="right">Overdue Days</TableCell>
                        </TableRow>
                    </TableHead>
                    {!statementData.loading ? <TableBody>
                        {statementData.statement.map((row, index) => (
                            <Row key={index} row={row} downloading={docDownloading[index]} index={index} onDocDownload={onDocDownload} />
                        ))}
                    </TableBody > : <TableBody className="loaderProgress"><TableRow><TableCell colSpan="11"><div><CircularProgress size={"2rem"} /></div></TableCell></TableRow></TableBody>}
                </Table>
            </InfiniteScroll>

        </TableContainer>
    );
}


const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    let bgColor = "#000"

    let InvoiceDate = "";
    if (row.InvoiceDate) {
        InvoiceDate = moment(row.InvoiceDate).format("DD/MM/YYYY");
    }
    let DueDate = "";
    if (row.DueDate) {
        DueDate = moment(row.DueDate).format("DD/MM/YYYY");
    }

    // if (row.OverDueDays > 0) {
    //     bgColor = "red"
    // }

    const calculateAmmount = (ammount) => {
        const amt = Number(parseFloat(ammount).toFixed(2)).toLocaleString('en', {
            minimumFractionDigits: 2
        });
        return amt
    }

    return (
        <React.Fragment>
            <TableRow className={row.OverDueDays > 0 ? classes.root + " overdue" : classes.root}>
                <TableCell align="left" title={row.Branchcode}><div>{row.Branchcode ?? " "}</div></TableCell>
                <TableCell align="center" title={row.Type}><div>{row.Type ?? " "}</div></TableCell>
                <TableCell align="right" title={row.CreditDays}><div>{row.CreditDays ?? " "}</div></TableCell>
                <TableCell align="left">
                    <div>
                        <span title="Download Invoice" onClick={() => props.onDocDownload(props.index)} className="invnoIcon"><GetAppIcon className="pointer" /></span>
                        <span title={row.InvoiceNo} className="invno">{row.InvoiceNo ?? " "}</span>
                    </div>
                </TableCell>
                <TableCell align="right" title={InvoiceDate}><div>{InvoiceDate ?? " "}</div></TableCell>
                <TableCell align="center" title={row.Currency_code}><div>{row.Currency_code ?? " "}</div></TableCell>
                <TableCell align="right" title={row.Amount}><div>{row.Amount ? calculateAmmount(row.Amount) : " "}</div></TableCell>
                <TableCell align="right" title={row.ReceivedAmount}><div>{row.ReceivedAmount ? calculateAmmount(row.ReceivedAmount) : " "}</div></TableCell>
                <TableCell align="right" title={row.Amount}><div>{(row.Amount - row.ReceivedAmount) ? calculateAmmount((row.Amount - row.ReceivedAmount)) : " "}</div></TableCell>
                <TableCell align="right" title={DueDate}><div>{DueDate ?? " "}</div></TableCell>
                <TableCell align="right" title={row.OverDueDays}><div>{row.OverDueDays ?? " "}</div></TableCell>
            </TableRow>
        </React.Fragment>
    );
}