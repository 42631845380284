import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { HOST_URL } from "../../config";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { setUserData } from "../../utility/utils";
import { getUserData } from "../../utility/utils";
import { StoreContext } from "../../store/StoreProvider";
import $, { event } from "jquery";
import Button from "@material-ui/core/Button";
import { useTimer } from "react-timer-hook";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("user login sucessfully");
  const [severity, setSeverity] = useState("success");
  const [otp, setOtp] = useState("");
  const context = useContext(StoreContext);
  const [verifyOTP, setVerifyOTP] = useState("");
  const [userResponse, setUserResponse] = useState({});
  const [resendActive, setResendActive] = useState(false);
  const [hide, setHide] = useState(true);
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    // onSendEmail()
    getUserData("user_data")
      .then((res) => {
        if (res.user_roles === "ADM") {
          context.setSelectedTab("user");
          context.setIsAdmin(true);
          props.history.push("/Users");
        } else {
          context.setIsAdmin(false);
          props.history.push("/Shipments");
          context.setSelectedTab("shipment");
        }
      })
      .catch((err) => {
        context.setIsAdmin(false);
        props.history.push("/");
      });
  }, []);

  const onSendEmail = (res) => {
    const header = {
      Authorization: `Bearer ${res.access_token}`,
      UserName: username,
      UserSecret: password,
      DeviceType: "W",
      MacAddress: "",
      IPAddress: "",
    };

    setVerifyOTP("");
    const emailOTP = Math.floor(100000 + Math.random() * 900000);
    setOtp(emailOTP);
    const params = new URLSearchParams();
    params.append("Subject", "OTP for login verification");
    params.append(
      "Body",
      `OTP for login is ${emailOTP}.<br><br>
        This is a system generated message. Please do not reply on it. if you have any query/issue, please contact on <b>'mytgf@tgfworld.com'</b>.<br><br>
        Sent from myTGF Team<br><br>
        This email and any of its attachments are intended for and distributed exclusively to their above addressee and may contain confidential information protected by a professional duty of secrecy. If you are not the addressee or received this message in error, please inform the original sender and delete this message immediately.<br><br>`
    );
    params.append("Recipient", res.user_email);

    console.log(header, params.toString());
    axios
      .post(`${HOST_URL}api/v1/MailData/SendMail`, params, {
        headers: header,
      })
      .then((res) => {
        setHide(false);
        let t = new Date();
        setTime(t.setSeconds(t.getSeconds() + 120));
        setResendActive(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleLogin = () => {
    // e.preventDefault();
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);
    params.append("grant_type", "password");
    params.append("ipAddress", "");
    params.append("deviceType", "W");
    params.append("macAddress", "");

    const END_POINT = HOST_URL + "Login";
    axios
      .post(HOST_URL + "Login", params)
      .then((result) => {
        setUserResponse(result.data);
        onSendEmail(result.data);
      })
      .catch((err) => {
        console.log(err);
        setSeverity("error");
        setMessage("The username, password that you've entered is incorrect.");
        setOpen(true);
        context.setIsAdmin(false);
      });
  };

  const onMatchOTP = () => {
    if (verifyOTP == otp) {
      setUserData(userResponse, "user_data").then((res) => {
        const userSecret = {
          userId: username,
          password: password,
        };

        setUserData(userSecret, "user_secret").then(() => {
          if (userResponse.user_roles === "ADM") {
            context.setIsAdmin(true);
            context.setSelectedTab("dashboard");
            props.history.push("/Dashboard");
          } else {
            context.setIsAdmin(false);
            context.setSelectedTab("dashboard");
            props.history.push("/Dashboard");
          }
        });
      });
    } else {
      setOpen(true);
      setSeverity("error");
      if (otp === null) {
        setMessage("Your OTP is expired.");
      } else {
        setMessage("OTP does not match.");
      }
    }
  };

  const handleChange = (event) => {
    if (event.target.id === "floatingInput") {
      setUsername(event.target.value);
    } else {
      setPassword(event.target.value);
    }
  };

  const showPassword = () => {
    let x = document.getElementById("floatingPassword");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
    $(".passIcon").toggleClass("hide");
  };

  const [open, setOpen] = React.useState(false);
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const Timer = ({
    expiryTimestamp,
    setResendActive,
    resendActive,
    handleLogin,
  }) => {
    const { seconds, minutes, restart } = useTimer({
      expiryTimestamp,
      onExpire: () => {
        setResendActive(true);
        setOtp(null);
      },
    });
    // restart(time);
    return (
      <div className="timerComponent">
        <span
          className={resendActive ? "resendActive" : ""}
          onClick={() => {
            if (resendActive) {
              handleLogin();
              restart(time);
            }
          }}
        >
          Resend OTP
        </span>{" "}
        in <span>{minutes}</span>:<span>{seconds}</span>
      </div>
    );
  };

  return (
    <div className="logincont">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-xl-4 col-lg-5 col-md-9 col-12">
            <div className="row d-none d-md-block">
              <div className="col">
                <img src={`/dashboard/Assets/Images/logo.png`} />
              </div>
            </div>
            <div className="row mt-0 mt-md-5">
              <h4>Login to TGF Portal</h4>
            </div>
            {hide ? (
              <div className="row">
                <div className="col-12">
                  <div className="form-floating my-md-4 mb-3">
                    <input
                      type="email"
                      className="form-control"
                      id="floatingInput"
                      placeholder="name@example.com"
                      value={username}
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingInput">Email address</label>
                  </div>
                  <div className="form-floating my-md-4 my-3">
                    <input
                      type="password"
                      className="form-control"
                      id="floatingPassword"
                      placeholder="Password"
                      value={password}
                      required
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingPassword">Password</label>
                    <span className="showpassword" onClick={showPassword}>
                      <VisibilityOffOutlinedIcon className="passIcon" />
                      <VisibilityOutlinedIcon className="passIcon hide" />
                    </span>
                  </div>
                  {/* <div className="my-md-4 my-3">
                                    <a href="javascript:void(0);">Forgot Password?</a>
                                </div> */}
                  <div className="my-md-4 my-3">
                    <button
                      type="button"
                      className="btn btn-lg loginbutton"
                      onClick={handleLogin}
                    >
                      Send OTP
                    </button>
                  </div>
                  {/* <div className="my-md-4 my-3">
                                    <span>New here ? </span> <a href="javascript:void(0);"> Register</a>
                                </div> */}
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <span>
                    6 digit OTP sent to your email {userResponse.user_email}
                  </span>
                  <div className="form-floating mt-md-4 mb-2">
                    <input
                      type="text"
                      className="form-control"
                      id="otpenterInput"
                      placeholder="Enter OTP"
                      value={verifyOTP}
                      onChange={(event) => {
                        setVerifyOTP(event.target.value);
                      }}
                    />
                    <label htmlFor="otpenter">Enter OTP</label>
                  </div>
                  <Timer
                    expiryTimestamp={time}
                    setResendActive={setResendActive}
                    resendActive={resendActive}
                    handleLogin={handleLogin}
                  />
                  <div className="my-md-4 my-3">
                    <button
                      type="button"
                      className="btn btn-lg loginbutton"
                      onClick={onMatchOTP}
                    >
                      Login
                    </button>
                    <button
                      type="button"
                      className="btn btn-lg loginbutton ms-3"
                      onClick={() => {
                        setHide(true);
                      }}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            )}

            <Snackbar
              autoHideDuration={3000}
              onClose={() => setOpen(false)}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              open={open}
            >
              <Alert onClose={() => setOpen(false)} severity={severity}>
                {message}
              </Alert>
            </Snackbar>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
