import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ShipmentTable from './ShipmentTable'
import './home.css';
import axios from 'axios'
import { HOST_URL } from '../../config'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { getUserData } from '../../utility/utils'
import { Collapse } from '@material-ui/core/Collapse';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Chip from '@material-ui/core/Chip';
import PeopleIcon from '@material-ui/icons/People';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import SyncIcon from '@material-ui/icons/Sync';
import Button from '@material-ui/core/Button';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Sidebar from './Sidebar';
import HomeMobile from "./MobileView/HomeMobile";
import $ from 'jquery';
import { StoreContext } from '../../store/StoreProvider';
import AccountStatement from './AccountStatement';
import UserList from '../screens/UsersList'
import ChangePassword from '../common/ChangePassword';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import HeaderOrganisations from './HeaderOrganisations'
import { LeakAddTwoTone } from '@material-ui/icons';
import Drawer from '@material-ui/core/Drawer';
import Dashboard from './dashboard/Dashboard';
import moment from 'moment';
import getMAC from 'getmac'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function Home(props) {
    const history = useHistory();
    const context = useContext(StoreContext)
    const [searchActive, setSearchActive] = useState(false)
    const [shipmentId, setShipmentId] = useState("")
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [cargoStatus, setCargoStatus] = useState("Search")
    const [sortCriteria, setSortCriteria] = useState("ETD")
    const [sortOrder, setSortOrder] = useState('Desc')
    const [hasLoadMore, setHasLoadMore] = useState(false)
    const [userInfo, setUserInfo] = useState({})
    const location = useLocation()
    const currency = ["INR", "USD"];
    const [totalShipment, setTotalShipment] = useState("")
    const [organization, setOrganization] = useState("")
    const [type, setType] = useState("normal")
    const [isAdmin, setIsAdmin] = useState(false)
    const [showprivacy, setShowprivacy] = useState(true);
    const [acceptLoading, setAcceptLoading] = useState(false)

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    //for statements modal
    const [showModal, setShowModal] = useState(false)

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [advanceSearch, setAdvanceSearch] = useState({ HouseBLNumber: "", EastimatedDeparture: "", EastimatedArrival: "", LoadPort: "", Consignor: "", Consignee: "", Status: "", ReferenceNumber: "", Direction: "", TrasnportMode: "", ShipmentData: "", DestinationPort: "", sortCriteria: "ETD", sortOrder: "Desc", PageNumber: "1", PageSize: "20" })

    const [snackbar, setSnackbar] = useState({ severity: "", open: false, message: "" })

    const [shipmentData, setShipmentData] = useState({
        shipments: [],
        loading: false,
        error: false
    })


    const LogOut = () => {
        sessionStorage.clear();
        history.push('/');
    }

    useEffect(() => {
        if (localStorage.getItem("acceptCookies") === null) {
            localStorage.setItem('acceptCookies', false);
        } else if (localStorage.getItem("acceptCookies") === 'true') {
            setShowprivacy(false);
        } else if (localStorage.getItem("acceptCookies") === 'false') {
            setShowprivacy(true);
        }
        getShipmentList(sortOrder, sortCriteria, "PreLoaded", pageSize, 1, type, organization);

        $('#tablesearch').focus(function () {
            $('.searchInputClr').show()
        });
        $('#tablesearch').blur(function () {
            let val = document.getElementById('tablesearch').value;
            if (val === "") {
                $('.searchInputClr').hide();
            }
        })
        $('.toggleSidebar').on('click', function () {
            $('.sidebar').toggleClass('closeSidebar');
            $(".toggleSidebar").toggleClass('flip');
            if ($('.sidebar').hasClass('closeSidebar')) {
                context.setSidebarclosed(true);
            } else {
                context.setSidebarclosed(false);
            }
        });

        adminorUser()
    }, [])

    const adminorUser = async () => {
        await getUserData("user_data").then((res) => {
            if (res.user_roles === "ADM") {
                setIsAdmin(true)
            } else {
                setIsAdmin(false)
            }
        }).catch((err) => {
            console.log(err)
        })

    }


    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const onAlertSave = () => {
        setSnackbar({ ...snackbar, open: true, message: "Notification sent successfully !!", severity: "success" })
        if (searchActive) {
            onAdvanceSearch("Search", 1, 20, type, organization)
        } else {
            getShipmentList(sortOrder, sortCriteria, "PreLoaded", 20, 1, type, organization)

        }
    }

    const SearchInputClr = (e) => {
        e.stopPropagation();
        document.getElementById('tablesearch').value = "";
        $('.searchInputClr').hide();
        setShipmentId("")
        getShipmentList("Desc", "ETD", "PreLoaded", 20, 1, type, organization);
        context.setSearchValue("")

    }

    const onResetHandle = () => {
        setSearchActive(false)
        setPageNumber(1)
        $('#eta, #etd').text('Select date range');
        $("input:radio[name='shpstatus'],input:radio[name='shpMode']").each(function (i) {
            this.checked = false;
        });
        setAdvanceSearch({ HouseBLNumber: "", EastimatedDeparture: "", EastimatedArrival: "", LoadPort: "", Consignor: "", Consignee: "", Status: "", ReferenceNumber: "", Direction: "", TrasnportMode: "", ShipmentData: "", DestinationPort: "", sortCriteria: "", sortOrder: "Desc", PageNumber: 1, PageSize: "20" })
        setSortCriteria("ETD")
        setSortOrder("Desc")
        getShipmentList("DESC", "ETD", "PreLoaded", pageSize, 1, type, organization);

    }


    const onSearchHandle = () => {
        setSearchActive(true)
        setPageNumber(1)
        onAdvanceSearch(cargoStatus, 1, pageSize, type, organization)
    }

    const onScrollToLoad = () => {
        let count = pageNumber + 1
        if (shipmentId) {
            onSearchByShipmnetId(count, cargoStatus, type)
        } else {
            if (searchActive) {
                onAdvanceSearch("Search", count, 20, type, organization)
            } else {
                getShipmentList(sortOrder, sortCriteria, "PreLoaded", 20, count, type, organization)

            }

        }
    }

    const onSortByETD = (order, criteria) => {
        setSortOrder(order)
        setSortCriteria(criteria)
        setPageNumber(1)

        if (searchActive) {
            onAdvanceSearch("Search", 1, 20, type, organization)
        } else {
            getShipmentList(order, criteria, "PreLoaded", 20, 1, type, organization)

        }
    }

    const getShipmentList = async (order, criteria, cargoStatus, size, number = 1, type = "normal", org) => {

        let userData = {}
        setHasLoadMore(false)
        if (number === 1) {
            setShipmentData({ shipments: [], loading: true, error: false })
        }

        const userSecret = JSON.parse(sessionStorage.getItem('user_secret'))
        await getUserData("user_data").then((res) => {
            userData = res
            setUserInfo(res)
        }).catch(() => {
            props.history.goBack()
            return;
        })

        const header = {
            "Authorization": `Bearer ${userData.access_token}`,
            'UserName': userData.user_name,
            "UserSecret": userSecret.password,
            'DeviceType': "W",
            'MacAddress': '',
            'IPAddress': ''
        }
        let END_POINT = ""
        if (type !== "normal") {
            END_POINT = `${HOST_URL}api/v1/ShipmentData/${userData.user_country_code}/Get/${org}?page.cargoStatus=${cargoStatus}&page.pageNumber=${number}&page.pageSize=${size}&page.sortCriteria=${criteria}&page.sortOrder=${order}`

        } else {
            END_POINT = `${HOST_URL}api/v1/ShipmentData/${userData.user_country_code}/Get/${userData.user_company_code}?page.cargoStatus=${cargoStatus}&page.pageNumber=${number}&page.pageSize=${size}&page.sortCriteria=${criteria}&page.sortOrder=${order}`
        }

        axios.get(END_POINT, {
            headers: header
        }).then((res) => {
            if (number === 1 && res.data.Data !== []) {
                setShipmentData({ shipments: res.data.Data, loading: false, error: false })
                setTotalShipment(res.data.TotalRecords)
            }
            else {
                if (res.data.Data !== []) {
                    setPageNumber(number)
                    setShipmentData({ ...shipmentData, shipments: [...shipmentData.shipments, ...res.data.Data], loading: false, error: false })
                } else {
                    setShipmentData({ ...shipmentData, loading: false })
                }
                setTotalShipment(res.data.TotalRecords)

            }

            if (res.data.Data.length === 20) {
                setHasLoadMore(true)
            } else {
                setHasLoadMore(false)
            }

        }).catch((err) => {
            console.log(err)
            setShipmentData({ ...shipmentData, shipmentData: [], loading: false, error: true })
        })
    }

    const onSelectOrganization = (org) => {
        context.setType("user")
        context.setOrgaizatioCode(org)
        setOrganization(org)
        setType("user")
        getShipmentList(sortOrder, sortCriteria, "PreLoaded", 20, 1, "user", org)

    }

    //handle multiple organization for admin in statements
    const onSelectOrg = (org) => {
        context.setType("user")
        context.setOrgaizatioCode(org)
    }

    const onSearchByShipmnetId = async (pageNumber, cargoStatus, type) => {
        let data = {}
        setHasLoadMore(false)
        if (pageNumber === 1) {
            setShipmentData({ ...shipmentData, loading: true, shipments: [], error: false })

        }

        const userSecret = JSON.parse(sessionStorage.getItem('user_secret'))
        await getUserData("user_data").then((res) => {
            data = res
        }).catch(() => {
        })

        const header = {
            "Authorization": `Bearer ${data.access_token}`,
            'UserName': data.user_name,
            "UserSecret": userSecret.password,
            'DeviceType': "W",
            'MacAddress': '',
            'IPAddress': '',
            'Access-Control-Allow-Origin': "*"
        }


        let END_POINT = ""

        if (type === "normal") {
            END_POINT = `${HOST_URL}api/v1/ShipmentData/${data.user_country_code}/Search/${data.user_company_code}`
        } else {
            END_POINT = `${HOST_URL}api/v1/ShipmentData/${data.user_country_code}/Search/${organization}`
        }

        let params = new URLSearchParams();
        params.append("ShipmentData", shipmentId)
        params.append("cargoStatus", cargoStatus)
        params.append("pageNumber", pageNumber)
        params.append("pageSize", 20)

        axios.post(END_POINT, params, {
            headers: header
        }).then((res) => {
            setPageNumber(pageNumber)


            if (pageNumber === 1) {
                setShipmentData({ shipments: res.data.Data, loading: false, error: false })
                // setTotalRecords(res.data.TotalRecords);
                setTotalShipment(res.data.TotalRecords)

            } else {
                setTotalShipment(res.data.TotalRecords)

                setShipmentData({ shipments: [...shipmentData.shipments, ...res.data.Data], loading: false, error: false })
                // setTotalRecords(res.data.TotalRecords);
            }
            if (res.data.Data.length === 20) {
                setHasLoadMore(true)
            } else {
                setHasLoadMore(false)
            }

            context.setInvoiceAmmount({ ...context.invoiceAmmount, totalShipment: res.data.TotalRecords })
        }).catch((err) => {
            console.log(err)
            setShipmentData({ ...shipmentData, shipments: [], loading: false, error: true })
        })
    }


    const onAdvanceSearch = async (cargoStatus, number = 1, size = 20, type = "normal", org) => {
        let data = {}
        // setHasLoadMore(false)

        let etd = "";
        if (document.getElementById('etd').innerHTML !== "Select date range") {
            etd = document.getElementById('etd').innerHTML;
        }
        let eta = "";
        if (document.getElementById('eta').innerHTML !== "Select date range") {
            eta = document.getElementById('eta').innerHTML;
        }
        setHasLoadMore(false)
        if (number === 1) {
            setShipmentData({ ...shipmentData, shipments: [], loading: true, error: false })

        }


        const userSecret = JSON.parse(sessionStorage.getItem('user_secret'))
        await getUserData("user_data").then((res) => {
            data = res
        }).catch(() => {

        })

        const header = {
            "Authorization": `Bearer ${data.access_token}`,
            'UserName': data.user_name,
            "UserSecret": userSecret.password,
            'DeviceType': "W",
            'MacAddress': '',
            'IPAddress': '',
        }


        let END_POINT = ""
        if (type === "normal") {
            END_POINT = `${HOST_URL}api/v1/ShipmentData/${data.user_country_code}/Search/${data.user_company_code}?page.cargoStatus=${cargoStatus}&page.pageNumber=${number}&page.pageSize=${size}`

        } else {
            END_POINT = `${HOST_URL}api/v1/ShipmentData/${data.user_country_code}/Search/${org}?page.cargoStatus=${cargoStatus}&page.pageNumber=${number}&page.pageSize=${size}`

        }

        const searchParams = new URLSearchParams()
        searchParams.append("HouseBLNumber", "")
        searchParams.append("EastimatedDeparture", etd)
        searchParams.append("EastimatedArrival", eta)
        searchParams.append("LoadPort", advanceSearch.LoadPort)
        searchParams.append("Direction", advanceSearch.EastimatedDeparture)
        searchParams.append("TrasnportMode", advanceSearch.TrasnportMode)
        searchParams.append("ShipmentData", "")
        searchParams.append("Status", advanceSearch.Status)
        searchParams.append("Consignor", advanceSearch.Consignor)
        searchParams.append("Consignee", advanceSearch.Consignee)

        searchParams.append("DestinationPort", advanceSearch.DestinationPort)
        searchParams.append("sortCriteria", sortCriteria)
        searchParams.append("sortOrder", sortOrder)
        searchParams.append("cargoStatus", cargoStatus)
        searchParams.append("pageNumber", number)
        searchParams.append("pageSize", 20)

        axios.post(END_POINT, searchParams, {
            headers: header
        }).then((res) => {
            setTotalShipment(res.data.TotalRecords)
            // context.setInvoiceAmmount({ ...context.invoiceAmmount, totalShipment: res.data.TotalRecords })

            if (number === 1) {
                setShipmentData({ shipments: res.data.Data, loading: false, error: false })
                // setTotalRecords(res.data.TotalRecords);

            } else {
                setPageNumber(number)
                setShipmentData({ shipments: [...shipmentData.shipments, ...res.data.Data], loading: false, error: false })
                // setTotalRecords(res.data.TotalRecords);
            }

            if (res.data.Data.length === 20) {
                setHasLoadMore(true)
            } else {
                setHasLoadMore(false)
            }
        }).catch((err) => {
            console.log(err)
            setHasLoadMore(false)
            setShipmentData({ ...shipmentData, loading: false, shipments: [], error: true })
        })
    }

    const calculateAmmount = (ammount) => {
        const amt = Number(parseFloat(ammount).toFixed(2)).toLocaleString('en', {
            minimumFractionDigits: 2
        });


        return amt
    }

    const title = location.pathname === "/Shipments" ? "Shipment Status" : location.pathname === "/Dashboard" ? "DashBoard" : location.pathname === "/Statements" ? "Outstanding Report" : context.isAdmin ? "User Administration" : null;

    const acceptPolicy = async () => {
        setAcceptLoading(true);
        let data = {};
        const userSecret = await JSON.parse(sessionStorage.getItem('user_secret'));
        await getUserData('user_data').then((res) => {
            data = res;
        })
        // axios.get(`https://geolocation-db.com/json/`).then(res => {
        // let ip = res.data.IPv4;
        // let mac = '';
        const header = {
            "Authorization": `Bearer ${data.access_token}`,
            "UserName": data.user_name,
            "UserSecret": userSecret.password,
            "DeviceType": 'W',
            "MacAddress": '',
            "IPAddress": ''
        }
        const body = {
            "AcceptedId": "1",
            "AcceptedBy": userInfo.user_name,
            "AcceptedOn": moment().format(),
            "IPAddress": '',
            "MacAddress": ''
        }
        axios.post(`${HOST_URL}api/v1/UserData/AcceptPolicy`, body, { headers: header })
            .then(res => {
                setAcceptLoading(false);
                if (res.data === '1') {
                    localStorage.setItem('acceptCookies', 'true');
                    setShowprivacy(false);
                } else {
                    setSnackbar({ ...snackbar, open: true, message: "Something went wrong!", severity: "error" })
                }
            })
            .catch((err) => {
                setAcceptLoading(false);
                console.log(err);
                setSnackbar({ ...snackbar, open: true, message: "Something went wrong!", severity: "error" })
            })
        // })
    }

    return (
        <div className="home">
            <HomeMobile />
            <Snackbar autoHideDuration={3000} onClose={() => setSnackbar({ ...snackbar, open: false })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={snackbar.open} >
                <Alert onClose={() => setSnackbar({
                    ...snackbar, open: false
                })} severity={snackbar.severity}>{snackbar.message}</Alert>
            </Snackbar>
            {context.loading ? <div className="loaderFull"><CircularProgress /></div> : null}
            <div className="desktop">
                <Sidebar />
                <div className="main">
                    <div className="header p-1 mb-3">
                        <div className="container-fluid h-100">
                            <div className="headerrow h-100">
                                <div className="headerName">
                                    <img className="me-2 pointer toggleSidebar" src={`/dashboard/Assets/Images/Line.png`} />
                                    <span className="title">{title}</span>
                                    {location.pathname === "/Statements" ? <span className="ms-3" id="excelSave" title="Excel Download"><ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="download-table-xls-button"
                                        table="tabletocopy"
                                        filename="Statement of Account"
                                        sheet="Statement of Account"
                                        buttonText={<GetAppIcon className="advSearchIcon pointer" />} />
                                    </span> : location.pathname === "/Users" ? <span className="ms-3 pointer" data-bs-toggle="modal" data-bs-target="#addUser">
                                        <Button
                                            variant="outlined"
                                            startIcon={<PersonAddIcon className="advSearchIcon" />}
                                        > Add User </Button></span> : null}
                                </div>
                                <div className="headermiddle">
                                    {(location.pathname === "/Shipments") || (location.pathname === "/Users") || (location.pathname === "/Dashboard") ? null : <div className="px-2 mx-2 odue">
                                        <span> Overdue</span>
                                        <p>
                                            <span className="me-1">{currency[0]}</span>
                                            <span>{calculateAmmount(context.invoiceAmmount.overdue)
                                                // parseFloat(context.invoiceAmmount.overdue ?? "").toFixed(2)
                                            }</span>
                                        </p>
                                        <p>
                                            <span className="me-1">{currency[1]}</span>
                                            <span>{calculateAmmount(context.invoiceAmmount?.overdueInUSD)
                                                // parseFloat( ?? "").toFixed(2)
                                            }</span>
                                        </p>
                                    </div>}
                                    {
                                        (location.pathname === "/Users") || (location.pathname === "/Dashboard") ? null :
                                            <div className="px-2 mx-2 odue inv">
                                                <span>
                                                    {location.pathname === "/Shipments" ? "Total Shipments" : "Invoice Amount"}
                                                </span>
                                                {
                                                    location.pathname === "/Shipments" ?
                                                        <p>
                                                            {totalShipment}
                                                        </p> :
                                                        <p>
                                                            <span className="me-1">
                                                                {currency[0]}
                                                            </span>
                                                            <span>
                                                                {calculateAmmount(context.invoiceAmmount.total)
                                                                }
                                                            </span>
                                                        </p>
                                                }
                                                <p>{location.pathname === "/Statements" ?
                                                    <>
                                                        <span className="me-1">
                                                            {currency[1]}
                                                        </span>
                                                        <span>
                                                            {
                                                                calculateAmmount(context.invoiceAmmount.totalInUSD)
                                                            }</span>
                                                    </>
                                                    : null}
                                                </p>
                                            </div>}
                                    {
                                        location.pathname.includes('Dashboard') ?
                                            null
                                            :
                                            <div className="form-group has-search ms-5">
                                                <img className="p-2 form-control-feedback" src={`/dashboard/Assets/Images/Ellipse1.svg`} />
                                                <input type="text" className="form-control" placeholder={location.pathname === "/Shipments" ? "Shipment No, Consignee , Consignor" : "Enter Keywords"} id="tablesearch" autoComplete="off"
                                                    onChange={(event) => { setShipmentId(event.target.value) }}
                                                    onKeyPress={(event) => (event.key === "Enter") && location.pathname == '/Shipments' ? onSearchByShipmnetId(1, cargoStatus, type) : location.pathname == '/Users' ? context.setSearchUser(shipmentId) : context.setSearchValue((preState) => {
                                                        if (preState === shipmentId) {
                                                            return preState
                                                        }
                                                        return shipmentId
                                                    })

                                                    }
                                                />
                                                <button type="button" className="pointer searchInputClr" onClick={SearchInputClr}

                                                ><HighlightOffIcon /></button>
                                            </div>
                                    }
                                    {location.pathname === "/Shipments" ? <><img title="Advance Search" className="ms-3 advSearchIcon pointer" src={`/dashboard/Assets/Images/advsrch.png`} data-bs-toggle="modal" data-bs-target="#advSearch" />
                                        {/* <SyncIcon className="ms-3 pointer" title="Reset Filter" /> */}
                                    </> : null}
                                    <div className="modal fade advSearch" id="advSearch" tabIndex="-1" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Advance Search</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" />
                                                </div>
                                                <div className="modal-body">
                                                    <div className="container-fluid">
                                                        <div className="row">
                                                            <div className="col-md-6 col-12">
                                                                <label htmlFor="consignor" className="form-label">Consignor</label>
                                                                <input type="text" autoComplete="off" className="form-control" id="consignor" placeholder="Consignor Name / Code"
                                                                    value={advanceSearch.Consignor}
                                                                    onChange={(event) => {
                                                                        setAdvanceSearch({ ...advanceSearch, Consignor: event.target.value })
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-12">
                                                                <label htmlFor="consignee" className="form-label">Consignee</label>
                                                                <input type="text" autoComplete="off" className="form-control" id="consignee" placeholder="Consignee Name / Code"
                                                                    value={advanceSearch.Consignee}
                                                                    onChange={(event) => { setAdvanceSearch({ ...advanceSearch, Consignee: event.target.value }) }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 col-12">
                                                                <label htmlFor="load" className="form-label">Load</label>
                                                                <input type="text" autoComplete="off" className="form-control" id="load" placeholder="Load Port Name / Code"
                                                                    value={advanceSearch.LoadPort}
                                                                    onChange={(event) => {
                                                                        setAdvanceSearch({ ...advanceSearch, LoadPort: event.target.value })
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-12">
                                                                <label htmlFor="discharge" className="form-label">Discharge</label>
                                                                <input type="text" autoComplete="off" className="form-control" id="discharge" placeholder="Discharge Port Name / Code"
                                                                    value={advanceSearch.DestinationPort}
                                                                    onChange={(event) => {
                                                                        setAdvanceSearch({ ...advanceSearch, DestinationPort: event.target.value })
                                                                    }}

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 col-12">
                                                                <label htmlFor="eta" className="form-label">ETA</label>
                                                                <div className="form-control" id="eta"
                                                                >Select date range</div>
                                                            </div>
                                                            <div className="col-md-6 col-12">
                                                                <label htmlFor="etd" className="form-label">ETD</label>
                                                                <div className="form-control" id="etd"
                                                                >Select date range</div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 col-12">
                                                                <label htmlFor="shpMode" className="form-label">Shipment Mode</label>
                                                                <div className="shpInput">
                                                                    <label><input type="radio" name="shpMode" value="Air" onChange={(event) => { setAdvanceSearch({ ...advanceSearch, TrasnportMode: event.target.value }) }} /><span>AIR</span></label>
                                                                    <label><input type="radio" name="shpMode" value="Sea" onChange={(event) => { setAdvanceSearch({ ...advanceSearch, TrasnportMode: event.target.value }) }} /><span>SEA</span></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-12">
                                                                <label htmlFor="shpMode" className="form-label">Shipment Status</label>
                                                                <div className="shpInput">
                                                                    <label><input type="radio" name="shpstatus" value="O"
                                                                        onChange={(event) => { setAdvanceSearch({ ...advanceSearch, Status: event.target.value }) }}
                                                                    /><span>Open</span></label>
                                                                    <label><input type="radio" name="shpstatus" value="C" onChange={(event) => { setAdvanceSearch({ ...advanceSearch, Status: event.target.value }) }}
                                                                    /><span>Closed</span></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn ms-0" onClick={onResetHandle}>RESET ALL</button>
                                                    <button type="button" data-bs-dismiss="modal" className="btn" onClick={onSearchHandle}>SEARCH</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="notify">
                                    <div className="row">
                                        <div className="col">
                                            <ul className="navbar-nav">
                                                {location.pathname === "/Shipments" ? <li className="nav-item dropdown">
                                                    <ul className="dropdown-menu" >
                                                        <li className="nothead">You have 14 Notifications <span className="badge bg-warning">14</span></li>
                                                        <li><hr className="dropdown-divider" /></li>
                                                        <li className="notsubhead">
                                                            <PeopleIcon className="text-info me-2" />
                                                            <div>
                                                                <span>New Registered Users</span>
                                                                <p>Lorem ipsum dolor sit amet...</p>
                                                            </div>
                                                        </li>
                                                        <li><hr className="dropdown-divider" /></li>
                                                        <li className="notsubhead">
                                                            <LocalCafeIcon className="text-warning me-2" />
                                                            <i />
                                                            <div>
                                                                <span>New Received Orders</span>
                                                                <p>Lorem ipsum dolor sit amet...</p>
                                                            </div>
                                                        </li>
                                                        <li><hr className="dropdown-divider" /></li>
                                                        <li className="notsubhead">
                                                            <div>
                                                                <span>New Received Orders</span>
                                                                <p>Lorem ipsum dolor sit amet...</p>
                                                            </div>
                                                        </li>
                                                        <li><hr className="dropdown-divider" /></li>
                                                        <li className="seeAll text-info text-center">See All Notifications</li>
                                                    </ul>
                                                </li>
                                                    : null}
                                                {/* {location.pathname === "/Statements" ? <li className="nav-item dropdown">
                                                    {isAdmin ?
                                                        <div>
                                                            <button type="button" style={{ backgroundColor: 'transparent', marginTop: '5px' }}
                                                                onClick={() => {
                                                                    console.log("reset click")
                                                                    context.setType("normal")
                                                                    context.setOrgaizatioCode("")
                                                                    // getShipmentList(sortOrder, sortCriteria, "PreLoaded", 20, 1, "normal", "")

                                                                }}

                                                            >Reset</button>

                                                            <button type="button" className="headerOrganisation" onClick={handleOpenModal} title="Select Organisation">
                                                                <LocationCityIcon />
                                                            </button>
                                                        </div>
                                                        : null}
                                                    <Modal
                                                        className={classes.modal}
                                                        open={showModal}
                                                        onClose={handleCloseModal}
                                                        closeAfterTransition
                                                        BackdropComponent={Backdrop}
                                                        BackdropProps={{
                                                            timeout: 500,
                                                        }}
                                                    >
                                                        <Fade in={showModal}>
                                                            <div className={classes.paper}>
                                                                <HeaderOrganisations onClose={handleCloseModal} onSelect={onSelectOrg} />
                                                            </div>
                                                        </Fade>
                                                    </Modal>
                                                </li> : null}

                                                {location.pathname === "/Shipments" ? <li className="nav-item dropdown"> */}
                                                {

                                                    isAdmin && location.pathname !== "/Users" ?
                                                        <div>
                                                            {
                                                                location.pathname.includes('Shipments') ?
                                                                    <>
                                                                        <button type="button" style={{ backgroundColor: 'transparent', marginTop: '5px' }}
                                                                            onClick={() => {
                                                                                setType("normal")
                                                                                context.setType("normal")
                                                                                context.setOrgaizatioCode("")
                                                                                setOrganization("")
                                                                                getShipmentList(sortOrder, sortCriteria, "PreLoaded", 20, 1, "normal", "")
                                                                            }}
                                                                        >Reset</button>

                                                                        <button type="button" className="headerOrganisation" onClick={handleOpen} title={context.orgaizatioCode === '' ? "Select Organisation" : `Selected Organisation :\n ${context.orgaizatioCode.replaceAll(",", "\n")}`}>
                                                                            <LocationCityIcon />
                                                                        </button>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        : null
                                                }
                                                <Modal
                                                    className={classes.modal}
                                                    open={open}
                                                    onClose={handleClose}
                                                    closeAfterTransition
                                                    BackdropComponent={Backdrop}
                                                    BackdropProps={{
                                                        timeout: 500,
                                                    }}
                                                >
                                                    <Fade in={open}>
                                                        <div className={classes.paper}>
                                                            <HeaderOrganisations onClose={handleClose} onSelect={onSelectOrganization} />
                                                        </div>
                                                    </Fade>
                                                </Modal>
                                                {/* </li> : null} */}
                                                <li className="nav-item dropdown">
                                                    <div className="user pointer" title="User" data-bs-toggle="dropdown" >
                                                        <AccountCircleIcon className="userIcon" />
                                                        <KeyboardArrowDownIcon />
                                                    </div>
                                                    <ul className="dropdown-menu" >
                                                        <li className="notsubhead">
                                                            <AccountCircleIcon className="userIcon me-2" />
                                                            <div>
                                                                <span>{userInfo.name_of_user}</span>
                                                                <p>{userInfo.user_name}</p>
                                                            </div>
                                                        </li>
                                                        <li><hr className="dropdown-divider" /></li>
                                                        <li className="notsubhead pointer" data-bs-toggle="modal" data-bs-target="#chngPass" >
                                                            <LockOutlinedIcon className="me-2" />
                                                            <i />
                                                            <div>
                                                                <span>Change Password</span>
                                                            </div>
                                                        </li>
                                                        <li><hr className="dropdown-divider" /></li>
                                                        <li className="notsubhead pointer" onClick={LogOut}>
                                                            <PowerSettingsNewIcon className="me-2" />
                                                            <i />
                                                            <div>
                                                                <span>Logout</span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ChangePassword />
                    <div className="aside">
                        <Switch>
                            <Route exact path='/:id' children={(url) => {
                                if (url.location.pathname == '/Shipments' || url.location.pathname == '/Shipments/') {
                                    return (
                                        <div className="container-fluid shipmentTable homeshipments pe-0">
                                            <ShipmentTable
                                                shipmentData={shipmentData}
                                                onScrollToLoad={onScrollToLoad}
                                                hasLoadMore={hasLoadMore}
                                                onSortByETD={onSortByETD}
                                                onAlertSave={onAlertSave}
                                            />
                                        </div>
                                    )
                                } else if (url.location.pathname == "/Dashboard" || url.location.pathname == '/Dashboard') {
                                    return (
                                        <div className="container-fluid dashboardPage pe-0">
                                            <Dashboard />
                                        </div>
                                    )
                                } else if (url.location.pathname == "/Statements" || url.location.pathname == '/Statements') {
                                    return (
                                        <div className="container-fluid shipmentTable tableStOA pe-0">
                                            <AccountStatement shipmentData={shipmentData} />
                                        </div>
                                    )
                                } else if (url.location.pathname == "/Users" || url.location.pathname == '/Users') {
                                    return (
                                        <div className="container-fluid shipmentTable tableStOA userList pe-0">
                                            <UserList shipmentData={shipmentData} />
                                        </div>
                                    )
                                }
                            }} />
                        </Switch>
                    </div>
                </div>
            </div>
            <Drawer anchor='bottom' open={userInfo.accepted === '1' ? false : true} BackdropProps={{ invisible: true }} style={{ position: 'relative ', zIndex: 'unset' }} disableEnforceFocus>
                <div className='bottomoverlay'>
                    <div className="container py-3">
                        <div className='row'>
                            <div className="col-lg-10">
                                <p className="text-white" style={{ marginBottom: 0 }}>This site uses cookies as described in our <a target='blank' href="https://www.tgfworld.com/privacy-policy.php" style={{ textDecoration: 'underline', fontWeight: 600, color: '#ff6600' }}>Privacy Policy.</a> By acknowledging this message or continuing to browse our site, you agree to accept cookies. we'll assume you're OK to continue.
                                </p>
                            </div>
                            <div className="col-lg-2">
                                <button onClick={acceptPolicy}>{!acceptLoading ? 'ACCEPT' :
                                    <CircularProgress size="1rem" color='white' />}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}
export default Home