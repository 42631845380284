import React, { createContext, useState } from 'react'


const StoreContext = createContext()

const StoreProvider = (props) => {

    //notifyEmail
    const [email, setEmail] = useState("")
    const [shipmentId, setShipmentId] = useState("")
    const [selectedTab, setSelectedTab] = useState("")
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("")
    const [isAdmin, setIsAdmin] = useState(false)
    const [sortFilter, setSortFilter] = useState({
        sortCriteria: "ETD",
        sortOrder: "Desc"
    })

    const [stmData, setStmData] = useState([]);

    const [invoiceAmmount, setInvoiceAmmount] = useState({
        overdue: 0.0,
        total: 0.0,
        totalInUSD: 0.0,
        overdueInUSD: 0.0,
        totalShipment: 0,
    })


    const [selectedUser, setSelectedUser] = useState({})

    const [searchUser, setSearchUser] = useState("")

    const imgUrl = "http://dev.sflhub.com/transysnew";
    // const imgUrl = ""

    const [orgaizatioCode, setOrgaizatioCode] = useState("")
    //admin search for user shipments
    const [type, setType] = useState("normal")

    const [sidebarclosed, setSidebarclosed] = useState(false);


    const [value, setValue] = React.useState(0);

    return (
        <StoreContext.Provider
            value={{
                shipmentId,
                selectedTab,
                sortFilter,
                invoiceAmmount,
                isAdmin,
                selectedUser,
                stmData,
                searchValue,
                searchUser,
                imgUrl,
                email,
                loading,
                orgaizatioCode,
                type,
                sidebarclosed,
                value,
                setType,
                setOrgaizatioCode,
                setShipmentId,
                setSelectedTab,
                setSortFilter,
                setInvoiceAmmount,
                setIsAdmin,
                setSelectedUser,
                setStmData,
                setSearchValue,
                setSearchUser,
                setEmail,
                setLoading,
                setSidebarclosed,
                setValue
            }}

        >
            {props.children}
        </StoreContext.Provider>
    )

}

export { StoreProvider, StoreContext }