import React, { useContext, useState, useEffect } from 'react'
import { StoreContext } from '../../store/StoreProvider'
import { getUserData } from '../../utility/utils'
import axios from 'axios'
import { HOST_URL } from '../../config'


function UpdateUser(props) {
    const context = useContext(StoreContext)
    const [username, setUsername] = useState("")
    const [fullName, setfullName] = useState("")
    const [email, setEmail] = useState("")
    // const [org, setOrg] = useState("")
    const [organization, setOrganization] = useState([])
    const [header, setHeader] = useState({})
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})
    const [org, setOrg] = useState("")

    useEffect(async () => {
        let userData = {}
        const userSecret = JSON.parse(sessionStorage.getItem('user_secret'))
        await getUserData("user_data").then((res) => {
            userData = res
            setData(res)
        }).catch(() => {
            // props.history.goBack()
            return;

        })

        const header = {
            "Authorization": `Bearer ${userData.access_token}`,
            'UserName': userData.user_name,
            "UserSecret": userSecret.password,
            'DeviceType': "W",
            'MacAddress': '',
            'IPAddress': ''
        }

        setHeader(header)
        getOrganization(header)
    }, [])



    useEffect(() => {
        setEmail(context.selectedUser.email)
        setfullName(context.selectedUser.name_of_user)
        setOrg(context.selectedUser.organisations)
    }, [context.selectedUser])

    const getOrganization = (header) => {
        // https://dev.sflhub.com/transysapitemp/api/v1/UserData/GetAllOrganizations
        const END_POINT = HOST_URL + "api/v1/UserData/GetAllOrganizations"

        axios.get(END_POINT, {
            headers: header
        })
            .then((res) => {
                setLoading(false)
                setOrganization(res.data.Data)
            }).catch((err) => {
                console.log(err)
            })

    }

    const onSaveAction = (header) => {
        if (fullName && email && org) {


            const params = new URLSearchParams()
            params.append("ActionName", "Edit")
            params.append("UserId", context.selectedUser.user_id)
            params.append("FullName", fullName)
            // params.append("Password", password)
            params.append("Email", email)
            params.append("CompanyCode", "TR001")
            params.append("CountryCode", "IN")

            const END_POINT = `${HOST_URL}api/v1/UserData/AddorEditUser`

            axios.post(END_POINT, params, {
                headers: header
            })
                .then((res) => {
                    setOrg("")
                    setfullName("")
                    // setPassword("")
                    setEmail("")
                    setOrg("")
                    props.getUserList()
                }).catch((err) => {
                    console.log(err)
                })


        }
    }

    return (
        <div className="modal fade advSearch" id="updateUser" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Update User</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" />
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="useremail" className="form-label">User Email-Id</label>
                                    <input className="form-control" id="useremail" placeholder="User Email-Id" type="text" value={email} onChange={(event) => setEmail(event.target.value)} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="fullName" className="form-label">Full Name</label>
                                    <input className="form-control" id="fullName" placeholder="Full Name" type="text" value="Admin" value={fullName} onChange={(event) => { setfullName(event.target.value) }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="userId" className="form-label">User Id</label>
                                    <input className="form-control" id="userId" placeholder="User Id" type="text" disabled value={context.selectedUser.user_id} />
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-12">
                                    <label htmlFor="selectuprole" className="form-label">Organization</label>
                                    <select className="form-control form-select" id="selectuprole" onChange={(e) => { setOrg(e.target.value) }} value={org}>
                                        <option value="">-- Select Org --</option>
                                        {
                                            organization.map((item) => {

                                                return (
                                                    <option value={item.org_code}>{item.org_name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn ms-0" data-bs-dismiss="modal" onClick={() => onSaveAction(header)}>Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateUser
