import './App.css';
import Navigation from './navigation/Navigation'
import { StoreProvider } from './store/StoreProvider' 

function App() {
  return (
    <StoreProvider>
      <Navigation />
    </StoreProvider>
  );
}

export default App;
