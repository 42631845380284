import React from 'react'
import moment from 'moment'
import AddUser from './../common/AddUser';

const Cards = (props) => {
    let estimatedDate = ""
    let ATD = ""
    let actualDate = ""
    let ETD = ""
    let ETA = ""
    let ATA = ""

    if (props.item.ATA) {
        ATA = moment(props.item.ATA).format("DD/MM/YYYY")
        // <span style = {{fontSize : "0.6rem"}}>ATA: {}</span>
    }
    if (props.item.ETA) {
        ETA = moment(props.item.ETA).format("DD/MM/YYYY")
        // <span style = {{fontSize : "0.6rem"}}>ETA: {}</span>
    }



    if (props.item.ATD) {
        ATD = moment(props.item.ATD ?? "").format("DD/MM/YYYY");
        // actualDate = <span style = {{fontSize : "0.6rem"}}>ATD: {ATD}</span>;
    }


    if (props.item.ETD) {
        ETD = moment(props.item.ETD ?? "").format("DD/MM/YYYY");
        // estimatedDate = <span style = {{fontSize : "0.6rem"}}>ETD: {ETD}</span>
    }
    // invisible

    return (
        <div className='cardContainer'>
            <p className="voy">{props.item.TransportMode === "SEA" ? "Voyage : " : props.item.TransportMode === "AIR" ? "Flight : " : null}{props.item.Voyage ? <span>{props.item.Voyage}</span> : <span>&nbsp;</span>}</p>
            <p className={props.item.DelayedStr === "On Time" ? "st onTime" : "st delay"} >{props.item.DelayedStr ? props.item.DelayedStr : <span>&nbsp;</span>}</p>
            <div className="cardimg my-2">
                <hr className={props.preNode ? "completed leftline " : "completed leftline invisible"} />
                {/* <img src="https://dev.sflhub.com/transystemp/Assets/images/timeline/deliver.svg" alt="default logo" /> */}
                <img src="/dashboard/Assets/Images/delivery.svg" alt="default logo" />
                <hr className={props.item.DischargePortName ? "completed rightline " : "completed rightline invisible"} />
            </div>
            <p className={props.item.LoadPortName}><span>{props.item.LoadPortName}</span></p>
            <p className="voyE">{props.index !== 0 ? <span>ETA: {ETA}</span> : null}{(props.index !== props.length - 1) ? <span>ETD: {ETD}</span> : null}</p>
            <p className="voyE">{props.index !== 0 ? <span>ATA: {ATA}</span> : null}{(props.index !== props.length - 1) ? <span>ATD: {ATD}</span> : null}</p>
        </div>
    )
}
//ETA,ATA

function Routing(props) {
    const length = props.routingData.length;
    let preNode = ""
    return (
        <div className="milestone routing">
            <div className="container-fluid">
                <div className="milestonerow" style={props.routingData.length < 6 ? { justifyContent: 'center' } : null}>
                    {props.routingData.map((item, index) => {
                        if (index !== 0) {
                            preNode = props.routingData[index - 1].DischargePortName
                        }
                        return (
                            <Cards item={item} length={length} index={index} preNode={preNode} />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Routing
