import React from 'react'
import moment from 'moment';

const Container = (props) => {

    return (
        <div className="containerPanel">
            <div className="container-fluid">
                <table>
                    <thead>
                        <tr>
                            <th>Container No</th>
                            <th>Count</th>
                            <th>Type</th>
                            <th>Weight</th>
                            <th>Placed at Factory for Pick up</th>
                            <th>Placed at Factory for Delivery</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.Summary.ContainerData.map((ContainerData) => {
                                let pickDate = "";
                                if (ContainerData.ContainerVehiclePlacedatFactoryforPickup) {
                                    pickDate = `${moment(ContainerData.ContainerVehiclePlacedatFactoryforPickup).format("DD/MM/YYYY")}`
                                }
                                let deliveryDate = ""
                                if (ContainerData.ContainerVehiclePlacedatFactoryforDelivery) {
                                    deliveryDate = `${moment(ContainerData.ContainerVehiclePlacedatFactoryforDelivery).format("DD/MM/YYYY")}`
                                }
                                return (
                                    <tr>
                                        <td title={ContainerData.ContainerNumber}>{ContainerData.ContainerNumber}</td>
                                        <td title={ContainerData.Count}>{ContainerData.Count}</td>
                                        <td title={ContainerData.ContainerType}>{ContainerData.ContainerType}</td>
                                        <td title={`${ContainerData.GrossWeight} ${ContainerData.GrossWeightUnit}`}>{ContainerData.GrossWeight} {ContainerData.GrossWeightUnit}</td>
                                        <td title={pickDate}>{pickDate}</td>
                                        <td title={deliveryDate}>{deliveryDate}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Container
