
const getUserData = (key) =>{
    return new Promise((resolve, reject)=> {
        let userData = JSON.parse(sessionStorage.getItem(key))
        if(userData){
            resolve(userData)
        }else{
            reject(false)
        }
    })
}


const setUserData = (userData, key) => {
    return new Promise((resolve, reject) => {
        const data = JSON.stringify(userData)
        sessionStorage.setItem(key, data)
        resolve(true)
    })
}

const deleteData = (key) => {
    return new Promise((resolve, reject)=> {
        sessionStorage.removeItem(key)
        resolve(true)
    })
}





export {getUserData, setUserData, deleteData}