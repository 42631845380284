import React, { useContext, useState, useEffect } from 'react'
import { HOST_URL } from '../../config'
import axios from 'axios'
import { getUserData } from '../../utility/utils'
import { Headset } from '@material-ui/icons'
import $ from 'jquery';
import { StoreContext } from '../../store/StoreProvider'



function UpdateRole(props) {
    const [role, setRole] = useState("")
    const [userData, setUserData] = useState([])
    const [header, setHeader] = useState({})
    // const [data, setData] = useState({})
    const context = useContext(StoreContext)


    const [userRole, setUserRole] = useState([])

    useEffect(async () => {
        let userData = {}
        const userSecret = JSON.parse(sessionStorage.getItem('user_secret'))
        await getUserData("user_data").then((res) => {
            userData = res
            // console.log(res)
            // setData(res)
        }).catch(() => {
            // props.history.goBack()
            return;

        })

        const header = {
            "Authorization": `Bearer ${userData.access_token}`,
            'UserName': userData.user_name,
            "UserSecret": userSecret.password,
            'DeviceType': "W",
            'MacAddress': '',
            'IPAddress': ''
        }

        setHeader(header)
        getUserOrg(header)
    }, [])


    const getUserOrg = (header) => {
        // https://dev.sflhub.com/transysapitemp/api/v1/UserData/GetAllOrganizations
        const END_POINT = HOST_URL + "api/v1/UserData/GetUserRoles/YWRtaW5AdHJhbnN5cy5jb20="

        axios.get(END_POINT, {
            headers: header
        })
            .then((res) => {

                setUserRole(res.data.Data)
                // setLoading(false)
                // props.getUserList()

                // setUserOrg(res.data.Data)
            }).catch((err) => {
                console.log(err)
            })

    }

    const onRoleChange = () => {
        $('#selectrole').val('');
        const END_POINT = `${HOST_URL}api/v1/UserData/AddRoll?user_id=${context.selectedUser.email}&roll_code=ADM,CLIENT`;
        // console.log('userId=>', userId, 'URL=>', URL);
        axios.post(END_POINT, {}, {
            headers: header
        }).then((res) => {
            props.getUserList()
            // setUserData(res.data.rolls)
        }).catch((err) => {
            console.log(err);
        })

    }
    // const getUserData = () => {
    //     // const userId = context.showRightSidebar.childProps.data.user_id;
    //     const URL = `${HOST_URL}/api/GetUserRolls?user_id=${userId}`;
    //     console.log('userId=>', userId, 'URL=>', URL);
    //     axios.get(URL).then((res) => {
    //         console.log(res)
    //         setUserData(res.data.rolls)
    //     }).catch((err) => {
    //         console.log(err);
    //     })
    // }

    return (
        <div className="modal fade advSearch" id="updaterole" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Update Role</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" />
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="selectrole" className="form-label">Select Role</label>
                                    <select className="form-control form-select" id="selectrole" onChange={(e) => { setRole(e.target.value) }} value={role}>
                                        <option value="">-- Select Role --</option>
                                        {
                                            userRole.map((user) => {
                                                return (
                                                    <option value={user.roll_code}>{user.roll_name}</option>
                                                )
                                            })
                                        }

                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn ms-0" data-bs-dismiss="modal" onClick={onRoleChange}>Save Changes</button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default UpdateRole
