import React, { useEffect, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ShipmentDetails from './ShipmentDetails'
import { CircularProgress } from '@material-ui/core';
import { HOST_URL } from '../../config'
import axios from 'axios'
import { getUserData } from '../../utility/utils'
import { TurnedIn } from '@material-ui/icons';
import { StoreContext } from '../../store/StoreProvider'
import UpdateIcon from '@material-ui/icons/Update';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CancelIcon from '@material-ui/icons/Cancel';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import InfiniteScroll from 'react-infinite-scroller';
import AddUser from '../common/AddUser'
import ResetPassword from '../common/ResetPassword'
import UpdateOrg from '../common/UpdateOrg'
import UpdateRole from '../common/UpdateRole'
import UpdateUser from '../common/UpdateUser';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import $ from 'jquery';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

// import { StoreContext } from '../../store/StoreProvider'


export default function UserList(props) {


    const [pageNumber, setPageNumber] = useState(1);
    const [sortCriteria, setSortCriteria] = useState("username");
    const [sortOrder, setSortOrder] = useState("Desc");
    const [hasLoadMore, setHasLoadMore] = useState(false)
    const context = useContext(StoreContext)
    const [updateUser, setUpdateUser] = useState(false)


    const [userData, setUserData] = useState({
        userInfo: [],
        loading: false,
        error: false
    });

    const [snackbar, setSnackbar] = useState({ severity: "", open: false, message: "" })

    useEffect(() => {
        context.setIsAdmin(true)
        context.setSelectedTab("user")
        getUserList(sortCriteria, 1, 30, "UserList");

    }, [])

    useEffect(() => {
        if (context.searchUser !== "") {
            getUserList(1, 30, "UserList")

        }
    }, [context.searchUser])


    const onSortUser = () => {
        let sort = sortOrder
        if (sort === "Asc") {
            sort = "Desc"
        } else {
            sort = "Asc"
        }

        setSortOrder(sort)

        getUserList(sort)


    }

    const getUserList = async (sortOrder = "Desc", pageNumber = 1, pageSize = 20, callFrom = "user") => {
        let data = {}
        if (pageNumber === 1) {

            setUserData({ ...userData, userInfo: [], loading: true, error: false })
        }
        const userSecret = await JSON.parse(sessionStorage.getItem('user_secret'))
        await getUserData('user_data').then((res) => {
            data = res;
        })
        const header = {
            "Authorization": `Bearer ${data.access_token}`,
            "UserName": data.user_name,
            "UserSecret": userSecret.password,
            "DeviceType": 'W',
            "MacAddress": '',
            "IPAddress": ''
        }
        const URL = `${HOST_URL}api/v1/UserData/GetAllUsers?organizationCode=&pageNumber=${pageNumber}&pageSize=${pageSize}&sortCriteria=${sortCriteria}&sortOrder=${sortOrder}&searchText=${context.searchUser}`;

        axios.get(URL, { headers: header }).then((res) => {

            if (callFrom === "user") {
                setSnackbar({ ...snackbar, open: true, message: "User updated successfully.", severity: "success" })
            }

            if (res.data.Data !== []) {
                setUserData({ ...userData, userInfo: res.data.Data, loading: false, error: false })
                setHasLoadMore(true)
                setPageNumber(pageNumber)
            } else {
                setHasLoadMore(false)
            }
        }).catch(() => {
            setUserData({ ...userData, userInfo: [], loading: false, error: true })
        })
    }


    const onScrollLoadMore = () => {

    }

    function Row(props) {
        const { row } = props;


        const onDeactivate = async () => {

            let data = {}
            const userSecret = await JSON.parse(sessionStorage.getItem('user_secret'))

            await getUserData('user_data').then((res) => {
                data = res;
            })
            const header = {
                "Authorization": `Bearer ${data.access_token}`,
                "UserName": data.user_name,
                "UserSecret": userSecret.password,
                "DeviceType": 'W',
                "MacAddress": '',
                "IPAddress": ''
            }

            const END_POINT = `${HOST_URL}api/v1/UserData/DeactivateUser?user_id=${row.user_id}`

            axios.post(END_POINT, {}, {
                headers: header
            }).then((res) => {
                getUserList(sortOrder)

            }).catch((err) => {
                console.log(err)
            })


        }

        const onActivate = async () => {

            let data = {}
            const userSecret = await JSON.parse(sessionStorage.getItem('user_secret'))

            await getUserData('user_data').then((res) => {
                data = res;
            })
            const header = {
                "Authorization": `Bearer ${data.access_token}`,
                "UserName": data.user_name,
                "UserSecret": userSecret.password,
                "DeviceType": 'W',
                "MacAddress": '',
                "IPAddress": ''
            }

            const END_POINT = `${HOST_URL}api/v1/UserData/ActivateUser?user_id=${row.user_id}`

            axios.post(END_POINT, {}, {
                headers: header
            }).then((res) => {
                getUserList(sortOrder)

            }).catch((err) => {
                console.log(err)
            })


        }


        return (
            <React.Fragment>
                <TableRow className={row.is_active !== "Y" ? "deactivate" : ""}>
                    <TableCell align="left" title={row.user_id}>{row.user_id ?? " "}</TableCell>
                    <TableCell align="left" title={row.name_of_user}>{row.name_of_user ?? " "}</TableCell>
                    <TableCell align="left" title={row.email}>{row.email ?? " "}</TableCell>
                    <TableCell align="left" title={row.organisations}>{row.organisations ?? " "}</TableCell>
                    <TableCell align="right" className="actionlist">
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                            <Button title="Update User" data-bs-toggle="modal" data-bs-target="#updateUser" onClick={() => context.setSelectedUser(row)} disabled={row.is_active !== "Y" ? true : false}><UpdateIcon /></Button>
                            <Button title="Reset Password" data-bs-toggle="modal" data-bs-target="#resetPass" onClick={() => context.setSelectedUser(row)} disabled={row.is_active !== "Y" ? true : false} ><VpnKeyIcon /></Button>
                            <Button title="Update Organization" data-bs-toggle="modal" data-bs-target="#updateOrg" disabled={row.is_active !== "Y" ? true : false} ><LocationCityIcon onClick={() => {
                                setUpdateUser(!updateUser)
                                context.setSelectedUser(row)
                            }} /></Button>
                            {row.is_active === "Y" ? <Button title="Deactivate User" id="deactivateUser" onClick={() => { onDeactivate(row) }}><CancelIcon /></Button> :
                                <Button title="Activate User" id="activateUser" onClick={() => { onActivate(row) }}><CheckCircleIcon /></Button>}
                        </ButtonGroup></TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }


    return (
        <TableContainer component={Paper} className="tableCont">
            <Snackbar autoHideDuration={3000} onClose={() => setSnackbar({ ...snackbar, open: false })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={snackbar.open} >
                <Alert onClose={() => setSnackbar({
                    ...snackbar, open: false
                })} severity={snackbar.severity}>{snackbar.message}</Alert>
            </Snackbar>
            <AddUser getUserList={getUserList} />
            <ResetPassword getUserList={getUserList} />
            <UpdateOrg getUserList={getUserList} updateUser={updateUser} />
            <UpdateRole getUserList={getUserList} />
            <UpdateUser getUserList={getUserList} />
            <InfiniteScroll
                pageStart={0}
                loadMore={() => onScrollLoadMore()}
                hasMore={false}
                loader={<div className="loader" key={0}>Loading ...</div>}
                useWindow={false}
            >
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell>User ID</TableCell>
                            <TableCell align="left" onClick={onSortUser}>Full Name</TableCell>
                            <TableCell align="left">E-Mail Id</TableCell>
                            <TableCell align="left">Organizations</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    {!userData.loading ? <TableBody>
                        {userData.userInfo.map((row, index) => {
                            return (
                                <Row key={row.InvoiceNo} row={row} index={index} />
                            )
                        })}
                    </TableBody > : <TableBody className="loaderProgress"><TableRow><TableCell colSpan="5"><div><CircularProgress size={"2rem"} /></div></TableCell></TableRow></TableBody>}
                </Table>
            </InfiniteScroll>
        </TableContainer>
    );
}

