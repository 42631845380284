import React, { useEffect, useState, useContext } from 'react';
import { Bar, Chart } from 'react-chartjs-2';


function ShipmentCount(props) {
    const shpType = [];
    const shpCount = [];
    let max = 0;
    props.data?.forEach(ele => {
        shpType.push(ele.containerMode)
        shpCount.push(ele.shipmentCount)
    })
    if (props.data?.length) {
        max = (Math.max(...shpCount) + (0.1 * Math.max(...shpCount)))
    }

    const data = {
        labels: shpType,
        datasets: [
            {
                label: 'Shipment Count',
                data: shpCount,
                backgroundColor: '#ee7821',
            }
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: true
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    max: max,
                },
                gridLines: {
                    drawOnChartArea: false
                },
                display: false,
            }],
            xAxes: [{
                barPercentage: 0.6,
                maxBarThickness: 50,
                gridLines: {
                    drawOnChartArea: false
                }
            }]
        },
        tooltips: {
            enabled: true,
            mode: 'label',
            backgroundColor: '#000',
            callbacks: {
                label: function (tooltipItem) {
                    return tooltipItem.yLabel;
                },
                title: function (tooltipItems, data) {
                    var idx = tooltipItems[0].index;
                    return 'Title:' + data.labels[idx];
                }
            }
        },
        hover: {
            "animationDuration": 0
        },
        animation: {
            // "duration": 1,
            "onComplete": function () {
                var chartInstance = this.chart,
                    ctx = chartInstance.ctx;

                ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';

                this.data.datasets.forEach(function (dataset, i) {
                    var meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                        var data = dataset.data[index] ? dataset.data[index] : '';
                        ctx.fillText(data, bar._model.x, bar._model.y);
                    });
                });
            }
        },
    }


    return (
        <div className='bardata' >
            <Bar data={data} options={options} />
        </div>

    )
}

export default React.memo(ShipmentCount)
