import React, { useState, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Login from '../component/screens/Login';
import Home from '../component/screens/Home';
import Error from '../component/screens/Error';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Slide from '@material-ui/core/Slide';
import { Button } from '@material-ui/core';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Navigation = () => {
    const [userData, setUserData] = useState("")
    const [online, setOnline] = useState(window.navigator.onLine)
    const [openalert, setOpenalert] = useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenalert(false);
    };

    useEffect(() => {
        setUserData(JSON.parse(sessionStorage.getItem("UserData")))
        window.addEventListener('offline', () => setOnline(false));
        window.addEventListener('online', () => setOnline(true));
    }, [])

    useEffect(() => {
        !online && setOpenalert(true);
    }, [online])

    return (
        <BrowserRouter basename="/dashboard">
            {
                online ?
                    <Switch>
                        {/* <Redirect from = "/" to = "transysnew" exact/> */}
                        <Route path="/" component={Login} exact />
                        <Route path="/:id" component={Home} exact />
                        <Route component={Error} />
                    </Switch >
                    :
                    <div className='noInternet'>
                        <img src="/dashboard/Assets/Images/logo-small.png" alt="transysLogo" />
                        <p>Connect to the internet</p>
                        <p>You're offline. Check your connection.</p>
                        <Button onClick={() => window.location.reload()}>RETRY</Button>
                    </div>
            }
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={openalert} autoHideDuration={3000} onClose={handleClose}>
                <Alert severity={'error'} onClose={handleClose}>Internet connection is interrupted!</Alert>
            </Snackbar>
        </BrowserRouter >

    )
}





export default Navigation;