import React, { useState, useEffect } from 'react'
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import moment from 'moment';

function AirbnbThumbComponent(props) {
    return (
        <span {...props}>
            <span className="bar" />
            <span className="bar" />
            <span className="bar" />
        </span>
    );
}

const SFLSlider = withStyles({
    root: {
        // width: marks.length * 50,
        width: '100%',
    },
    thumb: {
        height: 27,
        width: 5,
        backgroundColor: 'transparent',
        // border: '1px solid currentColor',
        marginTop: -8,
        marginLeft: 0,
        boxShadow: 'none',
        '&:focus, &:hover, &$active': {
            boxShadow: 'none',
        },
        '& .bar': {
            // display: inline-block !important;
            height: 20,
            width: 2,
            backgroundColor: '#3d3456',
            // marginLeft: 1,
            // marginRight: 1,
        },
    },
    active: {},
    track: {
        height: 12,
        borderRadius: 0,
        backgroundColor: "#ee7821",
        border: 0
    },
    rail: {
        color: "#d8d8d8",
        opacity: 1,
        height: 12,
        borderRadius: 0
    },
    mark: {
        backgroundColor: "#fff",
        height: 12,
        width: 2
    }
})(Slider);

function CustomSlider({ marks, stDate, enDate, chngStDate, chngEnDate, dtFormat, finYear }) {
    const [value, setValue] = useState([0, 1]);
    const [sliderValue, setSliderValue] = useState([0, 1])
    // const [scrollPos, setScrollPos] = useState(0);

    useEffect(() => {
        setValue([0, 1]);
        setSliderValue([0, 1]);
        // if (dtFormat === 'Y') {
        //     chngStDate('01-01-2021');
        //     chngEnDate('31-12-2021');
        // } else 
        if (dtFormat === 'Q') {
            chngStDate(`01-04-${finYear}`);
            chngEnDate(`30-06-${finYear}`);
        } else if (dtFormat === 'M') {
            chngStDate(`01-04-${finYear}`);
            chngEnDate(`30-04-${finYear}`);
        } else if (dtFormat === 'D') {
            chngStDate(`01-04-${finYear}`);
            chngEnDate(`01-04-${finYear}`);
        }
    }, [dtFormat, finYear])

    const changeValue = () => {
        setSliderValue(value);
        if ((value[1] - 1) === marks[marks.length - 1].value) {
            chngEnDate(`31-03-${finYear + 1}`);
        }
        if ((sliderValue[0] !== value[0])) {
            chngStDate(marks[value[0]].string);
        } else if ((sliderValue[1] !== value[1]) && (value[1] !== (marks.length))) {
            const date = moment(marks[value[1]].string, 'DD-MM-YYYY').subtract(1, "day").format("DD-MM-YYYY");
            chngEnDate(date);
        }
    }


    return (
        <SFLSlider marks={marks} min={0} max={marks.length} value={value} onChange={(e, newValue) => (newValue[0] !== marks.length && newValue[1] !== 0 && newValue[0] < newValue[1]) && setValue(newValue)} onChangeCommitted={changeValue} ThumbComponent={AirbnbThumbComponent} />
    )
}

export default CustomSlider
