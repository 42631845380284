import React, { useEffect, useState, useContext } from 'react';
import { Bar, Chart } from 'react-chartjs-2';


function VolumeByAir(props) {
    const department = [];
    const volume = [];
    let max = 0;
    props.data?.forEach(ele => {
        department.push(ele.department)
        volume.push(ele.volume)
    })
    if (props.data?.length) {
        max = (Math.max(...volume) + (0.1 * Math.max(...volume)))
    }

    const data = {
        labels: department,
        datasets: [
            {
                label: 'Sum of Chargeable Weights',
                data: volume,
                backgroundColor: '#ee7821',
            }
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: true
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    max: max,
                },
                gridLines: {
                    drawOnChartArea: false
                },
                display: false
            }],
            xAxes: [{
                barPercentage: 0.6,
                maxBarThickness: 50,
                gridLines: {
                    drawOnChartArea: false
                }
            }]
        },
        tooltips: {
            enabled: true,
            mode: 'label',
            callbacks: {
                label: function (tooltipItem) {
                    return tooltipItem.yLabel + ' kg';
                },
                title: function (tooltipItems, data) {
                    var idx = tooltipItems[0].index;
                    return 'Title:' + data.labels[idx];
                }
            }
        },
        hover: {
            "animationDuration": 0
        },
        animation: {
            // "duration": 1,
            "onComplete": function () {
                var chartInstance = this.chart,
                    ctx = chartInstance.ctx;

                ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';

                this.data.datasets.forEach(function (dataset, i) {
                    var meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                        var data = dataset.data[index] ? `${props.amount(dataset.data[index])} kg` : '';
                        ctx.fillText(data, bar._model.x, bar._model.y);
                    });
                });
            }
        },
    }


    return (
        <div className='bardata' >
            <Bar data={data} options={options} />
        </div>

    )
}

export default React.memo(VolumeByAir)
