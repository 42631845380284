import React, { useState, useEffect } from 'react'
import { HOST_URL } from '../../config'
import axios from 'axios'
import { getUserData } from '../../utility/utils'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import $ from 'jquery';


const AddUser = (props) => {

    const [userOrg, setUserOrg] = useState([])
    const [loading, setLoading] = useState(false)
    // const [organizationData, setOrganizationData] = useState([])
    // const [countries, setCountries] = useState([])
    const [username, setUsername] = useState("")
    const [fullName, setfullName] = useState("")
    const [email, setEmail] = useState("")
    const [org, setOrg] = useState("")
    const [password, setPassword] = useState("")
    // const [country, setCountry] = useState("")
    const [header, setHeader] = useState({})
    const [data, setData] = useState({})
    // const context = useContext(AuthContext)

    const [severity, setSeverity] = useState('success')
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = useState("user login sucessfully")

    const [userCheck, setUserCheck] = useState(false)

    // const context = useContext(StoreContext)




    useEffect(async () => {


        let userData = {}
        const userSecret = JSON.parse(sessionStorage.getItem('user_secret'))
        await getUserData("user_data").then((res) => {
            userData = res
            console.log(res)
            setData(res)
        }).catch(() => {
            // props.history.goBack()
            return;

        })

        const header = {
            "Authorization": `Bearer ${userData.access_token}`,
            'UserName': userData.user_name,
            "UserSecret": userSecret.password,
            'DeviceType': "W",
            'MacAddress': '',
            'IPAddress': ''
        }

        setHeader(header)
        getOrganization(header)
    }, [])


    const getOrganization = (header) => {
        // https://dev.sflhub.com/transysapitemp/api/v1/UserData/GetAllOrganizations
        const END_POINT = HOST_URL + "api/v1/UserData/GetAllOrganizations"

        axios.get(END_POINT, {
            headers: header
        })
            .then((res) => {
                console.log("get orgnization list called", res.data)

                setLoading(false)
                setUserOrg(res.data.Data)
            }).catch((err) => {
                console.log(err)

                // context.setIsAdmin(false)
            })

    }


    const onSaveAction = (header) => {
        // console.log(country, password, fullName, email, username)
        $('#adduseremail').val('')
        $('#addfullName').val('')
        $('#adduserId').val('')
        $('#addpass').val('')
        $('#addselectrole').val('')
        if (username) {


            const params = new URLSearchParams()
            params.append("ActionName", "Add")
            params.append("UserId", username)
            params.append("FullName", fullName)
            params.append("Password", password)
            params.append("Email", email)
            params.append("CompanyCode", org)
            params.append("CountryCode", "IN")

            const END_POINT = `${HOST_URL}api/v1/UserData/AddorEditUser`
            // context.setToggelSnackbar({ ...context.toggelSnackbar, show: true, message: "User added successfully" })


            axios.post(END_POINT, params, {
                headers: header
            })
                .then((res) => {
                    if (res.data === "2") {
                        setSeverity("error")
                        setMessage("User id already exist.")
                        setOpen(true)
                    } else {
                        setUserCheck(true)
                        setUsername("")
                        setfullName("")
                        setPassword("")
                        setEmail("")
                        setOrg("")
                        props.getUserList()
                    }
                }).catch((err) => {
                    console.log(err)
                    setSeverity("error")
                    setMessage("All field are required.")
                    setOpen(true)


                })


        }
        else {
            setSeverity("error")
            setMessage("All field are required.")
            setOpen(true)

        }

    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }


    return (
        <div className="modal fade advSearch" id="addUser" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add User</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" />
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="adduseremail" className="form-label">User Email-Id*</label>
                                    <input className="form-control" id="adduseremail" placeholder="User Email-Id" type="text" value={email} onChange={(event) => { setEmail(event.target.value) }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="addfullName" className="form-label">Full Name</label>
                                    <input className="form-control" id="addfullName" placeholder="Full Name" type="text" value={fullName} onChange={(event) => { setfullName(event.target.value) }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="adduserId" className="form-label">User Id*</label>
                                    <input className="form-control" id="adduserId" placeholder="User Id" type="text" value={username} onChange={(event) => { setUsername(event.target.value) }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="addpass" className="form-label">Password*</label>
                                    <input type="text" className="form-control" id="addpass" placeholder="Password"
                                        password={password} onChange={(event) => setPassword(event.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="addselectrole" className="form-label">Organization*</label>
                                    <select className="form-control form-select" id="addselectrole" onChange={(e) => { setOrg(e.target.value) }} value={org} >
                                        <option value="">-- Select Org --</option>
                                        {
                                            userOrg.map((item) => {
                                                let gstNumber = ""
                                                if (item.org_gstin) {
                                                    gstNumber = ` ( ${item.org_gstin}) `
                                                }

                                                return (
                                                    <option value={item.org_code}>{item.org_name} {gstNumber}</option>
                                                )
                                            })
                                        }

                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Snackbar autoHideDuration={3000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} >
                        <Alert onClose={() => setOpen(false)} severity={severity}>{message}</Alert>
                    </Snackbar>

                    <div className="modal-footer">
                        <button type="button" className="btn ms-0"
                            data-bs-dismiss={userCheck ? "modal" : ""}
                            onClick={() => onSaveAction(header)}>Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default AddUser