import React, { useContext, useState, useEffect } from 'react'
import { HOST_URL } from '../../config'
import axios from 'axios'
import { getUserData } from '../../utility/utils'
import { contextType } from 'react-infinite-scroller'
import { StoreContext } from '../../store/StoreProvider'
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import '../common/common.css';
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import $ from 'jquery'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Select from 'react-select'

const StyledMenu = withStyles()((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center"
        }}
        {...props}
    />
));

function createData(name) {
    return { name };
}

const rows = [
    createData("Cupcake"),
    createData("Donut"),
    createData("Eclair"),
    createData("Frozen yoghurt"),
    createData("Gingerbread"),
    createData("Honeycomb"),
    createData("Ice cream sandwich"),
    createData("Jelly Bean"),
    createData("KitKat"),
    createData("Lollipop"),
    createData("Marshmallow"),
    createData("Nougat"),
    createData("Oreo")
];

function HeaderOrganisations(props) {
    const [userOrg, setUserOrg] = useState([])
    const [loading, setLoading] = useState(false)
    const [header, setHeader] = useState({})
    const [data, setData] = useState({})
    const [org, setOrg] = useState("")
    const context = useContext(StoreContext)
    // const [selected, setSelected] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchValue, setSearchValue] = useState("")
    const [searchedOrganization, setSearchedOrganization] = useState([])
    const [valLength, setValLength] = useState(0);


    const [selected, setSelected] = React.useState([]);
    // const handleSelectAllClick = (event) => {
    //     if (event.target.checked) {
    //         const newSelecteds = searchedOrganization.map((n) => n.org_code);
    //         setSelected(newSelecteds);
    //         return;
    //     }
    //     setSelected([]);
    // };
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (newSelected.length < 6) {
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, name);
            } else if (newSelected.length < 6 && selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (newSelected.length < 6 && selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (newSelected.length < 6 && selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1)
                );
            }
        }

        if (newSelected.length < 6 || selectedIndex !== -1) {
            setSelected(newSelected);
        }
        if (newSelected.length > 5 && selectedIndex === -1) {
            setOpen(true)
            setSeverity("error")
            setMessage("Please select maximum 5 organsisations!")
        }

        // setSelected(newSelected);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    useEffect(() => {
        if (valLength > 2) {
            if (!$('.selectOrgbutton').hasClass('Mui-expanded')) {
                $('.selectOrgbutton').click();
            }
        }
    }, [valLength])

    useEffect(async () => {

        let userData = {}
        const userSecret = JSON.parse(sessionStorage.getItem('user_secret'))
        await getUserData("user_data").then((res) => {
            userData = res
            setData(res)
        }).catch(() => {
            // props.history.goBack()
            // return;

        })

        const header = {
            "Authorization": `Bearer ${userData.access_token}`,
            'UserName': userData.user_name,
            "UserSecret": userSecret.password,
            'DeviceType': "W",
            'MacAddress': '',
            'IPAddress': ''
        }

        setHeader(header)

        getOrganization(header)
        // getOrganization(header)
    }, [])

    const onSearch = (text) => {
        let filtered = []
        setSearchValue(text)
        const val = $('#fullName').val();
        setValLength(val.length);
        if (text.length > 2) {
            filtered = userOrg.filter(function (str) { return (str.org_name.toUpperCase()).includes(text.toUpperCase()) });
            setSearchedOrganization(filtered)
        } else {
            setSearchedOrganization([])
        }
        // var PATTERN = 'bedroom',
        // setShipmentList(filtered)
        // searchedOrganization(filtered)

    }

    const getOrganization = (header) => {
        // https://dev.sflhub.com/transysapitemp/api/v1/UserData/GetAllOrganizations

        const END_POINT = HOST_URL + "api/v1/UserData/GetAllOrganizations"

        axios.get(END_POINT, {
            headers: header
        })
            .then((res) => {

                setLoading(false)

                setUserOrg(res.data.Data)
            }).catch((err) => {
                console.log(err)
            })

    }


    const onUpdate = () => {
        if (selected) {
            let organization = ""
            selected.forEach((element, index) => {
                if (index != selected.length - 1) {
                    organization = organization + element + ","
                } else {
                    organization = organization + element
                }
            })
            context.setOrgaizatioCode(organization)
            props.onSelect(organization)
            props.onClose()
        }
    }

    const options = userOrg.map((item) => {
        return (
            {
                label: item.org_name, value: item.org_code
            }
        )
    })

    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    return (

        <div className="headerOrgContent">
            <div className="headerOrgheader">
                <h4 className="headerOrgtitle">Select Organization</h4>
                <button type="button" className="btn-close" onClick={props.onClose} />
            </div>
            <div className="headerOrgbody">
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-12">
                            {/* <label htmlFor="selectOrg" className="form-label">Select Organization</label> */}
                            <Accordion className="selectOrgParentAccordian">
                                <AccordionSummary aria-label="Expand" aria-controls="additional-actions1-content" id="additional-actions1-header" className="pointer selectOrgbutton" >
                                    <input autoComplete="off" className="form-control" id="fullName" placeholder="Type here" type="text" value={searchValue} onChange={(e) => onSearch(e.target.value)} autoComplete="off" />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={valLength > 2 ? "tableContainerParent" : "tableContainerParent hide"}>
                                        <TableContainer className="tableContainer">
                                            <Table stickyHeader>
                                                {/* <TableHead>
                                                    <TableRow>
                                                        <TableCell padding="checkbox" component="th">
                                                            <Checkbox
                                                                indeterminate={selected.length > 0 && selected.length < searchedOrganization.length}
                                                                checked={searchedOrganization.length > 0 && selected.length === searchedOrganization.length}
                                                                onChange={handleSelectAllClick}
                                                                inputProps={{ "aria-label": "select all desserts" }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableSortLabel>
                                                                {selected.length > 0 && selected.length === searchedOrganization.length
                                                                    ? "Reset all"
                                                                    : "Select all"}
                                                            </TableSortLabel>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead> */}
                                                <TableBody>
                                                    {searchedOrganization.map((row, index) => {
                                                        const isItemSelected = isSelected(row.org_code);
                                                        const labelId = `enhanced-table-checkbox-${index}`;
                                                        let gstNumber = ""
                                                        if (row.org_gstin) {
                                                            gstNumber = ` ( ${row.org_gstin}) `
                                                        }
                                                        return (
                                                            <TableRow hover onClick={(event) => handleClick(event, row.org_code)} role="checkbox" aria-checked={isItemSelected}
                                                                tabIndex={-1} key={index} selected={isItemSelected} >
                                                                <TableCell padding="checkbox">
                                                                    <Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
                                                                </TableCell>
                                                                <TableCell id={labelId} scope="row"
                                                                    padding="none" >
                                                                    {row.org_name}{gstNumber}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {/* <button 
                                                aria-label="Expand"
                                                aria-controls="additional-actions1-content" id="additional-actions1-header" 
                                                className="bg-primary pointer text-white form-control" onClick={handleClosed}>Submit</button> */}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            {/* </select> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="headerOrgfooter">
                <button type="button" className="btn ms-0" onClick={props.onClose} onClick={onUpdate}>Select</button>
            </div>
            <Snackbar autoHideDuration={3000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={open} >
                <Alert onClose={() => setOpen(false)} severity={severity}>{message}</Alert>
            </Snackbar>
        </div>

    )
}

export default HeaderOrganisations
