import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ShipmentDetails from './ShipmentDetails'
import { CircularProgress } from '@material-ui/core';
import { HOST_URL } from '../../config'
import axios from 'axios'
import { getUserData } from '../../utility/utils'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { StoreContext } from '../../store/StoreProvider';
import $ from 'jquery';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment'
// import { StoreContext } from '../../store/StoreProvider'


export default function CollapsibleTable(props) {
    // const myRef = useRef(null);
    const [shipmentData, setShipmentData] = useState({ shipments: [], loading: true, error: false })
    // const context = useContext(StoreContext)
    const [hasLoadMore, setHasLoadMore] = useState("")
    const [error, setError] = useState("")
    const [phone, setPhone] = useState("")
    const context = useContext(StoreContext)
    const [modal, setModal] = useState("")
    const [email, setEmail] = useState("")
    // const [email, setEmail] = useState("")

    useEffect(() => {
        setEmail((prevState) => {
            if (prevState === context.email) {
                return prevState
            }

            return context.email
        })
    }, [context.email])

    useEffect(() => {
        setHasLoadMore((preState) => {

            if (preState === props.hasLoadMore) {
                return preState
            }

            return props.hasLoadMore
        })

        setShipmentData((preState) => {
            if (preState === props.shipmentData) {
                return preState
            }

            return props.shipmentData
        })


        // ETD
        $('.updownarrowD').on('click', () => {
            $('.uparrowD,.updownarrowA,.updownarrow1,.updownarrow2').removeClass('hide');
            $('.updownarrowD,.downarrowA,.uparrowA,.downarrow1,.uparrow1,.downarrow2,.uparrow2').addClass('hide');
        })
        $('.uparrowD').on('click', () => {
            $('.downarrowD,.updownarrowA,.updownarrow1,.updownarrow2').removeClass('hide');
            $('.uparrowD,.downarrowA,.uparrowA,.downarrow1,.uparrow1,.downarrow2,.uparrow2').addClass('hide');
        })
        $('.downarrowD').on('click', () => {
            $('.uparrowD,.updownarrowA,.updownarrow1,.updownarrow2').removeClass('hide');
            $('.downarrowD,.downarrowA,.uparrowA,.downarrow1,.uparrow1,.downarrow2,.uparrow2').addClass('hide');
        })
        // ETA
        $('.updownarrowA').on('click', () => {
            $('.uparrowA,.updownarrowD,.updownarrow1,.updownarrow2').removeClass('hide');
            $('.updownarrowA,.downarrowD,.uparrowD,.downarrow1,.uparrow1,.downarrow2,.uparrow2').addClass('hide');
        })
        $('.uparrowA').on('click', () => {
            $('.downarrowA,.updownarrowD,.updownarrow1,.updownarrow2').removeClass('hide');
            $('.uparrowA,.downarrowD,.uparrowD,.downarrow1,.uparrow1,.downarrow2,.uparrow2').addClass('hide');
        })
        $('.downarrowA').on('click', () => {
            $('.uparrowA,.updownarrowD,.updownarrow1,.updownarrow2').removeClass('hide');
            $('.downarrowA,.downarrowD,.uparrowD,.downarrow1,.uparrow1,.downarrow2,.uparrow2').addClass('hide');
        })
        //consignor
        $('.updownarrow1').on('click', () => {
            $('.uparrow1,.updownarrowD,.updownarrowA,.updownarrow2').removeClass('hide');
            $('.updownarrow1,.downarrowD,.uparrowD,.downarrowA,.uparrowA,.downarrow2,.uparrow2').addClass('hide');
        })
        $('.uparrow1').on('click', () => {
            $('.downarrow1,.updownarrowD,.updownarrowA,.updownarrow2').removeClass('hide');
            $('.uparrow1,.downarrowD,.uparrowD,.downarrowA,.uparrowA,.downarrow2,.uparrow2').addClass('hide');
        })
        $('.downarrow1').on('click', () => {
            $('.uparrow1,.updownarrowD,.updownarrowA,.updownarrow2').removeClass('hide');
            $('.downarrow1,.downarrowD,.uparrowD,.downarrowA,.uparrowA,.downarrow2,.uparrow2').addClass('hide');
        })
        //consignee
        $('.updownarrow2').on('click', () => {
            $('.uparrow2,.updownarrowD,.updownarrowA,.updownarrow1').removeClass('hide');
            $('.updownarrow2,.downarrowD,.uparrowD,.downarrowA,.uparrowA,.downarrow1,.uparrow1').addClass('hide');
        })
        $('.uparrow2').on('click', () => {
            $('.downarrow2,.updownarrowD,.updownarrowA,.updownarrow1').removeClass('hide');
            $('.uparrow2,.downarrowD,.uparrowD,.downarrowA,.uparrowA,.downarrow1,.uparrow1').addClass('hide');
        })
        $('.downarrow2').on('click', () => {
            $('.uparrow2,.updownarrowD,.updownarrowA,.updownarrow1').removeClass('hide');
            $('.downarrow2,.downarrowD,.uparrowD,.downarrowA,.uparrowA,.downarrow1,.uparrow1').addClass('hide');
        })
    })


    const onUpdate = async () => {
        setModal('');
        let userData = {}
        const userSecret = JSON.parse(sessionStorage.getItem('user_secret'))
        await getUserData("user_data").then((res) => {
            userData = res
        }).catch(() => {

        })


        const header = {
            "Authorization": `Bearer ${userData.access_token}`,
            'UserName': userData.user_name,
            "UserSecret": userSecret.password,
            'DeviceType': "W",
            'MacAddress': '',
            'IPAddress': ''
        }

        if (email === "") {
            setModal('');
            setError("Email field required")

        } else {
            const END_POINT = `${HOST_URL}api/v1/Alert/SaveAlertData`


            const params = new URLSearchParams()
            params.append("ShipmentId", context.shipmentId)
            params.append("Email", email)
            params.append("Phone", phone)

            axios.post(END_POINT, params, {
                headers: header
            }).then((res) => {

                props.onAlertSave()
                setModal("modal");
                // $('#alertUpdate').click();
            }).catch((err) => {
                console.log(err)
                setModal('');
            })
        }
    }

    const onUnsubscribe = async () => {
        setModal('');
        let userData = {}
        const userSecret = JSON.parse(sessionStorage.getItem('user_secret'))
        await getUserData("user_data").then((res) => {
            userData = res
        }).catch(() => {

        })


        const header = {
            "Authorization": `Bearer ${userData.access_token}`,
            'UserName': userData.user_name,
            "UserSecret": userSecret.password,
            'DeviceType': "W",
            'MacAddress': '',
            'IPAddress': ''
        }

        if (email === "") {
            setModal('');
            setError("Email field required")

        } else {
            const END_POINT = `${HOST_URL}api/v1/Alert/UnsubscribeAlert`
            const params = new URLSearchParams()
            params.append("ShipmentID", context.shipmentId)
            params.append("Email", email)
            params.append("Phone", phone)

            axios.post(END_POINT, params, {
                headers: header
            }).then((res) => {

                props.onAlertSave()
                setModal("modal");
                // $('#alertUpdate').click();
            }).catch((err) => {
                console.log(err)
                setModal('');
            })
        }

    }

    return (
        <TableContainer component={Paper} className="tableCont">
            <InfiniteScroll
                pageStart={0}
                loadMore={() => props.onScrollToLoad()}
                hasMore={props.hasLoadMore}
                loader={<div className="bottomLoader" key={0}><CircularProgress size={"1rem"} /></div>}
                useWindow={false}
            >

                <Table aria-label="collapsible table"
                // ref={myRef}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell className='shpIdTh'>Shipment Id</TableCell>
                            <TableCell className='pOTh'>Purchase Order</TableCell>
                            <TableCell className='consignorTh'>Consignor
                                <span title="Sort Descending">
                                    <UnfoldMoreIcon className="updownarrow1 pointer" onClick={() => {
                                        // etdAsc("ETD")
                                        props.onSortByETD("Desc", "consignor")
                                    }}
                                    />
                                </span>
                                <span title="Ascending">
                                    <ExpandLessIcon className="downarrow1 hide pointer" onClick={() => {
                                        // etdAsc("ETD")
                                        props.onSortByETD("Desc", "consignor")
                                    }} />
                                </span>
                                <span title="Descending">
                                    <ExpandMoreIcon className="uparrow1 hide pointer" onClick={() => {
                                        // etdDesc("ETD")
                                        props.onSortByETD("Asc", "consignor")
                                    }} />
                                </span>
                            </TableCell>
                            <TableCell className='consigneeTh'>Consignee
                                <span title="Sort Descending">
                                    <UnfoldMoreIcon className="updownarrow2 pointer" onClick={() => {
                                        // etdAsc("ETD")
                                        props.onSortByETD("Desc", "consignee")
                                    }}
                                    />
                                </span>
                                <span title="Ascending">
                                    <ExpandLessIcon className="downarrow2 hide pointer" onClick={() => {
                                        // etdAsc("ETD")
                                        props.onSortByETD("Desc", "consignee")
                                    }} />
                                </span>
                                <span title="Descending">
                                    <ExpandMoreIcon className="uparrow2 hide pointer" onClick={() => {
                                        // etdDesc("ETD")
                                        props.onSortByETD("Asc", "consignee")
                                    }} />
                                </span>
                            </TableCell>
                            <TableCell className='orgTh'>Origin - Discharge</TableCell>
                            <TableCell className='etdTh'
                            // onClick={() => {
                            //     onChangeSortOrder("ETD")
                            //     props.onSortByETD()
                            // }}
                            >ETD <span title="Sort Descending">
                                    <UnfoldMoreIcon className="updownarrowD hide pointer" onClick={() => {
                                        // etdAsc("ETD")
                                        props.onSortByETD("Desc", "ETD")
                                    }}
                                    />
                                </span>
                                <span title="Ascending">
                                    <ExpandLessIcon className="downarrowD hide pointer" onClick={() => {
                                        // etdAsc("ETD")
                                        props.onSortByETD("Desc", "ETD")
                                    }} />
                                </span>
                                <span title="Descending">
                                    <ExpandMoreIcon className="uparrowD pointer" onClick={() => {
                                        // etdDesc("ETD")
                                        props.onSortByETD("Asc", "ETD")
                                    }} />
                                </span>
                            </TableCell>
                            <TableCell className='etaTh'
                            >ETA <span title="Sort Descending">
                                    <UnfoldMoreIcon className="updownarrowA pointer" onClick={() => {
                                        // etaAsc("ETA")
                                        props.onSortByETD("Desc", "ETA")
                                    }}
                                    />
                                </span>
                                <span title="Ascending">
                                    <ExpandLessIcon className="downarrowA hide pointer" onClick={() => {
                                        // etaAsc("ETA")
                                        props.onSortByETD("Desc", "ETA")
                                    }} />
                                </span>
                                <span title="Descending">
                                    <ExpandMoreIcon className="uparrowA hide pointer" onClick={() => {
                                        // etaDesc("ETA")
                                        props.onSortByETD("Asc", "ETA")
                                    }} />
                                </span>
                            </TableCell>
                            <TableCell className='statusTh'>Status</TableCell>
                            <TableCell className='notifyTh'>Notify</TableCell>
                        </TableRow>
                    </TableHead>
                    {!shipmentData.loading ? <TableBody>
                        {shipmentData.shipments.map((row, index) => (
                            <Row key={index} row={row}
                            // onClick={ScrollToIndex} 
                            />
                        ))}
                    </TableBody > : <TableBody className="loaderProgress"><TableRow><TableCell colSpan="9"><div><CircularProgress size={"2rem"} /></div></TableCell></TableRow></TableBody>}
                </Table>
            </InfiniteScroll>

            <div className="modal fade advSearch alertDialog" id="alertDialog" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Alert</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" />
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <label htmlFor="consignor" className="form-label">Send update via E-Mail</label>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <input type="text" className="form-control" id="alert" placeholder="E-mail Address" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                        <p style={{ fontSize: 12, color: 'red', marginBottom: 0 }}>{error}</p>
                                    </div>
                                </div>
                                <span className="alertNote">Note : Add Comma (,) Seprated Values In E-Mail For Multiple Receivers.</span>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {context.email ? <button type="button" data-bs-dismiss="modal" className="btn ms-0" id="alertUpdate"
                                onClick={onUnsubscribe}
                            >Unsubscribe</button> : null}

                            <button type="button" data-bs-dismiss="modal" className="btn ms-0" id="alertUpdate"
                                onClick={onUpdate}
                            >UPDATE</button>
                        </div>
                    </div>
                </div>
            </div>

        </TableContainer>
    );
}


const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

function Row(props) {
    const context = useContext(StoreContext)
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    let ETD = ""
    let ETA = ""
    if (row.ETD) {
        ETD = moment(row.ETD).format("DD/MM/YYYY")
    }

    if (row.ETA) {
        ETA = moment(row.ETA).format("DD/MM/YYYY")
    }

    return (
        <React.Fragment>
            <TableRow className={classes.root} open={false} onClick={() => {
                context.setShipmentId(row.ShipmentId)
                setOpen(!open);
                // props.onClick();
            }}>
                <TableCell title={row.ShipmentId} className='shpIdtd'>
                    {row.ShipmentId}
                </TableCell>
                <TableCell title={row.OrderRef} className='ordertd'>
                    {row.OrderRef}
                </TableCell>
                <TableCell title={row.ConsignorName} className='conortd'>{row.ConsignorName}</TableCell>
                <TableCell title={row.ConsigneeName} className='coneetd'>{row.ConsigneeName}</TableCell>
                <TableCell align="center" className='orgtd'><span>
                    {
                        row.TransportMode === "AIR" ?
                            <img className="tMode" title="AIR" src={`/dashboard/Assets/Images/Air.svg`} alt="air" /> :
                            row.TransportMode === "SEA" ?
                                <img className="tMode" title="SEA" src={`/dashboard/Assets/Images/Sea.svg`} alt="sea" /> : null
                    }
                    {row.OriginPort} < ArrowRightAltIcon /> {row.DestinationPort} </span>
                </TableCell>
                <TableCell className='etdtd'>{ETD}</TableCell>
                <TableCell className='etatd'>{ETA}</TableCell>
                <TableCell className="shpmentStatus" onClick={() => {
                    context.setShipmentId(row.ShipmentId)
                }}>
                    {
                        (row.ShipmentStatus === 3) ?
                            <div className="completedShipment">
                                <span>Completed</span>
                            </div>
                            :
                            (row.ShipmentOnTime === "1") ?
                                <img src={`/dashboard/Assets/Images/OnTime.svg`} alt="ontime" /> :

                                <img src={`/dashboard/Assets/Images/Delayed.svg`} alt="delayed" />

                    }
                </TableCell>
                <TableCell align="center" className='notifitd' onClick={() => {
                }}>
                    {
                        <img className="pointer" title="Send Alert" src={row.AlertIcon} alt="notifi" onClick={(e) => {
                            context.setEmail(row.AlertData)
                            context.setShipmentId(row.ShipmentId)
                            e.stopPropagation()
                        }} data-bs-toggle="modal" data-bs-target="#alertDialog" />
                        // :
                        // <img className="pointer" src={`${context.imgUrl}/Assets/Images/notifiRed.svg`} alt="notifired" onClick={(e) => e.stopPropagation()} data-bs-toggle="modal" data-bs-target="#alertDialog" />
                    }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <ShipmentDetails ShipmentDetails={row} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}