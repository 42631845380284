import React, { useContext, useState, useEffect } from 'react'
import { HOST_URL } from '../../config'
import axios from 'axios'
import { getUserData } from '../../utility/utils'
import { StoreContext } from '../../store/StoreProvider'
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import './common.css';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import $ from 'jquery'
import Chip from '@material-ui/core/Chip';

// import {StoreContext} from '../../store/StoreProvider'

function createData(name) {
    return { name };
}

function UpdateOrg(props) {

    const [userOrg, setUserOrg] = useState([])
    const [loading, setLoading] = useState(false)
    const [header, setHeader] = useState({})
    const [data, setData] = useState({})
    const context = useContext(StoreContext)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchValue, setSearchValue] = useState("")
    const [searchedOrganization, setSearchedOrganization] = useState([])
    const [userOrgList, setUserOrgList] = useState([])
    const [valLength, setValLength] = useState(0);
    const [selected, setSelected] = React.useState([]);

    const handleClick = (event, name, row) => {
        const selectedIndex = selected.indexOf(name);
        const index = userOrgList.map(function (e) { return e.user_id; }).indexOf(row.user_id);
        //userOrgList.indexOf(row)

        let newSelected = [];


        if (newSelected.length < 6) {
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, name);
            } else if (newSelected.length < 6 && selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));

            } else if (selected.length < 6 && selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1)
                );
            }
        }
        if (newSelected.length < 6 || selectedIndex !== -1) {
            // setUserOrgList(userOrg)
            setSelected(newSelected);
        }

        if (newSelected.length > 5 && selectedIndex === -1) {
            setOpen(true)
            setSeverity("error")
            setMessage("Please select maximum 5 organsisations!")
        }

    };


    const isSelected = (name) => {
        return (
            selected.indexOf(name) !== -1
        )
    };

    useEffect(() => {
        if (valLength > 2) {
            if (!$('.selectOrgbutton').hasClass('Mui-expanded')) {
                $('.selectOrgbutton').click();
            }
        }
    }, [valLength])

    useEffect(async () => {
        var myModal = document.getElementById('updateOrg')
        myModal.addEventListener('hide.bs.modal', function () {
            setSearchedOrganization([]);
            if ($('.selectOrgbutton').hasClass('Mui-expanded')) {
                $('.selectOrgbutton').click();
            }
        })
        myModal.addEventListener('show.bs.modal', function () {
            $('#fullName').val("");
            $('.tableContainerParent').addClass('hide')
        })
        let userData = {}
        const userSecret = JSON.parse(sessionStorage.getItem('user_secret'))
        await getUserData("user_data").then((res) => {
            userData = res
            setData(res)
        }).catch(() => {
            // props.history.goBack()
            // return;

        })

        const header = {
            "Authorization": `Bearer ${userData.access_token}`,
            'UserName': userData.user_name,
            "UserSecret": userSecret.password,
            'DeviceType': "W",
            'MacAddress': '',
            'IPAddress': ''
        }

        setHeader(header)
        getOrganization(header)
        // getUserOrganization(header)
    }, [])

    useEffect(() => {
        setSearchValue("")
        if (context.selectedUser) {
            getUserOrganization(header)

        }
    }, [props])

    const getUserOrganization = (header) => {
        // https://dev.sflhub.com/transysapitemp/api/v1/UserData/GetAllOrganizations

        setUserOrgList([])
        let organization = window.btoa(context.selectedUser.user_id)


        const END_POINT = `${HOST_URL}api/v1/UserData/GetUserOrgs/${organization}`

        axios.get(END_POINT, {
            headers: header
        })
            .then((res) => {
                let org = []
                res.data.Data.map((data) => {
                    org.push(data.org_code)
                })
                setSelected(org)

                setUserOrgList(res.data.Data)

            }).catch((err) => {
                console.log(err)
            })

    }


    const onSearch = (text) => {
        let filtered = []
        console.log("inside key press called", text)
        setSearchValue(text)
        const val = $('#fullName').val();
        setValLength(val.length);
        if (text.length > 2) {
            filtered = userOrg.filter(function (str) {
                return (str.org_name.toUpperCase()).includes(text.toUpperCase())
            });

            filtered = filtered.concat(userOrg.filter(function (str) {
                return (str.org_code.toUpperCase()).includes(text.toUpperCase())
            }))
            filtered = [...new Set(filtered)];

            setSearchedOrganization(filtered)
        } else {
            setSearchedOrganization([])
        }
    }

    const getOrganization = (header) => {
        // https://dev.sflhub.com/transysapitemp/api/v1/UserData/GetAllOrganizations

        const END_POINT = HOST_URL + "api/v1/UserData/GetAllOrganizations"

        axios.get(END_POINT, {
            headers: header
        })
            .then((res) => {
                setLoading(false)
                setUserOrg(res.data.Data)
            }).catch((err) => {
                console.log(err)
            })

    }


    const onUpdate = () => {
        if (selected) {
            let organization = ""
            selected.forEach((element, index) => {
                if (index != selected.length - 1) {
                    organization = organization + element + ","

                } else {
                    organization = organization + element

                }
            })
            const END_POINT = HOST_URL + `api/v1/UserData/AddOrganization?user_id=${context.selectedUser.user_id}&org_code=${organization}`
            axios.post(END_POINT, {}, {
                headers: header
            })
                .then((res) => {
                    props.getUserList()
                }).catch((err) => {
                    console.log(err)
                })
        }
    }
    const handleDelete = (chipToDelete) => () => {
        setSelected((chips) => chips.filter((chip) => chip !== chipToDelete))
        // setUserOrgList((chips) => chips.filter((chip) => chip.org_code !== chipToDelete.org_code));
    };

    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    return (
        <div className="modal fade advSearch" id="updateOrg" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Update Organization</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" />
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="selectOrg" className="form-label">{selected.length > 0 ? "Current user Organization" : "Select Organization"}</label>
                                    <div className="searchedOrganizationchips">
                                        {
                                            selected.map((data, index) => {
                                                return (
                                                    <Chip
                                                        label={data}
                                                        key={index}
                                                        onDelete={data.label === 'React' ? undefined : handleDelete(data)}


                                                    />
                                                )
                                            })
                                        }
                                    </div>

                                    <Accordion className="selectOrgParentAccordian">
                                        <AccordionSummary aria-label="Expand" aria-controls="additional-actions1-content" id="additional-actions1-header" className="pointer selectOrgbutton" >
                                            <input autoComplete="off" className="form-control" id="fullName" placeholder="Type here to add new organization" type="text" value={searchValue} onChange={(e) => onSearch(e.target.value)} autoComplete="off" />
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className={valLength > 2 ? "tableContainerParent" : "tableContainerParent hide"}>
                                                <TableContainer className="tableContainer">
                                                    <Table stickyHeader>
                                                        <TableBody>
                                                            {searchedOrganization.map((row, index) => {
                                                                const isItemSelected = isSelected(row.org_code);
                                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                                let gstNumber = ""
                                                                if (row.org_gstin) {
                                                                    gstNumber = ` ( ${row.org_gstin}) `
                                                                }
                                                                return (
                                                                    <TableRow hover onClick={(event) => handleClick(event, row.org_code, row)} role="checkbox" aria-checked={isItemSelected}
                                                                        tabIndex={-1} key={index} selected={isItemSelected} >
                                                                        <TableCell padding="checkbox">
                                                                            <Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
                                                                        </TableCell>
                                                                        <TableCell id={labelId} scope="row"
                                                                            padding="none" >
                                                                            {row.org_name}{gstNumber}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn ms-0" data-bs-dismiss="modal" onClick={onUpdate}>Save Changes</button>
                    </div>
                </div>
            </div>
            <Snackbar autoHideDuration={3000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={open} >
                <Alert onClose={() => setOpen(false)} severity={severity}>{message}</Alert>
            </Snackbar>
        </div>
    )
}

export default UpdateOrg
