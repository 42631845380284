import React, { useState, useEffect, useContext } from 'react'
import { HOST_URL } from '../../config'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { StoreContext } from '../../store/StoreProvider'
import { getUserData } from '../../utility/utils'
import $ from 'jquery';
import { ContactSupport } from '@material-ui/icons'


function ChangePassword() {

    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const context = useContext(StoreContext)


    const onChangeAction = async () => {
        $('#oldPass').val('')
        $('#newPass').val('')
        $('#conPass').val('')

        let userData = {}
        const userSecret = JSON.parse(sessionStorage.getItem('user_secret'))
        await getUserData("user_data").then((res) => {
            userData = res
            // console.log(res)
            // setData(res)
        }).catch(() => {
            // props.history.goBack()
            return;

        })

        const header = {
            "Authorization": `Bearer ${userData.access_token}`,
            'UserName': userData.user_name,
            "UserSecret": userSecret.password,
            'DeviceType': "W",
            'MacAddress': '',
            'IPAddress': ''
        }

        if (oldPassword != "" && newPassword != "") {
            const END_POINT = HOST_URL + `api/v1/UserData/ResetPassword`

            const params = new URLSearchParams()
            // params.append("ActionName", "Edit")
            params.append("UserName", userData.user_id)
            params.append("NewPassword", oldPassword)
            params.append("OldPassword", newPassword)

            axios.post(END_POINT, params, {
                headers: header
            })
                .then((res) => {
                    // setLoading(false)
                    // setCountries(res.data.countries)
                    // props.getUserList()
                }).catch((err) => {
                    console.log(err)
                })

        }


    }


    return (
        <div className="modal fade advSearch" id="chngPass" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Change Password</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" />
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="oldPass" className="form-label">Old Password</label>
                                    <input type="text" className="form-control" id="oldPass" placeholder="Old Password"
                                        onChange={(e) => { setOldPassword(e.target.value) }} value={oldPassword}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="newPass" className="form-label">New Password</label>
                                    <input type="text" className="form-control" id="newPass" placeholder="New Password"
                                        onChange={(e) => { setNewPassword(e.target.value) }} value={newPassword}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="conPass" className="form-label">Confirm Password</label>
                                    <input type="text" className="form-control" id="conPass" placeholder="Confirm Password" value={confirmPassword}
                                        onChange={(e) => { setConfirmPassword(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn ms-0" data-bs-dismiss="modal" onClick={onChangeAction}>Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword
