import React, { useContext } from 'react'
import { StoreContext } from '../../../store/StoreProvider';

function HomeMobile() {
    const context = useContext(StoreContext)
    return (
        <div className="mobile" style={{ display: "none" }}>
            <div className="flex header align-items-center justify-content-between p-3">
                <div className="header_left">
                    <h2>Open Orders <span>(200)</span> <img className="py-2" alt="path" src={`/dashboard/Assets/Images/Path144.png`} /></h2>
                </div>
                <div className="header_right">
                    <img className="py-2 me-3" alt="dots" src={`/dashboard/Assets/Images/Dots24px.png`} />
                    <img className="py-2" alt="group" src={`/dashboard/Assets/Images/Group1955.png`} />
                </div>
            </div>
            <div className="cardgroup p-2">
                <div className="cardbox py-2 px-3 my-2">
                    <div className="row">
                        <div className="col-6">
                            <h6 className="po">#EY34567899</h6>
                            <h6 className="con">MAERSK</h6>
                        </div>
                        <div className="col-6 text-end">
                            <img className="me-3" src="/dashboard/Assets/Images/Vector.png" />
                            <button className="btn ontime px-2 py-1">On Time</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label>Origin</label>
                            <h6 className="row2">Mumbai</h6>
                        </div>
                        <div className="col-4">
                            <label>Destination</label>
                            <h6 className="row2">Sydney</h6>
                        </div>
                        <div className="col-4">
                            <label>ETD</label>
                            <h6 className="row2">3 days</h6>
                        </div>
                    </div>
                </div>
                <div className="cardbox py-2 px-3 my-2">
                    <div className="row">
                        <div className="col-6">
                            <h6 className="po">#EY34567899</h6>
                            <h6 className="con">MAERSK</h6>
                        </div>
                        <div className="col-6 text-end">
                            <img className="me-3" src="/dashboard/Assets/Images/Group496.png" />
                            <button className="btn ontime px-2 py-1">On Time</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label>Load</label>
                            <h6 className="row2">Mumbai</h6>
                        </div>
                        <div className="col-4">
                            <label>Discharge</label>
                            <h6 className="row2">Singapore</h6>
                        </div>
                        <div className="col-4">
                            <label>ETD</label>
                            <h6 className="row2">10 Days</h6>
                        </div>
                    </div>
                </div>
                <div className="cardbox py-2 px-3 my-2">
                    <div className="row">
                        <div className="col-6">
                            <h6 className="po">#EY34567899</h6>
                            <h6 className="con">MAERSK</h6>
                        </div>
                        <div className="col-6 text-end">
                            <img className="me-3" src="/dashboard/Assets/Images/Group496.png" />
                            <button className="btn delayed px-2 py-1">Delayed</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label>Load</label>
                            <h6 className="row2">Mumbai</h6>
                        </div>
                        <div className="col-4">
                            <label>Discharge</label>
                            <h6 className="row2">Siam Reaph</h6>
                        </div>
                        <div className="col-4">
                            <label>ETD</label>
                            <h6 className="row2">30 Dec 20</h6>
                        </div>
                    </div>
                </div>
                <div className="cardbox py-2 px-3 my-2">
                    <div className="row">
                        <div className="col-6">
                            <h6 className="po">#EY34567899</h6>
                            <h6 className="con">MAERSK</h6>
                        </div>
                        <div className="col-6 text-end">
                            <img className="me-3" src="/dashboard/Assets/Images/Group496.png" />
                            <button className="btn ontime px-2 py-1">On Time</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label>Load</label>
                            <h6 className="row2">Mumbai</h6>
                        </div>
                        <div className="col-4">
                            <label>Discharge</label>
                            <h6 className="row2">Bali</h6>
                        </div>
                        <div className="col-4">
                            <label>ETD</label>
                            <h6 className="row2">31 Dec 20</h6>
                        </div>
                    </div>
                </div>
                <div className="cardbox py-2 px-3 my-2">
                    <div className="row">
                        <div className="col-6">
                            <h6 className="po">#EY34567899</h6>
                            <h6 className="con">MAERSK</h6>
                        </div>
                        <div className="col-6 text-end">
                            <img className="me-3" src="/dashboard/Assets/Images/Group496.png" />
                            <button className="btn delayed px-2 py-1">Delayed</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label>Load</label>
                            <h6 className="row2">Tokyo</h6>
                        </div>
                        <div className="col-4">
                            <label>Discharge</label>
                            <h6 className="row2">Mumbai</h6>
                        </div>
                        <div className="col-4">
                            <label>ETD</label>
                            <h6 className="row2">4 Jan 21</h6>
                        </div>
                    </div>
                </div>
                <div className="cardbox py-2 px-3 my-2">
                    <div className="row">
                        <div className="col-6">
                            <h6 className="po">#EY34567899</h6>
                            <h6 className="con">MAERSK</h6>
                        </div>
                        <div className="col-6 text-end">
                            <img className="me-3" src="/dashboard/Assets/Images/Vector.png" />
                            <button className="btn ontime px-2 py-1">On Time</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label>Origin</label>
                            <h6 className="row2">Mumbai</h6>
                        </div>
                        <div className="col-4">
                            <label>Destination</label>
                            <h6 className="row2">Sydney</h6>
                        </div>
                        <div className="col-4">
                            <label>ETD</label>
                            <h6 className="row2">3 days</h6>
                        </div>
                    </div>
                </div>
                <div className="cardbox py-2 px-3 my-2">
                    <div className="row">
                        <div className="col-6">
                            <h6 className="po">#EY34567899</h6>
                            <h6 className="con">MAERSK</h6>
                        </div>
                        <div className="col-6 text-end">
                            <img className="me-3" src="/dashboard/Assets/Images/Group496.png" />
                            <button className="btn ontime px-2 py-1">On Time</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label>Load</label>
                            <h6 className="row2">Mumbai</h6>
                        </div>
                        <div className="col-4">
                            <label>Discharge</label>
                            <h6 className="row2">Singapore</h6>
                        </div>
                        <div className="col-4">
                            <label>ETD</label>
                            <h6 className="row2">10 Days</h6>
                        </div>
                    </div>
                </div>
                <div className="cardbox py-2 px-3 my-2">
                    <div className="row">
                        <div className="col-6">
                            <h6 className="po">#EY34567899</h6>
                            <h6 className="con">MAERSK</h6>
                        </div>
                        <div className="col-6 text-end">
                            <img className="me-3" src="/dashboard/Assets/Images/Group496.png" />
                            <button className="btn delayed px-2 py-1">Delayed</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label>Load</label>
                            <h6 className="row2">Mumbai</h6>
                        </div>
                        <div className="col-4">
                            <label>Discharge</label>
                            <h6 className="row2">Siam Reaph</h6>
                        </div>
                        <div className="col-4">
                            <label>ETD</label>
                            <h6 className="row2">30 Dec 20</h6>
                        </div>
                    </div>
                </div>
                <div className="cardbox py-2 px-3 my-2">
                    <div className="row">
                        <div className="col-6">
                            <h6 className="po">#EY34567899</h6>
                            <h6 className="con">MAERSK</h6>
                        </div>
                        <div className="col-6 text-end">
                            <img className="me-3" src="/dashboard/Assets/Images/Group496.png" />
                            <button className="btn ontime px-2 py-1">On Time</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label>Load</label>
                            <h6 className="row2">Mumbai</h6>
                        </div>
                        <div className="col-4">
                            <label>Discharge</label>
                            <h6 className="row2">Bali</h6>
                        </div>
                        <div className="col-4">
                            <label>ETD</label>
                            <h6 className="row2">31 Dec 20</h6>
                        </div>
                    </div>
                </div>
                <div className="cardbox py-2 px-3 my-2">
                    <div className="row">
                        <div className="col-6">
                            <h6 className="po">#EY34567899</h6>
                            <h6 className="con">MAERSK</h6>
                        </div>
                        <div className="col-6 text-end">
                            <img className="me-3" src="/dashboard/Assets/Images/Group496.png" />
                            <button className="btn delayed px-2 py-1">Delayed</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label>Load</label>
                            <h6 className="row2">Tokyo</h6>
                        </div>
                        <div className="col-4">
                            <label>Discharge</label>
                            <h6 className="row2">Mumbai</h6>
                        </div>
                        <div className="col-4">
                            <label>ETD</label>
                            <h6 className="row2">4 Jan 21</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default HomeMobile
