import moment from 'moment';
import React from 'react'

function ExportData(props) {

    return (
        <div>
            <table id="tabletocopy" className="tabletocopy hide">
                <thead>
                    <th>Posting Branch</th>
                    <th>Type</th>
                    <th>Credit Days</th>
                    <th>Job Invoice Number</th>
                    <th>Job Invoice Date</th>
                    <th>Job Invoice Currency</th>
                    <th>Job Invoice Amount</th>
                    <th>Received Amount</th>
                    <th>Balance Total</th>
                    <th>Due Date</th>
                    <th>Overdue Days</th>
                </thead>
                <tbody>
                    {props.data.map((row, index) => {
                        let InvoiceDate = "";
                        if (row.InvoiceDate) {
                            InvoiceDate = moment(row.InvoiceDate).format("DD/MM/YYYY");
                        }
                        let DueDate = "";
                        if (row.DueDate) {
                            DueDate = moment(row.DueDate).format("DD/MM/YYYY");
                        }

                        return (
                            <tr key={index}>
                                <td>{row.Branchcode}</td>
                                <td>{row.Type}</td>
                                <td>{row.CreditDays}</td>
                                <td>{row.InvoiceNo}</td>
                                <td>{InvoiceDate}</td>
                                <td>{row.Currency_code}</td>
                                <td>{row.Amount}</td>
                                <td>{row.ReceivedAmount}</td>
                                <td>{row.Amount - row.ReceivedAmount}</td>
                                <td>{DueDate}</td>
                                <td>{row.OverDueDays}</td>
                            </tr>

                        )
                    })}
                </tbody>
            </table>

        </div>
    )
}

export default ExportData
