import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../store/StoreProvider'
import { withRouter, Link, useLocation } from 'react-router-dom'
import PersonIcon from '@material-ui/icons/Person';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { getUserData } from '../../utility/utils';
import { version_no } from '../../config';
import InfoIcon from '@material-ui/icons/Info';
import Button from '@material-ui/core/Button'; import Fab from '@material-ui/core/Fab'; import AddIcon from '@material-ui/icons/Add';


import Tooltip from '@material-ui/core/Tooltip';


function Sidebar() {

    const context = useContext(StoreContext)
    const [isAdmin, setIsAdmin] = useState(false)
    const url = useLocation()
    useEffect(() => {
        adminorUser()
    }, [])

    const adminorUser = async () => {
        await getUserData("user_data").then((res) => {
            if (res.user_roles === "ADM") {
                context.setIsAdmin(true)
                setIsAdmin(true)
            } else {
                context.setIsAdmin(false)
                setIsAdmin(false)
            }
        }).catch((err) => {
            console.log(err)
        })

    }

    return (
        <div className="sidebar">
            <div className="container-fluid h-100">
                <div className="row h-100 flex-column">
                    <div className="col logo">
                        <img className="" src={`/dashboard/Assets/Images/logo-small.png`} alt="logosmall" />
                    </div>
                    <div className="col">

                        <div className="row flex-column shipmentstatus">
                            <div>
                                <div className="col my-3 st">
                                    <Link to='/Dashboard' >
                                        <div className="row">
                                            <div className="col" onClick={() => context.setSelectedTab("dashboard")}>
                                                <div className={url.pathname === "/Dashboard" ? "activeSidebarButton" : "tabtoclick"}>
                                                    {url.pathname === "/Dashboard" ? <DashboardIcon className="me-2 text-white" /> : <DashboardIcon className="me-2" />}
                                                    Dashboard
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col my-3 st">
                                    <Link to='/Shipments' >
                                        <div className="row">
                                            <div className="col" onClick={() => context.setSelectedTab("shipment")}>
                                                <div className={url.pathname === "/Shipments" ? "activeSidebarButton" : "tabtoclick"}>
                                                    {url.pathname === "/Shipments" ?
                                                        <img className="me-2" src={`/dashboard/Assets/Images/home-solid.svg`} alt="home" /> :
                                                        <img className="me-2" src={`/dashboard/Assets/Images/Rectangle.svg`} alt="rectangle" />
                                                    }
                                                    Shipment Status
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col my-3 st">
                                    <Link to='/Statements' >
                                        <div className="row">
                                            <div className="col" onClick={() => context.setSelectedTab("statement")}>
                                                <div className={url.pathname === "/Statements" ? "activeSidebarButton" : "tabtoclick"}>
                                                    {url.pathname === "/Statements" ? <img className="me-2" src={`/dashboard/Assets/Images/dollar-sign-solid-white.svg`} alt="dollar" /> : <img className="me-2" src={`/dashboard/Assets/Images/dollar-sign-solid.svg`} alt="dollar" />}
                                                    Statement of Accounts
                                                </div>
                                            </div>
                                        </div></Link>
                                </div>

                                {/* <div className="col my-3 st gps">
                                    <div className="row">
                                        <div className="col">
                                            <img className="me-2" alt="location" src={`/dashboard/Assets/Images/CurrentLocation.svg`} /> <div><span>GPS Tracking</span><p>Coming Soon</p></div>
                                        </div>
                                    </div>
                                </div> */}
                                {isAdmin ? <div className="col my-3 st">

                                    <Link to='/Users' >
                                        <div className="row">
                                            <div className="col" onClick={() => context.setSelectedTab("user")}>
                                                <div className={url.pathname === "/Users" ? "activeSidebarButton" : "tabtoclick"}>
                                                    {url.pathname === "/Users" ? <PersonIcon className="me-2 text-white" /> : <PersonIcon className="me-2" />}
                                                    User Admin
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div> : null}
                            </div>
                        </div>
                    </div>
                    <div className="col" style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                        {/* <p className='disclaimer'><span>Disclaimer : </span></p> */}
                        <p className='version'>Disclaimer <Tooltip title="TGF does not warrant the functions and/or contents in this page will meet your requirements or that the operation of this software will be uninterrupted or error-free. In no event shall we be liable to you or any third parties for any special, punitive, incidental, indirect or consequential damages of any kind, or any damages whatsoever, including, without limitation, those resulting from loss of use, data or profits, and on any theory of liability, arising out of or in connection with the use of this software contents." aria-label="add" arrow >
                            <span><InfoIcon /></span>
                        </Tooltip></p>
                        {/* <a className='version mb-0'><Tooltip arrow placement="right" title='TGF does not warrant the functions and/or contents in this page will meet your requirements or that the operation of this software will be uninterrupted or error-free. In no event will TGF and its Employees be liable to you for any damages, including any lost profit, lost savings, lost patience or other incidental or consequential damages.'><Button></Button> </Tooltip></a> */}
                        <span>v{version_no}</span>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default withRouter(Sidebar)
