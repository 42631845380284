import React from 'react'

function Summary(props) {

    let voyage = ""
    let length = props.Summary.TransportlegData.length - 1
    props.Summary.TransportlegData.forEach((element, index) => {
        if ((index === length)) {
            if (element.Voyage) {
                voyage = voyage + element.Voyage
            }

        } else {
            if (element.Voyage) {
                voyage = voyage + element.Voyage + ", "
            }

        }

    });
    return (
        <div className="shpSumm">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 border-right-light">
                        <div className="row">
                            <div className="col-8">
                                <span>Consignor Name</span>
                                <p title={props.Summary.ConsignorName}>{props.Summary.ConsignorName ?? " "}</p>
                            </div>
                            <div className="col-4">
                                <span>Email Id</span>
                                <p title={props.Summary.ConsignorEMail}>{props.Summary.ConsignorEMail ?? " "}</p>
                            </div>
                            <div className="col-8">
                                <span>CS Name</span>
                                <p title={props.Summary.CSName}>{props.Summary.CSName === "" ? <>&nbsp;</> : props.Summary.CSName ?? " "}</p>
                            </div>
                            <div className="col-4">
                                <span>CS Email Id</span>
                                <p title={props.Summary.CSEMailId}>{props.Summary.CSEMailId ?? " "}</p>
                            </div>
                            {
                                props.Summary.Direction === "Export" ? <div className="col-12">
                                    <span>Export Broker Name</span>
                                    <p title={props.Summary.ExportBrokerName} >{props.Summary.ExportBrokerName === "" ? <>&nbsp;</> : props.Summary.ExportBrokerName ?? " "}</p>
                                </div>
                                    : <div className="col-12">
                                        <span>Import Broker Name</span>
                                        <p title={props.Summary.ImportBrokerName}>{props.Summary.ImportBrokerName === "" ? <>&nbsp;</> : props.Summary.ImportBrokerName ?? " "}</p>
                                    </div>
                            }
                            <div className="col-12">
                                <span>Carrier Name</span>
                                <p title={props.Summary.CarrierName} className="mb-0">{props.Summary.CarrierName ?? " "}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-4">
                                <span>Shipment Number</span>
                                <p title={props.Summary.ShipmentId}>{props.Summary.ShipmentId ?? " "}</p>
                            </div>
                            <div className="col-4">
                                <span>Shipper Invoice Number</span>
                                <p title={props.Summary.ShipperInvoiceNumber}> {props.Summary.ShipperInvoiceNumber ?? " "}</p>
                            </div>
                            <div className="col-4">
                                <span>Shipper Invoice Amount</span>
                                <p title={`${props.Summary.ShipperInvoiceCurrency} ${props.Summary.ShipperInoviceTotalAmount}`}>{props.Summary.ShipperInvoiceCurrency} {props.Summary.ShipperInoviceTotalAmount ?? " "}</p>
                            </div>

                            <div className="col-4">
                                <span>INCO Terms</span>
                                <p title={props.Summary.INCOTerm}>{props.Summary.INCOTerm ?? " "}</p>
                            </div>
                            <div className="col-4">
                                <span>Transport Mode</span>
                                <p title={props.Summary.TransportMode}> {props.Summary.TransportMode ?? " "}</p>
                            </div>
                            <div className="col-4">
                                <span>Type Of Shipment</span>
                                <p title={props.Summary.ContainerMode}> {props.Summary.ContainerMode ?? " "}</p>
                            </div>
                            {props.Summary.TransportMode === "SEA" ? <><div className="col-4">
                                <span>Container Count</span>
                                <p title={props.Summary.FCLContainerCount}> {props.Summary.FCLContainerCount ?? " "}</p>
                            </div>
                                <div className="col-8">
                                    <span>Voyage</span>
                                    <p>
                                        {voyage}
                                    </p>
                                </div></> : null
                            }
                            {/* <div className="col-4">
                                <span>Container Count</span>
                                <p title={props.Summary.FCLContainerCount}> {props.Summary.FCLContainerCount ?? " "}</p>
                            </div>
                            <div className="col-8">
                                <span>Voyage</span>
                                <p title={props.Summary.ShipmentType}> {props.Summary.ShipmentType ?? " "}</p>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <span>Goods Description</span>
                                <p title={props.Summary.GoodsDescription} className="mb-0">{props.Summary.GoodsDescription ?? " "}</p>
                            </div>
                            <div className="col-4">
                                <span>Inner Package</span>
                                <p title={`${props.Summary.InnerPackage} ${props.Summary.InnerPackageUQ}`} className="mb-0"> {props.Summary.InnerPackage ?? " "} {props.Summary.InnerPackageUQ}</p>
                            </div>
                            <div className="col-4">
                                <span>Outer Package</span>
                                <p title={`${props.Summary.OuterPackage} ${props.Summary.OuterPackageUQ}`} className="mb-0"> {props.Summary.OuterPackage ?? " "} {props.Summary.OuterPackageUQ ?? " "}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Summary
