import React, { useEffect, useState, useContext } from 'react';
import DetailsSummary from './DetailsSummary'
import axios from 'axios';
import { getUserData } from '../../utility/utils';
import { HOST_URL } from '../../config'
import { CircularProgress } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';
import $ from 'jquery';
import { Data } from './Data'
import { StoreContext } from '../../store/StoreProvider';

const DocCard = (props) => {
    const context = useContext(StoreContext)
    let SaveDate = "";
    useEffect(() => {

    })

    if (props.data.SaveDate) {
        SaveDate = `${moment(props.data.SaveDate).format("DD/MM/YYYY hh:mm:ss a")}`
    }

    return (
        <div className="col-sm-6 col-xs-12 docContainer" >
            <div className="container-fluid px-2 py-1 pointer" onClick={() => props.onDocDownload(props.index)}>
                <div className="row">
                    <div className="col-2 pe-0">
                        <img className="pointer" src={`/dashboard/Assets/Images/Document.svg`} />
                    </div>
                    <div className="col-10 ps-0">
                        <p title={props.data.SourceDesc}>{props.data.SourceDesc}</p>
                        <span>Date: {SaveDate}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ShipmentDetails(props) {

    const [documentData, setDocumentData] = useState({ document: [], loading: false, error: false })
    const [loading, setLoading] = useState([])
    const [body, setBody] = useState("")
    const context = useContext(StoreContext)

    useEffect(async () => {
        let userData = {}
        const userSecret = JSON.parse(sessionStorage.getItem('user_secret'))
        await getUserData("user_data").then((res) => {
            userData = res
        }).catch(() => {

        })

        const header = {
            "Authorization": `Bearer ${userData.access_token}`,
            'UserName': userData.user_name,
            "UserSecret": userSecret.password,
            'DeviceType': "W",
            'MacAddress': '',
            'IPAddress': ''
        }

        getDocuments(header, userData)
        // onDocDownload()
    }, [])




    const onDocDownload = async (index) => {
        let data = documentData.document
        data[index].loading = true
        setDocumentData({ ...documentData, document: data })
        context.setLoading(true)
        let userData = {}
        const userSecret = JSON.parse(sessionStorage.getItem('user_secret'))
        await getUserData("user_data").then((res) => {
            userData = res
        }).catch(() => {
            return;
        })

        const header = {
            "Authorization": `Bearer ${userData.access_token}`,
            'UserName': userData.user_name,
            "UserSecret": userSecret.password,
            'DeviceType': "W",
            'MacAddress': '',
            'IPAddress': ''
        }

        const END_POINT = `${HOST_URL}api/v1/DocumentData/${userData.user_company_code}/ShipmentDocument?shipmentType=ForwardingShipment`

        const searchParams = new URLSearchParams()
        searchParams.append("ShipmentId", documentData.document[index].shipmentid)
        searchParams.append("DocumentType", documentData.document[index].TypeName)
        searchParams.append("SaveDate", documentData.document[index].SaveDate)

        searchParams.append("FullName", `${documentData.document[index].FullName}.${documentData.document[index].TypeCode}`)

        axios.post(END_POINT, searchParams, {
            headers: header
        }).then((res) => {
            context.setLoading(false)
            const pdf = res.data[0].ImageData
            const linkSource = `data:application/pdf;base64,${pdf}`;
            const downloadLink = document.createElement("a");
            const fileName = res.data[0].FullName;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        }).catch((err) => {
            context.setLoading(false);
            console.log(err)
        })
    }




    const getDocuments = (header, userData) => {
        setDocumentData({ ...documentData, document: [], loading: true, error: false })
        const END_POINT = `${HOST_URL}api/v1/DocumentData/${userData.user_company_code}/ShipmentDocument/ForwardingShipment/${props.ShipmentDetails.ShipmentId}`

        axios.get(END_POINT, {
            headers: header
        }).then((res) => {
            res.data.forEach((element, index) => {
                res.data[index].loading = false
            })
            setDocumentData({ ...documentData, document: res.data, loading: false, error: false })

        }).catch((err) => {
            console.log(err)
            setDocumentData({ ...documentData, document: [], loading: false, error: true })
        })
    }
    const sendIssue = (txtId) => {
        if ($(`#message-${txtId}`).val() === "") {
            setOpen(true)
            setSeverity("error")
            setMessage("Please enter message!")
        } else {
            window.location.href = `mailto:${props.ShipmentDetails.CSEMailId}?subject=Trans Portal(Client) : ${props.ShipmentDetails.ShipmentId}&body=${body}`
        }
    }

    $(`#message-${props.ShipmentDetails.ShipmentId}`).keypress(function (e) {
        var key = e.which;
        if (key == 13) {
            $('button.reportSend').click();
            return false;
        }
    });

    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    return (
        <div className="shpmntdetails">
            <div className="container-fluid">

                <div className="row">
                    <div className="col-12 px-0">
                        <DetailsSummary detail={props.ShipmentDetails} />
                    </div>
                </div>
                {
                    (props.ShipmentDetails.TransportMode === "SEA" && context.value === 5) || (props.ShipmentDetails.TransportMode !== "SEA" && context.value === 4) || (props.ShipmentDetails.TransportMode === "SEA" && context.value === 6) || (props.ShipmentDetails.TransportMode !== "SEA" && context.value === 5) ? null : <div className="row">
                        <div className="col-md-6 col-12 ps-0">
                            <div className="docDetails">
                                <div className="container-fluid px-0">
                                    <div className="tabledivheading mb-2">
                                        <button className=" selected">Documents</button>
                                    </div>
                                    <div className="row docrow">
                                        {!documentData.loading ?
                                            documentData.document.map((item, index) => {
                                                return (
                                                    <DocCard
                                                        data={item}
                                                        index={index}
                                                        Data={Data}
                                                        onDocDownload={onDocDownload}
                                                    />
                                                )
                                            }) : <div className="docLoader"><CircularProgress size={"1.5rem"} /></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 pe-0">
                            <div className="details">
                                <div className="tabledivheading mb-2">
                                    <button className="selected report">Report an issue</button>
                                </div>
                                <div className="container-fluid">
                                    <div className="form-group">
                                        <input type="text" className="form-control mb-2" id={`subject-${props.ShipmentDetails.ShipmentId}`} placeholder="Type Subject here" value={`Trans Portal(Client) : ${props.ShipmentDetails.ShipmentId}`} disabled />
                                        <input type="textBOE Number" className="form-control mb-2" id={`message-${props.ShipmentDetails.ShipmentId}`} placeholder="Type Message here." onChange={(e) => { setBody(e.target.value) }} />
                                    </div>
                                    <div className="tabledivheading">
                                        <button className="selected reportSend" onClick={() => sendIssue(props.ShipmentDetails.ShipmentId ?? "")}>Send</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                }
            </div>
            <Snackbar autoHideDuration={3000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={open} >
                <Alert onClose={() => setOpen(false)} severity={severity}>{message}</Alert>
            </Snackbar>
        </div>
    )
}

export default ShipmentDetails
