import React, { useState, useEffect } from 'react';
import Select from "react-dropdown-select";
import styled from '@emotion/styled';

const MultiSelect = (props) => {

    const [selectValues, setSelectValues] = useState([])

    useEffect(() => {
        setSelectValues(props.selected ?? [])
        //    console.log("MultiSelect", props.selected)

    }, [props])


    const setValues = selectValue => {
        // console.log({ selectValue })
        setSelectValues(selectValue)
    };

    const isSelected = (name) => {
        // console.log({ selectValues }, name)
        return (
            selectValues.findIndex((item) => item.name === name.name) !== -1
        )
    };


    const contentRenderer = ({ props, state }) => {
        // console.log("contentRenderer", state, props)
        // console.log(props.placeholder)
        const length = selectValues.length === 0 ? <>Select</> : selectValues.length === props.options.length ? "All Selected" : selectValues.length + " Selected"
        return (
            <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                {length}
                {/* of {props.options.length} */}
            </div>
        );
    };

    const noDataRenderer = () => {
        return (
            <p style={{ textAlign: "center" }}>
                <strong>Ooops!</strong> No data found
            </p>
        );
    };

    const dropdownRenderer = ({ props, state, methods }) => {
        const regexp = new RegExp(state.search, "i");

        return (
            <div>
                <SearchAndToggle color={state.color}>
                    <Buttons>
                        {/* <div>Search</div> */}

                        <Button className="clear" onClick={methods.clearAll}>
                            Unselect All
                        </Button>

                        <Button onClick={methods.selectAll}>Select all</Button>

                    </Buttons>
                    <input
                        type="text"
                        value={state.search}
                        onChange={methods.setSearch}
                        placeholder="Search"

                    />
                </SearchAndToggle>
                <Items>
                    {props.options?.filter((item) =>
                        regexp.test(item[state.searchBy] || item[props.labelField])
                    ).map(option => {

                        const isItemSelected = isSelected(option);
                        return (
                            <Item
                                disabled={option.disabled}
                                key={option[props.valueField]}
                                onClick={
                                    option.disabled ? null : () => methods.addItem(option)
                                }
                            >
                                <input
                                    type="checkbox"
                                    onChange={() => methods.addItem(option)}
                                    checked={state.values.indexOf(option) !== -1 || isItemSelected}
                                />
                                <ItemLabel>{option[props.labelField]}</ItemLabel>
                            </Item>
                        );
                    })}
                </Items>
            </div>
        );
    };

    return (
        <StyledSelect
            className="mutliselectOption"
            // placeholder={`${props.data}`}
            placeholder='Select'
            searchBy={"name"}
            searchable={true}
            keepOpen={false}
            dropdownHandle={true}
            direction={"name"}
            multi={true}
            labelField={"name"}
            valueField={"name"}
            options={props.options}
            dropdownGap={5}
            values={selectValues}
            loading={props.loading ? true : false}
            // values
            // clearable={true}
            keepSelectedInList={true}
            onChange={values => {
                // console.log({ values }, {selectValues})
                setValues(values)
                props.onChange(values)
            }}
            noDataLabel="No matches found"
            closeOnSelect={false}
            noDataRenderer={
                false
                    ? () => noDataRenderer()
                    : undefined
            }
            // dropdownPosition={dropdownPosition}
            contentRenderer={
                // selectValues.length >= 1?
                (innerProps, innerState) =>
                    contentRenderer(innerProps, innerState)
                // : undefined
            }
            dropdownRenderer={
                // render?
                (innerProps, innerState, innerMethods) =>
                    dropdownRenderer(
                        innerProps,
                        innerState,
                        innerMethods
                    )
                // : undefined
            }

            searchFn={(event) => {
                // console.log(props.searchBy)
                // console.log(event)
            }}
        />
    );
}
// }

const StyledSelect = styled(Select)`
  ${({ dropdownRenderer }) =>
        dropdownRenderer &&
        `
		.react-dropdown-select-dropdown {
			overflow: initial;
		}
	`}
`;

const SearchAndToggle = styled.div`
  display: flex;
  flex-direction: column;

  input {
    margin: 10px 10px 0;
    line-height: 30px;
    padding: 0 20px;
    border: 1px solid #ccc;
    border-radius: 3px;
    :focus {
      outline: none;
    }
  }
`;

const Items = styled.div`
  overflow: auto;
  min-height: 10px;
  max-height: 200px;
`;

const Item = styled.div`
  display: flex;
  margin: 10px;
  align-items: baseline;
  cursor: pointer;
  border-bottom: 1px dotted transparent;

  :hover {
    border-bottom: 1px dotted #ccc;
  }

  ${({ disabled }) =>
        disabled
            ? `
  	opacity: 0.5;
  	pointer-events: none;
  	cursor: not-allowed;
  `
            : ""}
`;

const ItemLabel = styled.div`
  margin: 5px 10px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  & div {
    margin: 10px 0 0 10px;
    font-weight: 600;
  }
`;

const Button = styled.button`
  background: none;
  border: 1px solid #555;
  color: #555;
  border-radius: 3px;
  margin: 10px 10px 0;
  padding: 3px 5px;
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;

  &.clear {
    color: tomato;
    border: 1px solid tomato;
  }

  :hover {
    border: 1px solid deepskyblue;
    color: deepskyblue;
  }
`;

export default MultiSelect