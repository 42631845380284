import React, { useEffect, useState, useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Milestone from './Milestone'
import Routing from './Routing'
import Summary from './Summary'
import Container from './Container';
import { StoreContext } from '../../store/StoreProvider';
import MapComponent from './MapComponent'
import moment from 'moment'
import { getUserData } from '../../utility/utils';
import { HOST_URL } from '../../config';
import axios from 'axios';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const context = useContext(StoreContext)

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                context.sidebarclosed ? <div className="detailsummpanel detailsummpanelexpand">{children}</div> : <div className="detailsummpanel">{children}</div>
            )}
        </div>
    );
}




const ShpDetails = (props) => {


    let estDelevery = ""
    let estPickupDate = ""
    let actDeleveryDate = ""
    let actPickupDate = ""
    let doDate = ""
    let MasterDate = ""
    let HouseDate = ""
    let iGMDate = ""
    let bOEDate = ""
    let shpBillDate = ""
    let egmDate = ""

    // "EstimatedPickupDate": null,
    // "ActualPickupDate": "2021-04-18T14:49:00",
    // "EstimatedDeliveryDate": "2021-04-20T14:49:00",
    // "ActualDeliveryDate": "2021-04-20T14:49:00",

    if (props.sipmentDetails.EstimatedPickupDate) {
        estPickupDate = moment(props.sipmentDetails.EstimatedPickupDate).format("DD/MM/YYYY")
    }
    if (props.sipmentDetails.EstimatedDeliveryDate) {
        estDelevery = moment(props.sipmentDetails.EstimatedDeliveryDate).format("DD/MM/YYYY")
    }
    if (props.sipmentDetails.ActualPickupDate) {
        actPickupDate = moment(props.sipmentDetails.ActualPickupDate).format("DD/MM/YYYY")
    }
    if (props.sipmentDetails.ActualDeliveryDate) {
        actDeleveryDate = moment(props.sipmentDetails.ActualDeliveryDate).format("DD/MM/YYYY")
    }

    if (props.sipmentDetails.DOSharedDate) {
        doDate = moment(props.sipmentDetails.DOSharedDate).format("DD/MM/YYYY")
    }
    if (props.sipmentDetails.MasterDate) {
        MasterDate = moment(props.sipmentDetails.MasterDate).format("DD/MM/YYYY")
    }

    if (props.sipmentDetails.HouseDate) {
        HouseDate = moment(props.sipmentDetails.HouseDate).format("DD/MM/YYYY")
    }
    if (props.sipmentDetails.BOEDateincaseforImportShipments) {
        bOEDate = moment(props.sipmentDetails.BOEDateincaseforImportShipments).format("DD/MM/YYYY")
    }
    if (props.sipmentDetails.IGMDateincaseforImportShipments) {
        iGMDate = moment(props.sipmentDetails.IGMDateincaseforImportShipments).format("DD/MM/YYYY")
    }
    if (props.sipmentDetails.ShippingBillDate) {
        shpBillDate = moment(props.sipmentDetails.ShippingBillDate).format("DD/MM/YYYY")
    }
    if (props.sipmentDetails.EGMDateincaseforExportShipments) {
        egmDate = moment(props.sipmentDetails.EGMDateincaseforExportShipments).format("DD/MM/YYYY")
    }

    return (
        <div className="container-fluid details">
            <div className="row">
                <div className="col-lg-4">
                    <div className="row">
                        <div className="checky col-lg-6 col-md-6 col-sm-6">
                            <span>Est. Pickup</span>
                            <p title={estPickupDate}>{estPickupDate === "" ? <>&nbsp;</> : estPickupDate ?? " "}</p>
                        </div>
                        <div className="checky col-lg-6 col-md-6 col-sm-6">
                            <span>Est. Delivery</span>
                            <p title={estDelevery}>{estDelevery === "" ? <>&nbsp;</> : estDelevery}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="checky col-lg-6 col-md-6 col-sm-6">
                            <span>Act. Pickup</span>
                            <p title={actPickupDate}>{actPickupDate === "" ? <>&nbsp;</> : actPickupDate ?? " "}</p>
                        </div>
                        <div className="checky col-lg-6 col-md-6 col-sm-6">
                            <span>Act. Delivery</span>
                            <p title={actDeleveryDate}>{actDeleveryDate === "" ? <>&nbsp;</> : actDeleveryDate}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="checky col-lg-6 col-md-6 col-sm-6">
                            <span>Weight</span>
                            <p title={`${props.sipmentDetails.TotalWeight} ${props.sipmentDetails.TotalWeightUQ}`} className="mb-0">{props.sipmentDetails.TotalWeight ?? " "} {props.sipmentDetails.TotalWeightUQ}</p>
                        </div>
                        <div className="checky col-lg-6 col-md-6 col-sm-6">
                            <span>Volume</span>
                            <p title={`${props.sipmentDetails.TotalVolume} ${props.sipmentDetails.TotalVolumeUQ}`} className="mb-0">{props.sipmentDetails.TotalVolume ?? " "} {props.sipmentDetails.TotalVolumeUQ}</p>
                        </div>
                    </div>

                </div>
                <div className="col-lg-4 border-left-light">
                    <div className="row">
                        <div className="col-6">
                            <span>Master No.</span>
                            <p title={props.sipmentDetails.MasterBillNumber}>{props.sipmentDetails.MasterBillNumber ?? " "}</p>
                        </div>
                        <div className="col-6">
                            <span>Master Date</span>
                            <p title={MasterDate}>{MasterDate === "" ? <>&nbsp;</> : MasterDate}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="checky col-lg-6 col-md-6 col-sm-6">
                            <span>Order Ref #</span>
                            <p title={props.sipmentDetails.OrderRef}>{props.sipmentDetails.OrderRef ?? " "}</p>
                        </div>
                        <div className="checky col-lg-6 col-md-6 col-sm-6">
                            <span>DO Shared Date</span>
                            <p title={doDate}> {doDate === "" ? <>&nbsp;</> : doDate ?? " "}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <span>House No.</span>
                            <p title={props.sipmentDetails.HouseBillNumber}>{props.sipmentDetails.HouseBillNumber ?? " "}</p>
                        </div>

                        <div className="col-6">
                            <span>House Date</span>
                            <p title={HouseDate}>{HouseDate === "" ? <>&nbsp;</> : HouseDate}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 border-left-light">

                    <div className="row">
                        {
                            props.sipmentDetails.Direction === "Export" ? <div className="col-6">
                                <span>Shipping Bill Number</span>
                                <p title={props.sipmentDetails.ShippingBillNumber}>{props.sipmentDetails.ShippingBillNumber === "" ? <>&nbsp;</> : props.sipmentDetails.ShippingBillNumber ?? " "}</p>
                            </div>
                                : <div className="col-6">
                                    <span>BOE Number</span>
                                    <p title={props.sipmentDetails.BOENumberincaseforImportShipments}>{props.sipmentDetails.BOENumberincaseforImportShipments ?? " "}</p>
                                </div>
                        }
                        {
                            props.sipmentDetails.Direction === "Export" ? <div className="col-6">
                                <span>Shipping Bill Date</span>
                                <p title={shpBillDate}>{shpBillDate === "" ? <>&nbsp;</> : shpBillDate ?? " "}</p>
                            </div>
                                : <div className="col-6">
                                    <span>BOE Date</span>
                                    <p title={bOEDate}>{bOEDate === "" ? <>&nbsp;</> : bOEDate ?? " "}</p>
                                </div>
                        }
                        {
                            props.sipmentDetails.Direction === "Export" ? <div className="col-6">
                                <span>EGM Number</span>
                                <p title={props.sipmentDetails.EGMNumberincaseforExportShipments}>{props.sipmentDetails.EGMNumberincaseforExportShipments ?? " "}</p>
                            </div>
                                : <div className="col-6">
                                    <span>IGM Number</span>
                                    <p title={props.sipmentDetails.IGMNumberincaseforImportShipments}>{props.sipmentDetails.IGMNumberincaseforImportShipments ?? " "}</p>
                                </div>
                        }
                        {
                            props.sipmentDetails.Direction === "Export" ? <div className="col-6">
                                <span>EGM Date</span>
                                <p title={egmDate}>{egmDate === "" ? <>&nbsp;</> : egmDate ?? " "}</p>
                            </div>
                                : <div className="col-6">
                                    <span>IGM Date</span>
                                    <p title={iGMDate}>{iGMDate === "" ? <>&nbsp;</> : iGMDate ?? " "}</p>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div >

    )
}


function DetailsSummary(props) {


    const [mapData, setMapData] = useState([])
    const [vesseldetails, setVesseldetails] = useState({})
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState("")
    const [value, setValue] = useState(0);
    const context = useContext(StoreContext)
    const [portCalls, setPortCalls] = useState([])
    const [legends, setLegends] = useState([])




    useEffect(() => {
        if (props.detail.TransportMode === "SEA") {
            onMapDetail()

        }
        else {
            setLoading(false)
        }
    }, [])

    const onMapDetail = async () => {
        setLoading(true)
        let userData = {}
        const userSecret = JSON.parse(sessionStorage.getItem('user_secret'))
        await getUserData("user_data").then((res) => {
            userData = res
        }).catch(() => {

        })

        const header = {
            "Authorization": `Bearer ${userData.access_token}`,
            'UserName': userData.user_name,
            "UserSecret": userSecret.password,
            'DeviceType': "W",
            'MacAddress': '',
            'IPAddress': ''
        }

        const END_POINT = `${HOST_URL}api/v1/Location/ShipmentGeoCoordinates/${context.shipmentId}`

        axios.get(END_POINT, {
            headers: header
        }).then((res) => {
            if (res.data?.locations) {
                setMapData(res.data?.locations)
                setLegends(res?.data?.ledgends ?? [])

                // getCoordinates(res.data?.locations??[])
            }

            setPortCalls(res.data?.portcalls ?? [])
            setVesseldetails(res.data?.vesseldetails ?? {})
            setStatus(res.data?.status)

            setLoading(false)

        }).catch((err) => {
            console.log({ err })
            setLoading(false)
            setMapData([])
        })

    }



    const theme = useTheme();
    const handleChange = (event, newValue) => {
        setValue(newValue);
        context.setValue(newValue)
    };

    const handleChangeIndex = (index) => {
        setValue(index);
        context.setValue(index)
    };


    return (
        <div className="overview">
            <AppBar position="static" color="default" className="oviewHeader mb-3 p-4 pb-0">
                {props.detail.TransportMode === "SEA" ? <Tabs value={value} onChange={handleChange} >
                    <Tab label="Details" />
                    <Tab label="Milestone" />
                    <Tab label="Routing" />
                    <Tab label="Shipment Summary" />
                    <Tab label="Container" />
                    <Tab label="Vessel Level Tracking" />
                    <Tab label="Shipment Level Tracking" />
                </Tabs> :
                    <Tabs value={value} onChange={handleChange} >
                        <Tab label="Details" />
                        <Tab label="Milestone" />
                        <Tab label="Routing" />
                        <Tab label="Shipment Summary" />
                        {/* <Tab label="Container" /> */}
                        <Tab label="Vessel Level Tracking" />
                        <Tab label="Shipment Level Tracking" />
                    </Tabs>}
            </AppBar>
            {props.detail.TransportMode === "SEA" ? <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
                className="swipeablebottomoverview pb-4"
            >
                <TabPanel value={value} index={0} dir={theme.direction} className="routing">
                    <ShpDetails sipmentDetails={props.detail} />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction} className="routing">
                    <Milestone milestoneData={props.detail.MilstoneData} progDetails={props.detail} />
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction} className="document">
                    <Routing routingData={props.detail.TransportlegData} />
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction} className="cgdetail">
                    <Summary Summary={props.detail} />
                </TabPanel>
                <TabPanel value={value} index={4} dir={theme.direction} className="cgdetail">
                    <Container Summary={props.detail} />
                </TabPanel>
                {/* <TabPanel value={context.value} index={5} dir={theme.direction} className="cgdetail">
                    <MapComponent mapData={mapData} status = {} vesseldetails = {vesseldetails}/> */}
                <TabPanel value={value} index={5} dir={theme.direction} className="cgdetail">
                    <MapComponent mapData={mapData} status={props.detail.ShipmentStatus} loading={loading} legends={legends} vesseldetails={vesseldetails} portCalls={portCalls} />
                </TabPanel>
                <TabPanel value={value} index={6} dir={theme.direction} className="cgdetail">
                    <div className='container'>
                        <p className="map-error-message">This is customized and highly specific requirement, kindly reach out to <a href='mailto:vinod@tgfworld.com'>vinod@tgfworld.com</a> for more details about this service</p>
                    </div>
                </TabPanel>
            </SwipeableViews> :
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                    className="swipeablebottomoverview pb-4"
                >
                    <TabPanel value={value} index={0} dir={theme.direction} className="routing">
                        <ShpDetails sipmentDetails={props.detail} />
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction} className="routing">
                        <Milestone milestoneData={props.detail.MilstoneData} progDetails={props.detail} />
                    </TabPanel>
                    <TabPanel value={value} index={2} dir={theme.direction} className="document">
                        <Routing routingData={props.detail.TransportlegData} />
                    </TabPanel>
                    <TabPanel value={value} index={3} dir={theme.direction} className="cgdetail">
                        <Summary Summary={props.detail} />
                    </TabPanel>
                    <TabPanel value={value} index={4} dir={theme.direction} className="cgdetail">
                        <div className='container'>
                            <p className="map-error-message">GPS position tracking is only availble for SEA shipments</p>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={5} dir={theme.direction} className="cgdetail">
                        <div className='container'>
                            <p className="map-error-message">This is customized and highly specific requirement, kindly reach out to <a href='mailto:vinod@tgfworld.com'> vinod@tgfworld.com  </a> for more details about this service</p>
                        </div>
                    </TabPanel>
                </SwipeableViews>
            }
        </div>

    )
}

export default DetailsSummary
