import React from 'react'

function Error() {
    return (
        <div className='noPageFound'>
            <img src="/dashboard/Assets/Images/logo-small.png" alt="transysLogo" />
            <p>No page found!</p>
        </div>
    )
}

export default Error
