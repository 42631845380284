import React, { useEffect, useState, useContext } from 'react';
import { Map, Marker, GoogleApiWrapper, Polyline } from 'google-maps-react';
import axios from 'axios'
import { StoreContext } from '../../store/StoreProvider';
import { getUserData } from '../../utility/utils';
import { HOST_URL } from '../../config';
import CircularProgress from '@material-ui/core/CircularProgress';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './VerticalTimeline.css';
import moment from 'moment';


function MapComponent(props) {
    const { mapData, loading, status, vesseldetails, portCalls, legends } = props
    const [polygon, setPolygon] = useState([])
    const [center, setCenter] = useState({})
    const [bounds, setBounds] = useState()
    const [current, setCurrent] = useState("")
    const [cIndex, setCIndex] = useState(0)
    const [nIndex, setNIndex] = useState(0)
    const [detail, setDetail] = useState([])
    const context = useContext(StoreContext)


    useEffect(() => {
        if (mapData?.length > 0) {
            getCoordinates(mapData)
        }
    }, [props])

    const getCoordinates = (data) => {
        let polygon = []
        let center = {}
        // let points = 
        if (data) {
            let bound = new props.google.maps.LatLngBounds()
            let next = false

            data.forEach((element, index) => {
                if (element.locType === "CurrentLocation") {
                    setCurrent(element.locName)
                    setCIndex(index)

                    bound.extend({ lat: element.lat, lng: element.lng });


                } else if (element.locType === "NextDestination") {
                    next = true;
                    setCurrent(element.locName)
                    setNIndex(index)

                    bound.extend({ lat: element.lat, lng: element.lng });

                }

                polygon.push({ lat: element.lat, lng: element.lng })
            });

            const length = data.length;

            if (length !== 2 && !next) {
                bound.extend(polygon[length - 1]);

            }

            if (length === 2) {
                for (var i = 0; i < length; i++) {
                    bound.extend(polygon[i]);
                }
            }

            setCenter(center)
            setPolygon(polygon)
            setBounds(bound)
        }
    }

    const pathOptionsRedSolid = {
        strokeColor: '#e6292d',
        strokeOpacity: 1,
        icons: [
            {
                icon: {
                    path: props.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    strokeOpacity: 1,
                    fillColor: '#e6292d',
                    fillOpacity: 1.0,
                    scale: 3,
                },
                repeat: "200px",
            },
        ],
    };

    const pathOptionsGreenDashed = {
        strokeColor: '#82ca27',
        strokeOpacity: 1,
        icons: [
            {
                icon: {
                    path: props.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    strokeOpacity: 1,
                    fillColor: '#82ca27',
                    fillOpacity: 1.0,
                    scale: 3,
                },
                repeat: "200px",
            },
        ],
    };

    const pathOptionsYellowDashed = {
        strokeColor: '#0071bd',
        strokeOpacity: 1,
        icons: [
            {
                icon: {
                    path: props.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    strokeOpacity: 1,
                    fillColor: '#0071bd',
                    fillOpacity: 1.0,
                    scale: 3,
                },
                repeat: "200px",
            },
        ],
    };

    return (
        <div className="containerPanel">
            <div className="container-fluid">
                <div className="row mapComponentRow" style={{ height: '20rem' }}>
                    {
                        loading ? <div className='col-12'>
                            <div className="shipmentDetailsComp loading">
                                <CircularProgress size="1.5rem" />
                            </div>
                        </div> : <>
                            <div className='col-8'>
                                <div className="shipmentDetailsComp shipmentDetailsMapComp">
                                    <Map
                                        google={props.google}
                                        center={center}
                                        bounds={bounds}
                                    >
                                        {
                                            mapData.map((element) => {
                                                const title = element.locType === "CurrentLocation" ? `Current Location: ${element.locName} \nVessel Name: ${vesseldetails.VesselName}\nSpeed: ${vesseldetails.Speed} knot\nNext Destination: ${vesseldetails.NextDestination}` : `${element.locType}: ${element.locName}`

                                                return (
                                                    <Marker
                                                        icon={{ url: element.iconPath, scaledSize: new window.google.maps.Size(20, 25) }}
                                                        title={title}
                                                        position={{ lat: element.lat, lng: element.lng }}
                                                    />
                                                )
                                            })
                                        }
                                        {
                                            mapData.map((ele, index) => {
                                                if (index === mapData.length - 1) {
                                                    return null
                                                }
                                                const polyline = [
                                                    { lat: ele.lat, lng: ele.lng },
                                                    { lat: mapData[index + 1].lat, lng: mapData[index + 1].lng }
                                                ]
                                                let pathOptions = {}
                                                if (ele.locType === "Origin" || index < cIndex) {
                                                    pathOptions = pathOptionsRedSolid
                                                } else if (ele.locType === "CurrentLocation") {
                                                    pathOptions = pathOptionsGreenDashed
                                                } else if (ele.locType === "NextDestination") {
                                                    pathOptions = pathOptionsYellowDashed
                                                } else {
                                                    pathOptions = pathOptionsYellowDashed
                                                }
                                                return (
                                                    <Polyline
                                                        path={polyline}
                                                        options={pathOptions}
                                                    />
                                                )
                                            })
                                        }
                                    </Map>
                                </div>
                                {/* <div className='container-fluid mapIconDetails'>
                                    <div className='row'>
                                        {
                                            legends.map(({ iconPath, icontext }, index) => {
                                                return (

                                                    <div className='col-3 legendDiv' key={index}>
                                                        <img src={iconPath} alt={icontext} />
                                                        <span>{icontext}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div> */}
                            </div>
                            <div className='col-4' style={{ height: '100%' }}>
                                {status !== 3 ?
                                    <div className='shipmentDataDetailsCompParent'>
                                        <VerticalTimeline layout='1-column-left'>
                                            {
                                                [1, 2, 3].map((item, index) => {

                                                    return (
                                                        <VerticalTimelineElement
                                                            className={`vertical-timeline-element--work ${index === 0 ? 'green' : index === 1 ? 'red' : 'red'}`}
                                                            style={{ paddingBottom: index === 1 ? '0' : '2rem' }}
                                                            iconStyle={{}}
                                                            icon={
                                                                <div>
                                                                    <img className="milestone-img milestone-port"
                                                                        src={`/dashboard/Assets/Images/Sea${index === 0 ? 'Blue' : index === 1 ? 'Green' : 'Red'}.svg`}
                                                                        alt=''
                                                                    />
                                                                </div>
                                                            }
                                                        >
                                                            <p className="vertical-timeline-element-title">
                                                                {index === 2 ? "Previous Location" : index === 1 ? "Current Location" : "Next Destination"}
                                                            </p>
                                                            <p className="vertical-timeline-element-title vetical-time-second">
                                                                {index === 2 ? vesseldetails?.PreviousLocation : index === 1 ? vesseldetails?.VesselZone : vesseldetails.NextDestination}
                                                            </p>
                                                            {index === 0 ? <p className="vertical-timeline-element-title vetical-time-second">
                                                                {vesseldetails?.ETA ?? ""}
                                                            </p> : null}

                                                            {index === 1 ? <><p className="vertical-timeline-element-title" display='table'>
                                                                Vessel Name:
                                                                <span> {vesseldetails?.VesselName}
                                                                </span>
                                                            </p>
                                                                <p className="vertical-timeline-element-title" display='table'>
                                                                    Speed:
                                                                    <span> {vesseldetails?.Speed} Knot
                                                                    </span>
                                                                </p></> : null}
                                                        </VerticalTimelineElement>

                                                    )
                                                })

                                            }
                                            {
                                                portCalls.map((element) => {
                                                    if (element.PORTCALL.EVENT === "ARRIVAL") {
                                                        return null
                                                    }
                                                    return (
                                                        <VerticalTimelineElement
                                                            className="vertical-timeline-element--work red"
                                                            iconStyle={{}}
                                                            icon={
                                                                <div style={{ border: `2px solid #ed141f`, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', outline: '2px solid #fff' }}
                                                                >
                                                                    <img className="milestone-img imgRound"
                                                                        src="/dashboard/Assets/Images/receive.svg"
                                                                        alt=''
                                                                    />
                                                                </div>
                                                            }
                                                        >
                                                            <p className="vertical-timeline-element-title">
                                                                {element?.PORTCALL?.PORT}, <span>{element?.PORTCALL?.COUNTRY}</span>
                                                            </p>
                                                            <p className="vertical-timeline-element-title">
                                                                {element?.PORTCALL?.EVENT}:
                                                                <span> {element?.PORTCALL?.TIMESTAMP ? moment(element?.PORTCALL?.TIMESTAMP).format("DD/MM/YYYY") : ''}</span>
                                                            </p>
                                                        </VerticalTimelineElement>
                                                    )
                                                })
                                            }
                                        </VerticalTimeline>
                                    </div>
                                    :
                                    <div className="container-fluid">
                                        <p className="map-error-message">Shipment delivered, no tracking of vessel available.</p>
                                    </div>
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyDpfWeLTpZm0lhuv7_s-8cvKv_8sxJUHD0")
})(MapComponent)
