import React from 'react';
import { HorizontalBar, Chart } from 'react-chartjs-2';

function PayableAmount(props) {
    const tmode = [];
    let dueBal = [];
    let creBal = [];
    let maxValue = 0;

    if (props.data?.length) {
        props.data?.forEach(ele => {
            tmode.push(ele.transportMode)
            dueBal.push(ele.outstandingAmount / 1000)
            creBal.push(ele.payableAmount / 1000)
        })
        let arr = [Math.max(...dueBal), Math.max(...creBal)]
        let xMax = Math.max(...arr) + (0.2 * Math.max(...arr));
        maxValue = xMax;
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: true
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                },
                gridLines: {
                    drawOnChartArea: false
                },
                display: true
            }],
            xAxes: [{
                barPercentage: 0.6,
                maxBarThickness: 50,
                gridLines: {
                    drawOnChartArea: false
                },
                ticks: {
                    max: maxValue,
                },
                display: false
            }]
        },
        tooltips: {
            enabled: true,
            // xAlign: 'right',
            backgroundColor: '#000',
            mode: 'label',
            callbacks: {
                label: function (tooltipItem) {
                    if (tooltipItem.datasetIndex === 0)
                        return 'Due Balance : INR ' + props.amount(tooltipItem.xLabel * 1000);
                    return 'Credited Balance : INR ' + props.amount(tooltipItem.xLabel * 1000);
                },
                title: function (tooltipItems, data) {
                    var idx = tooltipItems[0].index;
                    return 'Title :' + data.labels[idx];
                }
            }
        },
        hover: {
            "animationDuration": 0
        },
        animation: {
            "duration": 1,
            "onComplete": function () {
                var chartInstance = this.chart,
                    ctx = chartInstance.ctx;

                ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                ctx.textAlign = 'left';
                ctx.textBaseline = 'center';
                ctx.zIndex = 0;

                this.data.datasets.forEach(function (dataset, i) {
                    var meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                        var data = dataset.data[index] ? ' ' + props.amount(dataset.data[index]) + 'k' : '';
                        ctx.fillText(data, bar._model.x, bar._model.y);
                    });
                });
            }
        },
    }

    const data = {
        labels: tmode,
        datasets: [
            {
                label: 'Balance Due',
                data: dueBal,
                backgroundColor: '#ee7821',
            },
            {
                label: 'Within Credit Period',
                data: creBal,
                backgroundColor: '#3d3456',
            },
        ],
    };

    return (
        <div className='bardata' >
            <HorizontalBar data={data} options={options} />
        </div>
    )

}

export default PayableAmount
