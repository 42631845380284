import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import $ from 'jquery';

import PropTypes from "prop-types";
import Slider from "@material-ui/core/Slider";

const Cards = (item) => {

    let actualDate = false
    let estimatedDate = false

    if (item.data.ActualStart) {
        actualDate = `A: ${moment(item.data.ActualStart).format("DD/MM/YYYY")}`
    }

    if (item.data.EstimatedDate) {
        // estimatedDate = `E: ${moment(item.data.EstimatedDate).format("DD/MM/YYYY hh:mm:ss a")}`
        estimatedDate = `E: ${moment(item.data.EstimatedDate).format("DD/MM/YYYY")}`
    }

    return (
        <div className="cardContainer">
            <div className="cardimg">
                <hr style={{ borderColor: item.data.IconColor, opacity: 1 }} className='leftline' />
                <img src={item.data.Icon} />
                <hr style={{ borderColor: item.data.IconColor, opacity: 1 }} className='rightline' />
            </div>
            <p title={item.data.Description}>{item.data.Description ?? ""}</p>
            <p className="voyE">{estimatedDate}</p>
            <p className="voyE">{actualDate}</p>
            <p className={item.data.DelayedStr === "On Time" ? "voyE onTime" : "voyE delay"}>{item.data.DelayedStr}</p>

        </div>

    )
}

// function ValueLabelComponent(props) {
//     const { children, open, value } = props;

//     return (
//         <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
//             {children}
//         </Tooltip>
//     );
// }

// ValueLabelComponent.propTypes = {
//     children: PropTypes.element.isRequired,
//     open: PropTypes.bool.isRequired,
//     value: PropTypes.number.isRequired
// };

const PrettoSlider = withStyles({
    root: {
        color: "#52af77",
        height: 8
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit"
        }
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 4px)"
    },
    track: {
        height: 8,
        borderRadius: 4
    },
    rail: {
        height: 8,
        borderRadius: 4
    }
})(Slider);

function AirbnbThumbComponent(props) {
    return (
        <span {...props}>
            <span className="bar">hello</span>
        </span>
    );
}

function Milestone(props) {
    const [progress, setProgress] = React.useState(0);
    const [progDeatils, setProgDetails] = React.useState({})

    React.useEffect(() => {

        setProgDetails(props.progDetails);

        console.log(props.progDetails.progressPer)

    }, []);

    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            height: 10,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: progDeatils.progressColorDefault,
        },
        bar: {
            borderRadius: 5,
            backgroundColor: progDeatils.progressColorDone,
        },
    }))(LinearProgress);

    return (
        <div className="milestone">
            <div className="container-fluid">
                <div className="milestonerow">
                    {props.milestoneData.map((item) => {
                        return (
                            <Cards data={item} />
                        )
                    })}
                </div>
                {/* <PrettoSlider
                    ThumbComponent={AirbnbThumbComponent}
                    valueLabelDisplay="auto"
                    defaultValue={50}
                /> */}

                <div className="milestonerow milestone-progress-icon">

                    {
                        props.milestoneData.map((item) => {

                            return (
                                <div className="cardContainer">


                                    <div className="cardimg">
                                        <hr style={{ borderColor: "#fff", opacity: 0 }} className='leftline' />
                                        {item.IconColor === "#3D3456" ?
                                            <img className="milestone-progress-icon-circle" src="/dashboard/Assets/Images/delivery.svg" />
                                            // <img src="https://myshipments.tgfworld.com/transysapi/Assets/Img/svg/icons-grey\deliver.svg" className="milestone-progress-icon-circle" />

                                            : null}
                                        <hr style={{ borderColor: "#fff", opacity: 0 }} className='rightline' />
                                    </div>
                                </div>

                            )
                        })
                    }

                </div>
                <div className="milestoneStatus my-3">
                    <BorderLinearProgress variant="determinate" value={progDeatils.progressPer} />
                    <div className="progressmilestone" style={{ marginLeft: `${progDeatils.progressPer}%` }}>
                        {/* {
                            progDeatils.TransportMode === "AIR" ? <img className="" src="/dashboard/Assets/Images/Air.svg" /> : progDeatils.TransportMode === "SEA" ? <img className="" src="/dashboard/Assets/Images/Sea.svg" /> : null
                        } */}
                        {/* <img className="" src="https://dev.sflhub.com/transystemp/Assets/images/timeline/deliver.svg" /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Milestone
