import React, { useState, useEffect } from 'react';
import MultiSelect from './MultiSelect';
import ShipmentCount from './ShipmentCount'
import './dashboard.css'
import { Button } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FreightSpent from './FreightSpent';
import VolumeByAir from './VolumeByAir';
import VolumeBySea from './VolumeBySea';
import PayableAmount from './PayableAmount';
import PayableAmountUSD from './PayableAmountUSD'
import moment from 'moment';
import DashTable from './DashTable';
import Slider from './slider/CustomSlider';
import { getUserData } from '../../../utility/utils';
import { HOST_URL } from '../../../config';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import VolumeBySeaLCL from './VolumeBySeaLCL';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function Dashboard() {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('');
    const [startDate, setStartDate] = useState(`01-04-${moment().year()}`);
    const [endDate, setEndDate] = useState(`30-06-${moment().year()}`);
    const [dateFormat, setDateFormat] = useState('Q');
    const [data, setData] = useState({});
    const [dest, setDest] = useState({ data: [], selected: [] });
    const [org, setOrg] = useState({ data: [], selected: [] });
    const [dept, setDept] = useState({ data: [], selected: [] })
    const [mode, setMode] = useState({ data: [], selected: [] });
    const [mark, setMark] = useState([]);
    const [shipData, setShipData] = useState({});
    const [payData, setPayData] = useState({});
    const [payDataUSD, setPayDataUSD] = useState({});
    const [frSpent, setFrSpent] = useState({});
    const [airData, setAirData] = useState({});
    const [seaData, setSeaData] = useState({});
    const [seaDataLCL, setSeaDataLCL] = useState({});
    const [tableData, setTableData] = useState({});
    const [chartValue, setChartValue] = useState('0');
    const [multiSelectLoading, setMultiSelectLoading] = useState(false)
    const [tableHeader, setTableHeader] = useState([]);
    const [yearArray, setYearArray] = useState([])
    const [finYear, setFinYear] = useState(moment().year().toString());
    const [currency, setCurrency] = useState('INR');
    const [open, setOpen] = useState(false)
    const [snackbar, setSnackbar] = useState({ severity: "", open: false, message: "" })

    useEffect(async () => {
        setLoading(true)
        await getData();
        let arr = [];
        for (let i = moment().year(); i >= 2017; i--) {
            let obj = {
                key: i.toString(),
                value: `${i}-${i + 1}`
            }
            arr.push(obj);
        }
        setYearArray([...arr]);
        setLoading(false)
    }, []);

    useEffect(async () => {
        // const getValue = (data) => {
        //     let list = '';
        //     data.selected.length && data.selected.forEach((ele, index) => {
        //         if (index < (data.selected.length - 1)) {
        //             list += `${ele.name},`
        //         } else {
        //             list += `${ele.name}`
        //         }
        //     })
        //     return list;
        // }
        await getData();
        if (chartValue === '0') {
            getGraphData('GetShipmentCount', status, '', '', '', '', '', 'INR');
        } else if (chartValue === '1') {
            setCurrency('INR');
            getGraphData('GetPayableAmount', status, '', '', '', '', '', 'INR');
        } else if (chartValue === '2') {
            getGraphData('GetFreightSpent', status, '', '', '', '', '', 'INR');
        } else if (chartValue === '3') {
            getGraphData('GetVolumeByAir', status, '', '', '', '', '', 'INR');
        } else if (chartValue === '4' || chartValue === '7') {
            getGraphData('GetVolumeBySea', status, '', '', '', '', '', 'INR');
        } else if (chartValue === '5') {
            getGraphData('GetTableData', status, '', '', '', '', '', 'INR');
        } else if (chartValue === '6') {
            setCurrency('USD');
            getGraphData('GetPayableAmount', status, '', '', '', '', '', 'USD');
        }

        if (chartValue === '3') {
            const arr = [];
            const deptData = ['FEA', 'FIA'];
            deptData.forEach((ele, index) => {
                Object.keys(data).length && data.lstDepartment.length && data.lstDepartment.forEach((item) => {
                    if (ele === item.department) {
                        arr.push({ name: ele, id: `${index + 1}` });
                    }
                })
            })
            setDept({ data: arr, selected: [] });
        } else if (chartValue === '4') {
            const arr = [];
            const deptData = ['FES', 'FIS'];
            deptData.forEach((ele, index) => {
                Object.keys(data).length && data.lstDepartment.length && data.lstDepartment.forEach((item) => {
                    if (ele === item.department) {
                        arr.push({ name: ele, id: `${index + 1}` });
                    }
                })
            })
            setDept({ data: arr, selected: [] });
        } else {
            const arr = [];
            const deptData = ['FEA', 'FIA', 'FES', 'FIS', 'WFS'];
            deptData.forEach((ele, index) => {
                Object.keys(data).length && data.lstDepartment.length && data.lstDepartment.forEach((item) => {
                    if (ele === item.department) {
                        arr.push({ name: ele, id: `${index + 1}` });
                    }
                })
            })
            setDept({ data: arr, selected: [] });
        }
    }, [chartValue])

    const setAllData = (dataValue) => {
        const originCountrydata = [];
        const destCountrydata = [];
        const deptData = [];
        const modeData = [];
        dataValue.lstContainerMode.forEach((ele, index) => {
            modeData.push({ name: ele.containerMode, id: `${index + 1}` })
        })
        dataValue.lstDepartment.forEach((ele, index) => {
            deptData.push({ name: ele.department, id: `${index + 1}` })
        })
        dataValue.lstDestinationCountryName.forEach((ele, index) => {
            destCountrydata.push({ name: ele.destinationCountryName, id: `${index + 1}` })
        })
        dataValue.lstOriginCountryName.forEach((ele, index) => {
            originCountrydata.push({ name: ele.originCountryName, id: `${index + 1}` })
        })
        setDest({ data: destCountrydata, selected: [] });
        setOrg({ data: originCountrydata, selected: [] });
        setDept({ data: deptData, selected: [] });
        setMode({ data: modeData, selected: [] });
        setMultiSelectLoading(false);
    }

    useEffect(() => {
        if ((dept.selected.some(arr => (arr.name === 'FEA' || arr.name === 'FIA'))) && (dept.selected.some(arr => (arr.name === 'FES' || arr.name === 'FIS')))) {
            const arr = [];
            const modeData = ['FCL', 'FTL', 'LCL', 'LSE'];
            modeData.forEach((ele, index) => {
                Object.keys(data).length && data.lstContainerMode.length && data.lstContainerMode.forEach((item) => {
                    if (ele === item.containerMode) {
                        arr.push({ name: ele, id: `${index + 1}` });
                    }
                })
            })
            setMode({ data: arr, selected: [] });
            setTableHeader(['Origin - Destination', 'Shipment Count', 'Chargeable Weight', 'LCL Volume', 'FCL Volume', 'Invoice Value']);
        } else if (dept.selected.some(arr => (arr.name === 'FES' || arr.name === 'FIS'))) {
            const arr = [];
            const modeData = ['FCL', 'FTL', 'LCL'];
            modeData.forEach((ele, index) => {
                Object.keys(data).length && data.lstContainerMode.length && data.lstContainerMode.forEach((item) => {
                    if (ele === item.containerMode) {
                        arr.push({ name: ele, id: `${index + 1}` });
                    }
                })
            })
            setMode({ data: arr, selected: [] });
            setTableHeader(['Origin - Destination', 'Shipment Count', 'LCL Volume (m3)', 'FCL Volume (m3)', 'Invoice Value']);
        } else if (dept.selected.some(arr => (arr.name === 'FEA' || arr.name === 'FIA'))) {
            const arr = [];
            const modeData = ['FTL', 'LSE'];
            modeData.forEach((ele, index) => {
                Object.keys(data).length && data.lstContainerMode.length && data.lstContainerMode.forEach((item) => {
                    if (ele === item.containerMode) {
                        arr.push({ name: ele, id: `${index + 1}` });
                    }
                })
            })
            setMode({ data: arr, selected: [] });
            setTableHeader(['Origin - Destination', 'Shipment Count', 'Chargeable Weight (kg)', 'Invoice Value']);
        } else if (dept.selected.some(arr => arr.name === 'WFS')) {
            const modeData = [];
            setMode({ data: modeData, selected: [] });
            setTableHeader(['Origin - Destination', 'Shipment Count', 'Chargeable Weight (kg)', 'LCL Volume (m3)', 'FCL Volume (m3)', 'Invoice Value']);
        } else {
            const arr = [];
            const modeData = ['FCL', 'FTL', 'LCL', 'LSE'];
            modeData.forEach((ele, index) => {
                Object.keys(data).length && data.lstContainerMode.length && data.lstContainerMode.forEach((item) => {
                    if (ele === item.containerMode) {
                        arr.push({ name: ele, id: `${index + 1}` });
                    }
                })
            })
            setMode({ data: arr, selected: [] });
            setTableHeader(['Origin - Destination', 'Shipment Count', 'Chargeable Weight (kg)', 'LCL Volume (m3)', 'FCL Volume (m3)', 'Invoice Value']);
        }
    }, [dept.selected.length])

    const getData = async () => {
        setMultiSelectLoading(true)
        let data = {}
        const userSecret = await JSON.parse(sessionStorage.getItem('user_secret'))
        await getUserData('user_data').then((res) => {
            data = res;
        })
        const header = {
            "Authorization": `Bearer ${data.access_token}`,
            "UserName": data.user_name,
            "UserSecret": userSecret.password,
            "DeviceType": 'W',
            "MacAddress": '',
            "IPAddress": ''
        }
        let URL = `${HOST_URL}api/v1/Kpi/GetFilterOptions?companyCode=${data.user_company_code}&transportMode=ALL&filterCriteria=${chartValue}&startDate=${startDate}&endDate=${endDate}`
        axios.get(URL, { headers: header })
            .then((res) => {
                setData(res.data.value)
                setAllData(res.data.value);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getGraphData = async (endPoint, shipStatus = '', containerMode = '', Department = '', originList = '', destList = '', tMode = '', currency = 'INR') => {
        setOpen(true)
        setShipData({});
        setPayData({});
        setPayDataUSD({});
        setFrSpent({});
        setAirData({});
        setSeaData({});
        setSeaDataLCL({});
        setTableData({});
        let data = {}
        const userSecret = await JSON.parse(sessionStorage.getItem('user_secret'))
        await getUserData('user_data').then((res) => {
            data = res;
        })
        const header = {
            "Authorization": `Bearer ${data.access_token}`,
            "UserName": data.user_name,
            "UserSecret": userSecret.password,
            "DeviceType": 'W',
            "MacAddress": '',
            "IPAddress": ''
        }
        const body = {
            "CompanyCode": data.user_company_code,
            "Status": shipStatus,
            "ContainerMode": containerMode,
            "Department": Department,
            "Origin": originList,
            "Destination": destList,
            "TransportMode": tMode,
            "Currency": currency,
            "Start": startDate,
            "End": endDate
        }
        let URL = `${HOST_URL}api/v1/Kpi/${endPoint}`
        await axios.post(URL, body, { headers: header })
            .then((res) => {
                if (endPoint === 'GetShipmentCount') {
                    setShipData(res.data);
                } else if (endPoint === 'GetPayableAmount') {
                    if (chartValue === '1') {
                        setPayData(res.data);
                    } else if (chartValue === '6') {
                        setPayDataUSD(res.data);
                    }
                } else if (endPoint === 'GetFreightSpent') {
                    setFrSpent(res.data);
                } else if (endPoint === 'GetVolumeByAir') {
                    setAirData(res.data);
                } else if (endPoint === 'GetVolumeBySea') {
                    if (chartValue === '4') {
                        setSeaData(res.data);
                    } else if (chartValue === '7') {
                        setSeaDataLCL(res.data);
                    }
                } else if (endPoint === 'GetTableData') {
                    setTableData(res.data);
                }
                setOpen(false)
            })
            .catch((err) => {
                console.log(err)
                setOpen(false)
                setSnackbar({ ...snackbar, open: true, message: "Something went wrong!!", severity: "error" })
            })
    }

    const onChangeDest = (value) => {
        setDest({ ...dest, selected: value })
    }

    const onChangeOrg = (value) => {
        setOrg({ ...org, selected: value });
    }

    const onChangeDept = (value) => {
        setDept({ ...dept, selected: value });
    }

    const onChangeMode = (value) => {
        setMode({ ...mode, selected: value });
    }

    // const markY = [
    //     {
    //         value: 0,
    //         label: "2021"
    //     }
    // ];

    const markD = [];

    const getDaysBetweenDates = (startDate, endDate) => {
        var now = startDate.clone(),
            dates = [];
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format("MM/DD/YYYY"));
            now.add(1, "days");
        }
        return dates;
    }

    const dateList = getDaysBetweenDates(
        moment(moment(`${parseInt(finYear)}-04-01`).format('YYYY-MM-DD')),
        moment(moment(`${parseInt(finYear) + 1}-03-31`).format('YYYY-MM-DD'))
    );

    dateList.forEach((ele, index) => {
        let value = index;
        let label = moment(ele).format("MMM DD");
        let string = moment(ele).format("DD-MM-YYYY");
        let obj = {
            value, label, string
        }
        markD.push(obj)
    })

    const markQ = [
        {
            value: 0,
            label: "Q1",
            string: `01-04-${parseInt(finYear)}`
        },
        {
            value: 1,
            label: "Q2",
            string: `01-07-${parseInt(finYear)}`
        },
        {
            value: 2,
            label: "Q3",
            string: `01-10-${parseInt(finYear)}`
        },
        {
            value: 3,
            label: "Q4",
            string: `01-01-${parseInt(finYear) + 1}`
        }
    ];

    const markM = [
        {
            value: 0,
            label: "APR",
            string: `01-04-${parseInt(finYear)}`
        },
        {
            value: 1,
            label: "MAY",
            string: `01-05-${parseInt(finYear)}`
        },
        {
            value: 2,
            label: "JUN",
            string: `01-06-${parseInt(finYear)}`
        },
        {
            value: 3,
            label: "JUL",
            string: `01-07-${parseInt(finYear)}`
        },
        {
            value: 4,
            label: "AUG",
            string: `01-08-${parseInt(finYear)}`
        },
        {
            value: 5,
            label: "SEP",
            string: `01-09-${parseInt(finYear)}`
        },
        {
            value: 6,
            label: "OCT",
            string: `01-10-${parseInt(finYear)}`
        },
        {
            value: 7,
            label: "NOV",
            string: `01-11-${parseInt(finYear)}`
        },
        {
            value: 8,
            label: "DEC",
            string: `01-12-${parseInt(finYear)}`
        },
        {
            value: 9,
            label: "JAN",
            string: `01-01-${parseInt(finYear) + 1}`
        },
        {
            value: 10,
            label: "FEB",
            string: `01-02-${parseInt(finYear) + 1}`
        },
        {
            value: 11,
            label: "MAR",
            string: `01-03-${parseInt(finYear) + 1}`
        }
    ];

    useEffect(() => {
        if (dateFormat === 'Q') {
            setMark(markQ);
            // } else if (dateFormat === 'Y') {
            //     setMark(markY);
        } else if (dateFormat === 'M') {
            setMark(markM);
        } else if (dateFormat === 'D') {
            setMark(markD);
        }
    }, [dateFormat])

    useEffect(() => {
        if (dateFormat === 'Q') {
            setMark(markQ);
            // } else if (dateFormat === 'Y') {
            //     setMark(markY);
        } else if (dateFormat === 'M') {
            setMark(markM);
        } else if (dateFormat === 'D') {
            setMark(markD);
        }
        setDateFormat('Q');
        setStartDate(`01-04-${parseInt(finYear)}`);
        setEndDate(`30-06-${parseInt(finYear)}`);
    }, [finYear])

    const divWidth = `${mark.length * 4}rem`;

    const reset = () => {
        if (chartValue === '0') {
            getGraphData('GetShipmentCount', status, '', '', '', '', '', 'INR');
        } else if (chartValue === '1') {
            setCurrency('INR');
            getGraphData('GetPayableAmount', status, '', '', '', '', '', 'INR');
        } else if (chartValue === '2') {
            getGraphData('GetFreightSpent', status, '', '', '', '', '', 'INR');
        } else if (chartValue === '3') {
            getGraphData('GetVolumeByAir', status, '', '', '', '', '', 'INR');
        } else if (chartValue === '4' || chartValue === '7') {
            getGraphData('GetVolumeBySea', status, '', '', '', '', '', 'INR');
        } else if (chartValue === '5') {
            getGraphData('GetTableData', status, '', '', '', '', '', 'INR');
        } else if (chartValue === '6') {
            setCurrency('USD');
            getGraphData('GetPayableAmount', status, '', '', '', '', '', 'USD');
        }
        setFinYear(moment().year().toString())
        setDateFormat('Y');
        setStartDate('01-04-2021');
        setEndDate('31-03-2022');
        setChartValue('0');
        setAllData(data);
        setStatus('');
        // getData();
    }

    const show = () => {
        const getValue = (data) => {
            let list = '';
            data.selected.length && data.selected.forEach((ele, index) => {
                if (index < (data.selected.length - 1)) {
                    list += `${ele.name},`
                } else {
                    list += `${ele.name}`
                }
            })
            return list;
        }
        if (chartValue === '0') {
            getGraphData('GetShipmentCount', status, getValue(mode), getValue(dept), getValue(org), getValue(dest), '', 'INR');
        } else if (chartValue === '1') {
            setCurrency('INR');
            getGraphData('GetPayableAmount', status, getValue(mode), getValue(dept), getValue(org), getValue(dest), '', 'INR');
        } else if (chartValue === '2') {
            getGraphData('GetFreightSpent', status, getValue(mode), getValue(dept), getValue(org), getValue(dest), '', 'INR');
        } else if (chartValue === '3') {
            getGraphData('GetVolumeByAir', status, getValue(mode), getValue(dept), getValue(org), getValue(dest), '', 'INR');
        } else if (chartValue === '4' || chartValue === '7') {
            getGraphData('GetVolumeBySea', status, getValue(mode), getValue(dept), getValue(org), getValue(dest), '', 'INR');
        } else if (chartValue === '5') {
            getGraphData('GetTableData', status, getValue(mode), getValue(dept), getValue(org), getValue(dest), '', 'INR');
        } else if (chartValue === '6') {
            setCurrency('USD');
            getGraphData('GetPayableAmount', status, getValue(mode), getValue(dept), getValue(org), getValue(dest), '', 'USD');
        }
    }

    const calculateAmmount = (ammount = 0) => {
        const amt = Number(parseFloat(ammount).toFixed(2)).toLocaleString('en', {
            minimumFractionDigits: 1
        });
        return amt
    }

    if (loading) {
        return null;
    }

    return (
        <div className='dashBoardWrapper'>
            {/* <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop> */}
            <Snackbar autoHideDuration={3000} onClose={() => setSnackbar({ ...snackbar, open: false })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={snackbar.open} >
                <Alert onClose={() => setSnackbar({
                    ...snackbar, open: false
                })} severity={snackbar.severity}>{snackbar.message}</Alert>
            </Snackbar>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-8 col-12'>
                        <div className='row shpCountDivData'>
                            <div className='col-md-4 col-12'>
                                <div className="px-2 py-1 dashDivData">
                                    <span> Total Shipment Count</span>
                                    <p>
                                        {
                                            chartValue === '0' ?
                                                shipData.totalShipment ? shipData.totalShipment : '0'
                                                :
                                                chartValue === '1' ?
                                                    payData.totalShipment ? payData.totalShipment : '0'
                                                    :
                                                    chartValue === '2' ?
                                                        frSpent.totalShipment ? frSpent.totalShipment : '0'
                                                        :
                                                        chartValue === '3' ?
                                                            airData.totalShipment ? airData.totalShipment : '0'
                                                            :
                                                            chartValue === '4' ?
                                                                seaData.totalShipment ? seaData.totalShipment : '0'
                                                                :
                                                                chartValue === '5' ?
                                                                    tableData.totalShipment ? tableData.totalShipment : '0'
                                                                    :
                                                                    chartValue === '6' ?
                                                                        payDataUSD.totalShipment ? payDataUSD.totalShipment : '0'
                                                                        :
                                                                        chartValue === '7' ?
                                                                            seaDataLCL.totalShipment ? seaDataLCL.totalShipment : '0'
                                                                            : <>&nbsp;</>
                                        }
                                    </p>
                                </div>
                            </div>
                            {/* <div className='col-md-3 col-12'>
                                <div className="px-2 py-1 dashDivData">
                                    <span> Total Freight Spent</span>
                                    <p style={{ color: '#9bd452' }}>&#8377;
                                        {
                                            chartValue === '0' ?
                                                shipData.totalFrieghtSpent
                                                :
                                                chartValue === '1' ?
                                                    payData.totalFrieghtSpent
                                                    :
                                                    chartValue === '2' ?
                                                        frSpent.totalFrieghtSpent
                                                        :
                                                        chartValue === '3' ?
                                                            airData.totalFrieghtSpent
                                                            :
                                                            chartValue === '4' ?
                                                                seaData.totalFrieghtSpent
                                                                :
                                                                chartValue === '5' ?
                                                                    tableData.totalFrieghtSpent
                                                                    : <>&nbsp;</>
                                        }
                                    </p>
                                </div> 
                            </div> */}
                            <div className='col-md-4 col-12'>
                                <div className="px-2 py-1 dashDivData">
                                    <span> Total Payable Amount</span>
                                    <p style={{ color: '#ee7821' }}>INR &nbsp;
                                        {
                                            chartValue === '0' ?
                                                calculateAmmount(shipData.totalPayableAmount)
                                                :
                                                chartValue === '1' ?
                                                    calculateAmmount(payData.totalPayableAmount)
                                                    :
                                                    chartValue === '2' ?
                                                        calculateAmmount(frSpent.totalPayableAmount)
                                                        :
                                                        chartValue === '3' ?
                                                            calculateAmmount(airData.totalPayableAmount)
                                                            :
                                                            chartValue === '4' ?
                                                                calculateAmmount(seaData.totalPayableAmount)
                                                                :
                                                                chartValue === '5' ?
                                                                    calculateAmmount(tableData.totalPayableAmount)
                                                                    :
                                                                    chartValue === '6' ?
                                                                        calculateAmmount(payDataUSD.totalPayableAmount)
                                                                        :
                                                                        chartValue === '7' ?
                                                                            calculateAmmount(seaDataLCL.totalPayableAmount)
                                                                            : <>&nbsp;</>
                                        }
                                    </p>
                                    <p style={{ color: '#ee7821' }}>USD &nbsp;
                                        {
                                            chartValue === '0' ?
                                                calculateAmmount(shipData.totalPayableAmountUSD)
                                                :
                                                chartValue === '1' ?
                                                    calculateAmmount(payData.totalPayableAmountUSD)
                                                    :
                                                    chartValue === '2' ?
                                                        calculateAmmount(frSpent.totalPayableAmountUSD)
                                                        :
                                                        chartValue === '3' ?
                                                            calculateAmmount(airData.totalPayableAmountUSD)
                                                            :
                                                            chartValue === '4' ?
                                                                calculateAmmount(seaData.totalPayableAmountUSD)
                                                                :
                                                                chartValue === '5' ?
                                                                    calculateAmmount(tableData.totalPayableAmountUSD)
                                                                    :
                                                                    chartValue === '6' ?
                                                                        calculateAmmount(payDataUSD.totalPayableAmountUSD)
                                                                        :
                                                                        chartValue === '7' ?
                                                                            calculateAmmount(seaDataLCL.totalPayableAmountUSD)
                                                                            : <>&nbsp;</>
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4 col-12'>
                                <div className="px-2 py-1 dashDivData">
                                    <span> Total Overdue Amount</span>
                                    <p style={{ color: '#cc222b' }}>INR &nbsp;
                                        {
                                            chartValue === '0' ?
                                                calculateAmmount(shipData.totalOutstandingAmount)
                                                :
                                                chartValue === '1' ?
                                                    calculateAmmount(payData.totalOutstandingAmount)
                                                    :
                                                    chartValue === '2' ?
                                                        calculateAmmount(frSpent.totalOutstandingAmount)
                                                        :
                                                        chartValue === '3' ?
                                                            calculateAmmount(airData.totalOutstandingAmount)
                                                            :
                                                            chartValue === '4' ?
                                                                calculateAmmount(seaData.totalOutstandingAmount)
                                                                :
                                                                chartValue === '5' ?
                                                                    calculateAmmount(tableData.totalOutstandingAmount)
                                                                    :
                                                                    chartValue === '6' ?
                                                                        calculateAmmount(payDataUSD.totalOutstandingAmount)
                                                                        :
                                                                        chartValue === '7' ?
                                                                            calculateAmmount(seaDataLCL.totalOutstandingAmount)
                                                                            : <>&nbsp;</>
                                        }
                                    </p>
                                    <p style={{ color: '#cc222b' }}>USD &nbsp;
                                        {
                                            chartValue === '0' ?
                                                calculateAmmount(shipData.totalOutstandingAmountUSD)
                                                :
                                                chartValue === '1' ?
                                                    calculateAmmount(payData.totalOutstandingAmountUSD)
                                                    :
                                                    chartValue === '2' ?
                                                        calculateAmmount(frSpent.totalOutstandingAmountUSD)
                                                        :
                                                        chartValue === '3' ?
                                                            calculateAmmount(airData.totalOutstandingAmountUSD)
                                                            :
                                                            chartValue === '4' ?
                                                                calculateAmmount(seaData.totalOutstandingAmountUSD)
                                                                :
                                                                chartValue === '5' ?
                                                                    calculateAmmount(tableData.totalOutstandingAmountUSD)
                                                                    :
                                                                    chartValue === '6' ?
                                                                        calculateAmmount(payDataUSD.totalOutstandingAmountUSD)
                                                                        :
                                                                        chartValue === '7' ?
                                                                            calculateAmmount(seaDataLCL.totalOutstandingAmountUSD)
                                                                            : <>&nbsp;</>
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4 col-12 mt-2'>
                                <select className='form-select' value={chartValue} onChange={async (e) => {
                                    setChartValue(e.target.value);
                                }}>
                                    <option value={'0'}>Shipment Count</option>
                                    <option value={'1'}>Payable Amount (INR)</option>
                                    <option value={'6'}>Payable Amount (USD)</option>
                                    {/* <option value={'2'}>Freight Spent</option> */}
                                    <option value={'3'}>Volume by Air</option>
                                    <option value={'4'}>Volume by Sea (FCL)</option>
                                    <option value={'7'}>Volume by Sea (LCL)</option>
                                    <option value={'5'}>Table</option>
                                </select>
                            </div>
                        </div>
                        <div className='row chartRow'>
                            <div className='col-12 py-2 chartCol' style={chartValue === '5' ? { padding: 0 } : {}}>
                                {
                                    open ?
                                        <div className='noInternet'>
                                            <CircularProgress size={"2rem"} />
                                        </div>
                                        :
                                        chartValue === '0' ?
                                            <ShipmentCount data={shipData.value} />
                                            :
                                            chartValue === '1' ?
                                                <PayableAmount data={payData.value} amount={calculateAmmount} />
                                                // :
                                                // chartValue === '2' ?
                                                //     <FreightSpent data={frSpent.value} amount={calculateAmmount}/>
                                                :
                                                chartValue === '3' ?
                                                    <VolumeByAir data={airData.value} amount={calculateAmmount} />
                                                    :
                                                    chartValue === '4' ?
                                                        <VolumeBySea data={seaData.value} />
                                                        :
                                                        chartValue === '5' ?
                                                            <DashTable data={tableData.value} tableHeader={tableHeader} amount={calculateAmmount} /> : chartValue === '6' ?
                                                                <PayableAmountUSD data={payDataUSD.value} amount={calculateAmmount} />
                                                                : chartValue === '7' ?
                                                                    <VolumeBySeaLCL data={seaDataLCL.value} />
                                                                    : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 col-12 dashRightCol'>
                        <div className='row mb-2 mx-0'>
                            <div className='col-12 ps-0 pe-0'>
                                <label className='form-label mb-0'>Relative Date</label>
                                <div className='stDateEnDate'>
                                    <span>{startDate} </span> to <span> {endDate}</span>
                                </div>
                            </div>
                            <div className='col-6 ps-0'>
                                <select className='form-select' value={finYear} onChange={(e) => {
                                    setFinYear(e.target.value);
                                    // setDateFormat('Q')
                                }}>
                                    {
                                        yearArray.length ?
                                            yearArray.map((ele) => {
                                                return <option key={ele.key} value={ele.key}>{ele.value}</option>
                                            })
                                            : ''
                                    }
                                </select>
                            </div>
                            <div className='col-6 pe-0'>
                                <select className='form-select' value={dateFormat} onChange={(e) => setDateFormat(e.target.value)}>
                                    {/* <option value='Y'>YEARS</option> */}
                                    <option value='Q'>QUARTERS</option>
                                    <option value='M'>MONTHS</option>
                                    <option value='D'>DAYS</option>
                                </select>
                            </div>
                            <div className='col-12 sliderRow '>
                                <div className='sliderInner' style={{ minWidth: '100%', width: divWidth }}>
                                    <Slider marks={mark} stDate={startDate} finYear={parseInt(finYear)} enDate={endDate} chngStDate={(value) => setStartDate(value)} chngEnDate={(value) => setEndDate(value)} dtFormat={dateFormat} />
                                </div>
                            </div>
                        </div>
                        <div className='row mb-1'>
                            <div className='col-12'>
                                <label className='form-label'>Origin Country</label>
                                <MultiSelect
                                    options={org.data.length ? org.data : []}
                                    data='Origin Country'
                                    onChange={onChangeOrg}
                                    selected={org.selected}
                                    loading={multiSelectLoading}
                                />
                            </div>
                        </div>
                        <div className='row mb-1'>
                            <div className='col-12'>
                                <label className='form-label'>Destination Country</label>
                                <MultiSelect
                                    options={dest.data.length ? dest.data : []}
                                    data='Destination Country'
                                    onChange={onChangeDest}
                                    selected={dest.selected}
                                    loading={multiSelectLoading}
                                />
                            </div>
                        </div>
                        <div className='row mb-1'>
                            <div className='col-12'>
                                <label className='form-label'>Department</label>
                                <MultiSelect
                                    options={dept.data.length ? dept.data : []}
                                    data='Department'
                                    onChange={onChangeDept}
                                    selected={dept.selected}
                                    loading={multiSelectLoading}
                                />
                            </div>
                        </div>
                        <div className='row mb-1'>
                            <div className='col-12'>
                                <label className='form-label'>Mode</label>
                                <MultiSelect
                                    options={mode.data.length ? mode.data : []}
                                    data='Mode'
                                    onChange={onChangeMode}
                                    selected={mode.selected}
                                    loading={multiSelectLoading}
                                />
                            </div>
                        </div>
                        <div className='row mb-1'>
                            <RadioGroup name="status" value={status} onChange={(e) => setStatus(e.target.value)} style={{ flexDirection: 'row' }}>
                                <div className='col-12'>
                                    <label className='form-label mb-0'>Satus</label>
                                </div>
                                <div className='col-md-4 col-xs-6 col-12'>
                                    <div className="form-check">
                                        <FormControlLabel value="O" control={<Radio />} label="Open" />
                                    </div>
                                </div>
                                <div className='col-md-4 col-xs-6 col-12'>
                                    <div className="form-check">
                                        <FormControlLabel value="C" control={<Radio />} label="Closed" />
                                    </div>
                                </div>
                                <div className='col-md-4 col-xs-6 col-12'>
                                    <div className="form-check">
                                        <FormControlLabel value="" control={<Radio />} label="All" />
                                    </div>
                                </div>
                            </RadioGroup>
                        </div>
                        <div className='row actions'>
                            <div className='col-6 text-center saveAction'>
                                <Button variant='contained' onClick={show}>Show Results</Button>
                            </div>
                            <div className='col-6 text-center resetAction'>
                                <Button variant='contained' onClick={reset}>Reset</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Dashboard