import React from 'react'
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';

// const row = (i) => ;

function DashTable(props) {
    return (
        <div className='dashTable'>
            <TableContainer >
                <Table stickyHeader >
                    <TableHead>
                        <TableRow>
                            {
                                props.tableHeader.map((item, index) => <TableCell key={index} align={index > (props.tableHeader.length - 4) ? 'right' : 'left'}>{item}</TableCell>)
                            }
                            {/* <TableCell>Origin - Destination</TableCell>
                            <TableCell>Shipment Count</TableCell>
                            <TableCell>Freight Volume</TableCell>
                            <TableCell>Freight Spent</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.data?.length && props.data?.map((ele, index) => {
                                return (
                                    <TableRow index={index}>
                                        <TableCell title={`${ele.origin} - ${ele.destination}`}>{ele.origin} - {ele.destination}</TableCell>
                                        <TableCell>{ele.shipmentCount}</TableCell>
                                        {
                                            props.tableHeader.includes('Chargeable Weight (kg)') ? <TableCell>{props.amount(ele.weight) == 0 ? '0' : props.amount(ele.weight)}</TableCell> : null
                                        }
                                        {
                                            props.tableHeader.includes('LCL Volume (m3)') ? <TableCell align='right'>{props.amount(ele.lclVolume) == 0 ? '0' : props.amount(ele.lclVolume)}</TableCell> : null
                                        }
                                        {
                                            props.tableHeader.includes('FCL Volume (m3)') ? <TableCell align='right'>{props.amount(ele.fclVolume) == 0 ? '0' : props.amount(ele.fclVolume)}</TableCell> : null
                                        }
                                        <TableCell align='right'>{props.amount(ele.invoiceValue) == 0 ? <>{ele.currency} 0</> : <>{ele.currency} {props.amount(ele.invoiceValue)}</>}</TableCell>
                                    </TableRow>
                                )
                            })
                        }

                        {/* {
                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((e, i) => {
                                return row(i);
                            })
                        } */}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default DashTable
